import * as React from "react";
import { Image } from "react-bootstrap";
import Logo from "../Images/Logo.png";

import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  return (
    <div className="Main__Section">
      <div className="forBg__image">
        <div className="Main__centent text-center">
          <Image src={Logo} alt="Logo.png" className="Logo" />
          <div className="for__heading text-white my-5">
            <h2 className="welcome">Welcome</h2>
            <div className="para">
              <p className=" mt-5">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s
              </p>
            </div>
          </div>
          <div className="For__button">
            <buton
              type="butotn"
              onClick={() => {
                navigate("/Login");
              }}
              className="Login__button"
            >
              Login
            </buton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
