import React, { useState, useEffect } from "react";
import ResponsiveDrawer from "../Drawer/drawer";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";


import Notification from "../AlertNotification/Message";

import ConfirmDialog from "../AlertNotification/confirmdialog";

import NotifyComp from "./NitificationComp";

////////////

const drawerWidth = 240;

function AllFirebaseNotificationz() {
 
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  return (
    <div className="tableX">
      <ResponsiveDrawer heading="Notifications" />
      <Box
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
        component="main"
      >
        <Toolbar />
        <div className="mainnotify">
          <NotifyComp />
        </div>
      </Box>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </div>
  );
}

export default AllFirebaseNotificationz;
