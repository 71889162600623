import React, { useState, useRef, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios, { CancelToken, isCancel } from "axios";
import Notification from "../AlertNotification/Message";
import CircularIndeterminate from "../Loader/Loader";
import { styled } from "@mui/material/styles";
import LinearWithValueLabel from "../Loader/progressLoad";
import IconButton from "@mui/material/IconButton";
import { ProgressBar } from "react-bootstrap";
import AddIcon from "@mui/icons-material/Add";
import ResponsiveDrawer from "../Drawer/drawer";
import Box from "@mui/material/Box";
import { Paper } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
// switches
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import usePasswordToggle from "../PasswordToggle/usePasswordToggle";
import usePasswordToggleX from "../PasswordToggle/usePasswordToggleX";
import "../RegisterSection/Login";
//
//
// switch function
const drawerWidth = 240;
const Input = styled("input")({
  display: "none",
});
//
export default function Settings() {
  const navigate = useNavigate();
  const [size, setsize] = useState(false);
  const [selectedFile, setSelectedFile] = useState({
    file: [],
    filepreview: null,
  });
  const handleChange = (event) => {
    if (event.target.files[0].size > 100 * 1024) {
      setsize(true);
    } else if (event.target.files[0].size <= 100 * 1024) {
      setSelectedFile({
        ...selectedFile,
        file: event.target.files[0],
        filepreview: URL.createObjectURL(event.target.files[0]),
      });
      setupload(false);
      setsize(false);
    }
  };
  const [PasswordInputType, ToggleIcon] = usePasswordToggle();
  const [PasswordInputTypex, ToggleIconx] = usePasswordToggleX();
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [passwordtwo, setPassword2] = useState("");
  const [done, setdone] = useState(false);
  const [upload, setupload] = useState(true);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const cancelFileUpload = useRef(null);
  const [tokenauth, settokenauth] = useState("")

  const { mail } = useParams();
    const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  /////////////
  ///////////
  /////////
  const GetAdmin = () => {
    axios.post("/get-token" ,{
      id: sessionStorage.getItem("token_id")
    }).then((res) => {
      settokenauth(res.data.token)
    axios
      .post("/get-admins", {
        email: sessionStorage.getItem("email"),
      },  {
      
        headers: {
          
          "x-auth-header": res.data.token
        },
    })
      .then((response) => {
        // console.log(response.data.AdminProfile);
        // console.log(response.data.AdminLoginCredentials);
        setdone(false);
        response.data.AdminProfile.map((admin) => {
          setSelectedFile(admin.image);
          setFname(admin.firstname);
          setLname(admin.lastname);
          setPhone(admin.phone);
          setEmail(admin.email);
        });
        response.data.AdminLoginCredentials.map((admin) => {
          setPassword(admin.password);
          setPassword2(admin.password);
        });
      })
      .catch((err) => console.log(err));
    }).catch((err) => console.log(err))
  };
  useEffect(() => {
    sessionStorage.setItem("id", "8");
    setdone(true);
    GetAdmin();
  }, []);

  const options = {
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;

      let percent = Math.floor((loaded * 100) / total);

      if (percent < 100) {
        setUploadPercentage(percent);
      }
    },
    cancelToken: new CancelToken(
      (cancel) => (cancelFileUpload.current = cancel)
    ),
  };

  const cancelUpload = () => {
    if (cancelFileUpload.current)
      cancelFileUpload.current("User has canceled the file upload.");
  };

  const updateAdmin = () => {
    console.log(tokenauth && tokenauth)
    const formData = new FormData();
    formData.append("firstname", fname);
    formData.append("lastname", lname);
    formData.append("email", email);
    formData.append("password", password);
    formData.append("phone", phone);
    formData.append("file", selectedFile.file);
    axios
      .post(`/update-admin/${email}`, formData,{
      
        headers: {
          
          "x-auth-header": tokenauth && tokenauth
        },
    })
      .then((response) => {
        setUploadPercentage(100);
        if (response.status === 200) {
          setdone(false);
          console.log("success");
          setNotify({
            isOpen: true,
            message: `Updated Successfully`,
            type: "success",
          });
        }
        // setTimeout(() => {
        //   setUploadPercentage(0);
        //   navigate("/setting");
        //   window.location.reload();
        // }, 1000);
      })
      .catch((err) => {
        setdone(false);
        console.log(err.response);
        setNotify({
          isOpen: true,
          message: `${err}`,
          type: "error",
        });
        // setdone(false);
        // console.log(err);
        if (isCancel(err)) {
          alert(err.message);
        }
        setUploadPercentage(0);
      });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    updateAdmin();
    setdone(true);
  };
  return (
    <div className="mx-4">
      <ResponsiveDrawer heading="Settings" />
      <Box
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
        component="main"
      >
        <Toolbar />
        <div className="d-flex justify-content-between m-5 pt-5">
          <h4 className="">Change&nbsp;Settings</h4>
          <div className="">
            <Button className="btn2" type="submit" onClick={handleSubmit}>
              Save
            </Button>
          </div>
        </div>
        {done ? (
          <CircularIndeterminate className="AddAgentloader" />
        ) : (
          <div className="main mx-5">
            {/* <Container> */}
            <form onSubmit={handleSubmit} novalidate>
              <div className="d-inline-block mt-4">
                <label htmlFor="icon-button-file">
                  <Input
                    accept="image/*"
                    id="icon-button-file"
                    type="file"
                    // value={selectedFile}
                    onChange={handleChange}
                  />
                  {!upload ? (
                    upload ? (
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                      >
                        <div className="addicon">
                          <AddIcon className="icon" />
                        </div>
                      </IconButton>
                    ) : (
                      <img
                        className="previewimg"
                        src={selectedFile.filepreview}
                        alt=""
                      />
                    )
                  ) : (
                    <img className="previewimg" src={selectedFile} alt="" />
                  )}
                </label>
              </div>
              <div className={size ? "sizeshow" : "sizehide"}>
                <small>File size excedded than 100KB</small>
              </div>
              {uploadPercentage < 0 && (
                <div className="row mt-3">
                  <div className="col pt-1">
                    <ProgressBar
                      now={uploadPercentage}
                      striped={true}
                      label={`${uploadPercentage}%`}
                    />
                  </div>
                  <div className="col-auto">
                    <span
                      className="text-primary cursor-pointer"
                      onClick={() => cancelUpload()}
                    >
                      Cancel
                    </span>
                  </div>
                </div>
              )}
              <Row xs="1" sm="1" md="2" lg="3" xl="3" className="mt-4">
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="firstname"
                      value={fname}
                      onChange={(e) => setFname(e.target.value)}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="lastname"
                      value={lname}
                      onChange={(e) => setLname(e.target.value)}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="text"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      readOnly
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row xs="1" sm="1" md="2" lg="3" xl="3">
                <Col>
                  <Form.Group className="mb-3" con>
                    <Form.Label>phone</Form.Label>
                    <Form.Control
                      type="tel"
                      name="phone"
                      value={phone}
                      placeholder="Phone"
                      onChange={(e) => setPhone(e.target.value)}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" con>
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type={PasswordInputType}
                      name="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                    <span className="password-toogle-icon">{ToggleIcon}</span>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" con>
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                      type={PasswordInputTypex}
                      name="passwordtwo"
                      value={password}
                      onChange={(e) => setPassword2(e.target.value)}
                      required
                    />
                    <span className="password-toogle-icon">{ToggleIconx}</span>
                  </Form.Group>
                </Col>
              </Row>
            </form>
            {/* </Container> */}
            {/* <div className="content d-flex ms-4 my-3">
              <h5 className="mt-1">Notification</h5>
              <FormGroup className="mx-5 color">
                <FormControlLabel
                  control={<Android12Switch defaultChecked />}
                  label="ON \ OFF"
                />
              </FormGroup>
            </div> */}
          </div>
          //
        )}
      </Box>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
}
