/* eslint-disable no-unreachable */
import { useReactToPrint } from "react-to-print";
import { Container, Row } from "react-bootstrap";
import ResponsiveDrawer from "../Drawer/drawer";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import CircularIndeterminate from "../Loader/Loader";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Notification from "../AlertNotification/Message";
import React, { useState, useEffect, useRef, useContext } from "react";

import { UserContext } from "../../App";

import "../Orders/orderinfo.css";
//
const drawerWidth = 240;
//
//
const InformationOrder = () => {
  let total = "";
  let newlist = [];
  let subtotal = [];

  const navigate = useNavigate();
  //
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  ///////////////////////////
  const [styleName, setstyleName] = useState("");
  const [color_on_box, setcolor_on_box] = useState("");
  const [sku, setsku] = useState("");
  const [skux, setskux] = useState({});
  const [email_1, setemail_1] = useState("");
  const [qty, setqty] = useState({});
  ////////////////////////////
  ////////////////////////////
  const [customerName, setcustomerName] = useState("");
  const [agentEmail, setagentEmail] = useState("");
  const [storeName, setstoreName] = useState("");
  const [startDate, setstartDate] = useState("");
  const [cancelDate, setcancelDate] = useState("");
  const [paymentterm, setpaymentterm] = useState("");
  ////////////////////////////
  ///////////////////////////////shipTo
  const [shipTo, setShipTo] = useState("");
  const [shipToCity, setshipToCity] = useState("");
  const [shipToCountry, setshipToCountry] = useState("");
  const [shipToState, setshipToState] = useState("");
  const [shipToPostcode, setshipToPostcode] = useState("");
  const [size, setSize] = useState("");
  const [unitQuantity, setunitQuantity] = useState("");
  //
  const [totalfinal, settotalfinal] = useState("");
  const [Size1, setSize1] = useState("");
  const [Price1, setPrice1] = useState("");
  const [Size2, setSize2] = useState("");
  const [Price2, setPrice2] = useState("");
  const [Size3, setSize3] = useState("");
  const [Price3, setPrice3] = useState("");
  const [customerId, setcustomerId] = useState("");
  const [orderdate, setorderdate] = useState("");
  //
  // const [Date, setDate] = useState("");
  const [unit_final_price, setUnit_final_price] = useState("");
  const [revenues_total, setrevenues_total] = useState("");
  const [product_img, setproduct_img] = useState("");
  const [SignImg, setSignImg] = useState("");
  //
  const [done, setdone] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [orders, setOrders] = useState([]);
  const [ProductDetails, setProductDetails] = useState([]);
  const [orderslist, setOrderslist] = useState([]);
  const [vieworders, setvieworders] = useState([]);
  const [skuzx, setskuzx] = useState([]);
  const { order_number } = useParams();
  const [customeremail, setcustomeremail] = useState("");
  ///////
  const [billingadress, setbillingadress] = useState("");
  const [billingadresstwo, setbillingadresstwo] = useState("");
  const [billingcountry, setbillingcountry] = useState("");
  const [billingcity, setbillingcity] = useState("");
  const [billingstate, setbillingstate] = useState("");
  const [billingpostcode, setbillingpostcode] = useState("");
  //
  useEffect(() => {
    // sessionStorage.getItem("payloadout") != "true"
    //   ? navigate("/")
    //   :
    //    OrderInfo();
    // GetEmails();
    // // GetPdf();
    // GetCustomerEmail();
    // OrderX();
    // setdone(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //
  /////>>>>>>>

  const OrderInfo = async () => {
    const response = await axios.post(`/get-order-details`, {
      order_number: order_number,
    });
    setOrders(response.data.Orders);

    // console.log(response.data.Orders);
    // console.log(response.data.ProductDetails);
    setProductDetails(response.data.ProductDetails);

    const skx = response.data.ProductDetails.map((sk) => {
      return sk.sku;
    });
    setskuzx(skx);
    // console.log(skx);
    skx.forEach((value) => {
      let list = response.data.Orders.filter((obj) => {
        return obj.sku == value;
      });
      newlist.push(list);
    });

    // console.log(newlist);
    setOrderslist(newlist);

    setdone(false);
    // newlist.push(response.data)
    // console.log(newlist);
    response.data.Orders.map(function (OrderDetail) {
      setcustomerName(OrderDetail.customerName);
      setemail_1(OrderDetail.customerId);
      setstartDate(OrderDetail.startDate);
      setcancelDate(OrderDetail.cancelDate);
      setstoreName(OrderDetail.storeName);
      setorderdate(OrderDetail.date);
      //
      setShipTo(OrderDetail.shipTo);
      setshipToCountry(OrderDetail.shipToCountry);
      setshipToCity(OrderDetail.shipToCity);
      setshipToState(OrderDetail.shipToState);
      setshipToPostcode(OrderDetail.shipToPostcode);
      //
      //
      setsku(OrderDetail.sku);
      setstyleName(OrderDetail.styleName);
      setcolor_on_box(OrderDetail.color_on_box);
      setproduct_img(OrderDetail.product_img);
      setSignImg(OrderDetail.SignImg);
      setunitQuantity(OrderDetail.unitQuantity);
      setSize(OrderDetail.size);
      //
      setbillingadress(OrderDetail.billingShipToStreet);
      setbillingadresstwo(OrderDetail.billingShipToStreet2);
      setbillingcountry(OrderDetail.billingShipToCountry);
      setbillingcity(OrderDetail.billingShipToCity);
      setbillingstate(OrderDetail.billingShipToState);
      setbillingpostcode(OrderDetail.billingShipToPostcode);
      //
      setUnit_final_price(OrderDetail.unit_final_price);
      setrevenues_total(OrderDetail.revenues_total);
      setpaymentterm(OrderDetail.paymentTerm);
      setcustomeremail(OrderDetail.customerEmail1);
      setcustomerId(OrderDetail.customerId);
    });

    // console.log(sku && sku);
    // console.log(product_img);
    // eslint-disable-next-line array-callback-return
    response.data.ProductDetails.map((orders) => {
      setSize1(orders.Size1);
      setPrice1(orders.Price1);
      setSize2(orders.Size2);
      setPrice2(orders.Price2);
      setSize3(orders.Size3);
      setPrice3(orders.Price3);
    });
    // console.log(response.data.ProductDetails);
  };
  //
  const OrderX = async () => {
    const response = await axios.post(`get-order-details-admin`, {
      order_number: order_number,
    });
    //   console.log(response.data.orders.sort((a, b) => {
    //     return a.size - b.size;
    // }))

    setvieworders(response.data.orders);
    //   console.log(response.data.orders[0].filter((id) => {
    //     return id.order_number = order_number
    //   }).sort((a, b) => {
    //     return a.size - b.size;
    // }))
  };

  const [customermail, setcustomermail] = useState("");
  const GetCustomerEmail = async () => {
    const response = await axios.post(`/get-customers`);
    // setcustomers(response.data);
    response.data
      .filter((id) => {
        return (id.customerid = email_1);
      })
      .map((mail) => {
        setcustomermail(mail.email_1);
      });
    // setdone(true);
    // console.log(response.data);
    // setTimeout(() => {
    //   setNotify({
    //     isOpen: true,
    //     message: "Request Timeout",
    //     type: "error",
    //   });
    //   setdone(false);
    // }, 100);

    // setdone(true);
  };

  //
  const [emails, setEmails] = useState([]);
  const GetEmails = async () => {
    const response = await axios.post(`/all-customer-emails`);
    setEmails(response.data);
    // console.log(response.data);
    setdone(false);
  };
  //
  // console.log(orderslist)
  const [pdf, setpdf] = useState([]);
  const [pdfmail, setpdfmail] = useState("");

  //   const GetPdf = async () => {
  //     const response = await axios.post(`/get-pdf`, {
  //       order_number: order_number,
  //     });
  //     console.log(response.data)
  //     response.data.length > 0 ?
  //     setpdfmail(response.data[0].file) : setpdfmail("")
  //     setpdf(response.data);
  //     setdone(false);
  //   };
  const [showemail, setshowemail] = useState(false);
  const ShowOtherContact = () => {
    setshowemail(!showemail);
  };
  //////////////
  const [pdfsend, setPdfsend] = useState("");
  const [file, setfile] = useState("");
  const HandleSubmit = (e) => {
    e.preventDefault();
    SendPDF();
  };
  // API calling
  const SendPDF = () => {
    // const formData = new FormData();
    // formData.append("pdfsend", pdfsend);
    axios
      .post("/send-pdf-email", {
        email: email_1,
        file: file,
      })
      .then((response) => {
        console.log(response.status);
        if (response.status === 200) {
          setdone(false);
          setNotify({
            isOpen: true,
            message: `${response.data.message}`,
            type: "success",
          });
        }
      })
      .catch((err) => {
        setdone(false);
        console.log(err.response);
        setNotify({
          isOpen: true,
          message: `${err.response.data.message}`,
          type: "error",
        });
        // console.log(err);
      });
  };
  const runCallback = (cb) => {
    return cb();
  };
  //////

  return (
    <div className="overflow-hidden">
      <ResponsiveDrawer heading="Order Information" />
      <Box
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
        component="main"
      >
        <Toolbar />
        <div className="me-3 d-flex justify-content-end p-4">
          <div>
            {}
            <a href={pdf.length > 0 ? pdf[0].file : "#"}>
              <button className="CSV me-2">
                <small>Download&nbsp;PDF</small>
              </button>
            </a>

            <button
              className="CSV px-4"
              onClick={() => {
                navigate("/ActiveOrders");
              }}
            >
              <small>Home</small>
            </button>
          </div>
        </div>
        {done ? (
          <CircularIndeterminate className="AddAgentloader" />
        ) : (
          <div>
            <Container className="mt-3 w-auto overflow-auto" ref={componentRef}>
              <Row className="mx-4">
                <div className="  top__section py-4">
                  <div>
                    {/* <p className="">
                    <span className="ms-2 me-4 fw-bolder">
                      <span className="fw-bolder">Store&nbsp;Name: </span>
                    </span>
                    <small className="fw-bolder">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{storeName}</small>
                  </p>
                  <p className="">
                    <span className="ms-2 me-4  fw-bolder">
                      <span className="fw-bolder">Order&nbsp;Number: </span>
                    </span>
                    <small className="fw-bolder">{order_number}</small>
                  </p> */}

                    <div className="d-flex flex-column">
                      <small>
                        <span className="ms-2 me-4 fw-bolder">
                          Store&nbsp;Name:{" "}
                        </span>
                        <small className="fw-bolder ms-5">
                          &nbsp;&nbsp;{storeName}
                        </small>
                      </small>
                      <small>
                        <span className="ms-2 me-4 fw-bolder">
                          Order&nbsp;Number:{" "}
                        </span>
                        <small className="fw-bolder ms-4">
                          &nbsp;&nbsp;&nbsp;&nbsp;{order_number}
                        </small>
                      </small>
                    </div>
                    {/*  */}
                    {/*  */}
                    <div className="d-flex">
                      <small>
                        <span className="ms-2 me-4 mt-4">Customer Id: </span>
                        {/* {console.log()} */}
                        <small className=" ms-5">&nbsp;{customerId}</small>
                      </small>
                    </div>
                    <div className="d-flex">
                      <small>
                        <span className="ms-2 me-4 mt-4">Buyer Name: </span>
                        {/* {console.log()} */}
                        <small className=" ms-5">&nbsp;{customerName}</small>
                      </small>
                    </div>
                    <div className="d-flex">
                      <small>
                        <span className="ms-2 me-4 mt-4">Customer Email: </span>
                        {/* {console.log()} */}
                        <small className=" ms-4">&nbsp;{customeremail}</small>
                      </small>
                    </div>
                    <div className="d-flex">
                      <small>
                        <span className="ms-2 me-4 mt-4">Customer Phone: </span>
                        {/* {console.log()} */}
                        <small className=" ms-3">&nbsp;090078601</small>
                      </small>
                    </div>

                    {/*  */}
                    {/*  */}
                    <div className="d-flex">
                      <small>
                        <span className="ms-2 me-4 mt-4">
                          Order&nbsp;Date:{" "}
                        </span>
                        <small className="ms-5">&nbsp;&nbsp; {orderdate}</small>
                      </small>
                    </div>
                    <div className="d-flex">
                      <small>
                        <span className="ms-2 me-4 mt-4">
                          Start&nbsp;Date:{" "}
                        </span>
                        <small className="ms-5">
                          &nbsp;&nbsp;&nbsp;&nbsp;{startDate}
                        </small>
                      </small>
                    </div>
                    <div className="d-flex">
                      <small>
                        <span className="ms-2 me-4 mt-4">
                          Cancel&nbsp;Date:
                        </span>
                        <small className="ms-5 ">&nbsp;{cancelDate}</small>
                      </small>
                    </div>

                    {/*  */}
                    {/*  */}
                    <div className="d-flex">
                      <small>
                        <span className="ms-2 me-4 mt-4">
                          Payment&nbsp;Term:
                        </span>
                        <small className="ms-4">
                          &nbsp;&nbsp;&nbsp;&nbsp;{paymentterm}
                        </small>
                      </small>
                    </div>
                  </div>
                  {/*  */}
                  {/*  */}
                  <div className="d-flex flex-column">
                    <div className=" me-5">
                      <div className=" text-dark d-flex mt-2">
                        <h6>
                          <span className="">
                            &ensp;Shipping&nbsp;Address:{" "}
                          </span>
                        </h6>
                      </div>
                      {/*  */}
                      {/*  */}
                      <div className="d-flex">
                        <small>
                          {/* <span className="ms-2 me-4">Ship&nbsp;To: </span> */}
                          <small className="">&nbsp;&nbsp;&nbsp;{shipTo}</small>
                        </small>
                      </div>
                      {/*  */}
                      {/*  */}
                      <div className="d-flex">
                        <small>
                          {/* <span className="ms-2 mt-4 ">
                        Ship&nbsp;To&nbsp;City:{" "}
                      </span> */}
                          <small className="ms-2">
                            &nbsp;{shipToCity},&nbsp;{shipToState},&nbsp;
                            {shipToPostcode}
                          </small>
                        </small>
                      </div>
                      {/*  */}
                      {/*  */}
                      <div className="d-flex">
                        <small>
                          {/* <span className="ms-2 mt-4 ">
                        Ship&nbsp;To&nbsp;country:{" "}
                      </span> */}
                          <small className="ms-2">&nbsp;{shipToCountry}</small>
                        </small>
                      </div>
                    </div>
                    {/* /////BILLING Adress */}
                    <div className="me-5 ">
                      <div className=" text-dark d-flex mt-2">
                        <h6>
                          <span className="">&ensp;Billing&nbsp;Address: </span>
                        </h6>
                      </div>
                      {/*  */}
                      {/*  */}
                      <div className="d-flex">
                        <small>
                          {/* <span className="ms-2 me-4">Ship&nbsp;To: </span> */}
                          <small className="">
                            &nbsp;&nbsp;&nbsp;{billingadress}
                          </small>
                        </small>
                      </div>
                      {/*  */}
                      {/*  */}
                      <div className="d-flex">
                        <small>
                          {/* <span className="ms-2 mt-4 ">
                        Ship&nbsp;To&nbsp;City:{" "}
                      </span> */}
                          <small className="ms-2">
                            &nbsp;{billingcity},&nbsp;{billingstate},&nbsp;
                            {billingpostcode}
                          </small>
                        </small>
                      </div>
                      {/*  */}
                      {/*  */}
                      <div className="d-flex">
                        <small>
                          {/* <span className="ms-2 mt-4 ">
                        Ship&nbsp;To&nbsp;country:{" "}
                      </span> */}
                          <small className="ms-2">&nbsp;{billingcountry}</small>
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </Row>
              <hr className="fw-bolder mx-4" />
              {/*  */}
              {/*  */}
              {/* {orders.map((detailz) => {
                return ( */}
              <Row className="mx-0">
                {ProductDetails.map((detail) => (
                  <div className="px-4 mt-4">
                    <div className="py-2 for_top-Content w-100 px-3">
                      <small className="text-dark">
                        <b>
                          {detail.Stylename}&nbsp;&nbsp;{detail.sku}
                        </b>
                        <br />
                        {detail.Material1}/{detail.Material2}/{detail.Material3}
                      </small>
                    </div>
                    <table className="forTableCollapse w-auto overflow-auto">
                      <tbody>
                        <tr id="tableRow" className=" w-auto overflow-auto">
                          <td rowSpan="2" id="tabledata" className="">
                            {" "}
                            <img
                              src={detail.Picture}
                              alt=""
                              className=" w-75 ms-3"
                            />
                          </td>
                          {runCallback(() => {
                            const row = [];
                            for (var i = 0; i < orderslist.length; i++) {
                              row.push(
                                vieworders[i] &&
                                  vieworders[i]
                                    .filter((admin) => {
                                      return admin.sku === detail.sku;
                                    })
                                    .sort((a, b) => {
                                      return a.size - b.size;
                                    })
                                    .map((detailz) => {
                                      return (
                                        <td id="tabledata" className="ps-2">
                                          <small className="fw-bolder pe-1">
                                            {detailz.size}
                                          </small>
                                        </td>
                                      );
                                    })
                              );
                              // row.push(<p key={i}>{i}</p>);
                            }
                            return row;
                          })}
                        </tr>
                        <tr id="tableRow">
                          {runCallback(() => {
                            const row = [];
                            for (var i = 0; i < orderslist.length; i++) {
                              row.push(
                                vieworders[i] &&
                                  vieworders[i]
                                    .filter((admin) => {
                                      return admin.sku === detail.sku;
                                    })
                                    .sort((a, b) => {
                                      return a.size - b.size;
                                    })
                                    .map((detailz) => {
                                      return (
                                        <td id="tabledata" className="ps-2">
                                          <small>
                                            {detailz.unitQuantity
                                              ? detailz.unitQuantity
                                              : detailz.quantity}
                                          </small>
                                        </td>
                                      );
                                    })
                              );
                              // row.push(<p key={i}>{i}</p>);
                            }
                            return row;
                          })}
                          {/* {} */}
                        </tr>
                      </tbody>
                    </table>
                    <div className="for_botton-Content d-flex justify-content-between ps-3">
                      <small className="text-dark mt-3">
                        <span>{detail.Size1}=</span>
                        <span className="">${detail.Price1}</span>
                      </small>
                      <small className="text-dark mt-3">
                        <span>{detail.Size2}=</span>
                        <span className="">${detail.Price2}</span>
                      </small>
                      <small className="text-dark mt-3">
                        <span>{detail.Size3}=</span>
                        <span className="">${detail.Price3}</span>
                      </small>
                      <div className="d-flex flex-column justify-content-end forQTP my-1 px-4">
                        <div className="d-flex justify-content-end">
                          <small className=" text-dark fw-bold">
                            {/* {runCallback(() => {
                              const row = [];
                              const listx = [];
                              for (var i = 0; i < orderslist.length; i++) {
                                row.push(
                                  orderslist[i] &&
                                    orderslist[i]
                                      .filter((admin) => {
                                        return admin.sku === detail.sku;
                                      })
                                      .map((li) => listx.push(li.unitQuantity))
                                );
                              }
                              return listx.length;
                            })} */}
                            {runCallback(() => {
                              const row = [];
                              const listx = [];
                              for (var i = 0; i < orderslist.length; i++) {
                                row.push(
                                  orderslist[i] &&
                                    orderslist[i]
                                      .filter((admin) => {
                                        return admin.sku === detail.sku;
                                      })
                                      .reduce((total, item) => {
                                        return total + +item.unitQuantity;
                                      }, null)
                                );
                                //   subtotal.push(row && row);
                                //   subtotal.forEach((c) => {
                                //     if (!listx.includes(c)) {
                                //         listx.push(c);
                                //     }
                                // });
                                //   console.log(listx )
                              }
                              return row;
                            })}
                            &nbsp;Pairs
                          </small>
                        </div>
                        <div>
                          <small className="text-dark fw-bolder">
                            <span className="">Total= </span>
                            <span className="">
                              $
                              {runCallback(() => {
                                const row = [];
                                const listx = [];
                                for (var i = 0; i < orderslist.length; i++) {
                                  row.push(
                                    orderslist[i] &&
                                      orderslist[i]
                                        .filter((admin) => {
                                          return admin.sku === detail.sku;
                                        })
                                        .reduce((total, item) => {
                                          return total + +item.sales;
                                        }, null)
                                  );
                                  //   subtotal.push(row && row);
                                  //   subtotal.forEach((c) => {
                                  //     if (!listx.includes(c)) {
                                  //         listx.push(c);
                                  //     }
                                  // });
                                  //   console.log(listx )
                                }
                                return row;
                              })}
                              {/* {orders.reduce((total, item) => {
                              return (
                                +total +
                                +item.unitQuantity * item.Price1
                              ).toFixed(2);
                            }, 0)} */}
                              {/* ${(+Price1 + +Price2 + +Price3).toFixed(2)} */}
                            </span>
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Row>
              {/* /////////////////////// HELLO */}

              {/*  */}
              {/* );
              })} */}
              {/* NEXT-CONTENT */}
              <div className="d-flex justify-content-between mx-4 my-5">
                <div className="signandImg">
                  <div className="forSignature fw-bolder my-3">
                    <img
                      src={SignImg}
                      alt="SignImg.png"
                      className="SignImage"
                    />
                  </div>
                  <div className="fw-bolder text-danger">
                    <FormGroup className="d-flex">
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Email To Customer"
                        onChange={() => ShowOtherContact()}
                      />
                    </FormGroup>
                  </div>
                  <div className={!showemail ? "showpartnercontact" : null}>
                    <div className="ShowHide">
                      <div>
                        {/* <p className="ms-1"> */}
                        <small>
                          {/* ///////
                             ././.
                             .,.,.,
                             /./,.,/,/ */}{" "}
                          {/* ///////
                             ././.
                             .,.,.,
                             /./,.,/,/ */}{" "}
                          {/* ///////
                             ././.
                             .,.,.,
                             /./,.,/,/ */}{" "}
                          {/* ///////
                             ././.
                             .,.,.,
                             /./,.,/,/ */}{" "}
                          {/* ///////
                             ././.
                             .,.,.,
                             /./,.,/,/ */}
                          {emails.map((getemails) => {
                            return (
                              <FormGroup className="d-flex">
                                <FormControlLabel
                                  control={<Checkbox />}
                                  label={getemails.email_1}
                                  value={getemails.email_1}
                                  //   onChange={(e) => {
                                  //     console.log(e.target.value)
                                  //     pdfmail != "" ?
                                  //       axios
                                  //         .post(`/send-pdf-email`, {
                                  //           email: e.target.value ,
                                  //         pdf:  pdfmail
                                  //             })
                                  //         .then((response) => {
                                  //           if (response.status === 200) {
                                  //             setdone(false);
                                  //             console.log("success");
                                  //             setNotify({
                                  //               isOpen: true,
                                  //               message: `Send Successfully`,
                                  //               type: "success",
                                  //             });
                                  //           }
                                  //           // setTimeout(() => {
                                  //           //   navigate("/AllAdmin");
                                  //           // }, 1000);
                                  //         })
                                  //         .catch((err) => {
                                  //           setdone(false);
                                  //           console.log(err.response);
                                  //           setNotify({
                                  //             isOpen: true,
                                  //             message: `Sending Failed`,
                                  //             type: "error",
                                  //           });
                                  //           console.log(err.response);
                                  //         }) : alert("no pdf available")

                                  //   }}
                                />
                              </FormGroup>

                              //     <div className="d-flex flex-column" >
                              //        <input
                              //   type="checkbox"
                              //   id=""
                              //   name="pdfsend"
                              //   value="pdfsend"
                              //   onChange={(e) => setPdfsend(e.target.value)}
                              // />&nbsp;<small>{getemails.email_1}</small>
                              //     </div>
                            );
                          })}
                        </small>

                        {/* <div>
                              <button className="CSV my-3" type="submit">
                                <small>Send&nbsp;PDF&nbsp;to&nbsp;Email</small>
                              </button>
                            </div> */}
                        {/* </p> */}
                      </div>
                    </div>
                  </div>
                  <div className="fw-bolder text-primary">
                    <FormGroup className="d-flex">
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Email To Admin"
                        // onChange={() => ShowOtherContact()}
                      />
                    </FormGroup>
                  </div>

                  {/* {pdf.map((pd) => (
                    <p>{pd.file}</p>
                  ))} */}
                </div>
                <div className="shippingTable">
                  <div className="next__content mt-5">
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <small className=" me-5">Sub&nbsp;Total:</small>
                          </td>
                          <td>
                            {" "}
                            <small>
                              $
                              {ProductDetails.map((detail) =>
                                runCallback(() => {
                                  const row = [];
                                  const listx = [];

                                  for (var i = 0; i < orderslist.length; i++) {
                                    row.push(
                                      orderslist[i] &&
                                        orderslist[i]
                                          .filter((admin) => {
                                            return admin.sku === detail.sku;
                                          })
                                          .reduce((total, item) => {
                                            return total + +item.sales;
                                          }, null)
                                    );
                                    subtotal.push(row && row);
                                    // console.log(subtotal)

                                    subtotal.forEach((c) => {
                                      if (!listx.includes(c)) {
                                        listx.push(c);
                                      }
                                    });
                                    // console.log(
                                    total =
                                      listx.length == orderslist.length ? (
                                        Array.prototype
                                          .concat(...listx)
                                          .filter(function (item) {
                                            return item !== null;
                                          })
                                          .reduce(function (a, b) {
                                            return a + b;
                                          }, null)
                                      ) : (
                                        <small></small>
                                      );
                                    // )

                                    // console.log(total)
                                  }
                                  return total;
                                  // listx.toString() ;
                                })
                              )}
                              {/* {orders.reduce((total, item) => {
                              return (
                                +total +
                                +item.unitQuantity * item.Price1 +
                                +item.unitQuantity
                              );
                            }, 0)} */}
                              {/* ${(+Price1 + +Price2 + +Price3).toFixed(2)} */}
                            </small>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {" "}
                            <small className="me-5">Shipping&nbsp;Fee</small>
                          </td>
                          <td>
                            <small>$0.00</small>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <small className="me-5">Tax</small>
                          </td>
                          <td>
                            <small>$0.00</small>
                          </td>
                        </tr>
                        {/* <tr>
                      <hr />
                      </tr> */}
                        <tr>
                          <td>
                            <small className="">Total</small>
                          </td>
                          <td>
                            <small className="mt-2">${total}</small>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* {pdf.map((pd) => (
                    <p>{pd.file}</p>
                  ))} */}

                {/* <div className="bg-white">
      {runCallback(() => {
        const row = [];
        for (var i = 0; i < orderslist.length; i++) {
          orderslist[i] &&  orderslist[i].map((list) => (
            row.push( <p key={list._id}>{list.size}</p>)
          ))
          // row.push(<p key={i}>{i}</p>);
        }
        return row;
      })}
    </div> */}

                {/* {
        
        
         orderslist.map((list) => (
              <p key={list._id}>{list.size}</p>
            ))
            
         
      } */}
              </div>
            </Container>
            {/* ////// */}
          </div>
        )}
      </Box>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};
export default InformationOrder;
