import React, { useState } from "react";
import DatePicker from "react-date-picker";

const TestDatePicker = (props) => {
  const [value, onChange] = useState(new Date());

  const onDateChange = (newDate) => {
    //Your custom code here
    props.handleOnclick(newDate);
    onChange(newDate);
  };
  return (
    <div>
      <DatePicker onChange={onDateChange} value={value} />
    </div>
  );
};

export default TestDatePicker;
