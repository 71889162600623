import axios from "axios";
import React, { Fragment, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import LinearWithValueLabel from "../Loader/progressLoad";
import Notification from "../AlertNotification/Message";

export default function UploadImages() {
  const [files, setFiles] = useState([]);
  const [done, setdone] = useState(false);
  const [showbutton, setshowbutton] = useState(false)
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const onInputChange = (e) => {
    setFiles(e.target.files);
    setshowbutton(true)
  };
  const handleUpload = (e) => {
    e.preventDefault();
    setdone(true);

    const data = new FormData();

    for (let i = 0; i < files.length; i++) {
      data.append("files", files[i]);
    }

    axios
      .post("/upload-product-images", data, {

        headers: {
  
          "x-auth-header": sessionStorage.getItem("tokan")
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setdone(false);
          setNotify({
            isOpen: true,
            message: `${response.data.message}`,
            type: "success",
          });
        }
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        setdone(false);
        console.log(err);
        setNotify({
          isOpen: true,
          message: `${err.response.data.message}`,
          type: "error",
        });
        console.log(err);
      });
  };

  const [hx, sethx] = useState(false);
  const handleClose = () => {
    sethx(false);
  };
  const handleOpen = () => {
    sethx(!hx);
  };
  const [final, setFinal] = useState(false);
  const clickchange = () => {
    setFinal(false);
  };
  return (
    <div>
      <div className="csvimport">
        {done ? (
          <LinearWithValueLabel className="loaderx" />
        ) : (
          <form>
            <div className={!hx ? "main12" : "main11"}>
              <div className="appX">
                <div className="position-relative">
                  <label htmlFor="icon-button-file">
                    <div className="">
                      <input
                        multiple
                        accept="image/*"
                        id="icon-button-file"
                        type="file"
                        onChange={onInputChange}
                      />
                      <AddAPhotoIcon
                        className={showbutton ? "iconX mt-4" : "iconf"}
                        style={{ color: "#ba0000" }}
                        onChange={clickchange}
                      />
                    </div>
                  </label>
                </div>
                <small className="Selected2 mb-4">
                  <span className="fw-bolder">{files.length}</span> Files
                  Selected
                </small>
                <div className=" mt-5">
                {/* {showbutton ? "btnsubmit px-5 mt-5" : "d-hide"} */}
                  <button
                    type="submit"
                    className= {showbutton ? "btnsubmit px-5 mt-5" : "d-none px-5 mt-5"}
                    onClick={handleUpload}
                  >
                    Upload
                  </button>
                </div>
              </div>
            </div>
          </form>
        )}
        <Notification notify={notify} setNotify={setNotify} />
      </div>
    </div>
  );
}
