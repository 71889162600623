import React, { useState, useEffect, useContext } from "react";

import { UserContext } from "../../App";

import axios from "axios";
import { Form, Col, Row } from "react-bootstrap";
import ResponsiveDrawer from "../Drawer/drawer";
import { useNavigate, useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Notification from "../AlertNotification/Message";
import Avatar from "@mui/material/Avatar";
import usePasswordToggle from "../PasswordToggle/usePasswordToggle";
import usePasswordToggleX from "../PasswordToggle/usePasswordToggleX";
import CircularIndeterminate from "../Loader/Loader";
const drawerWidth = 240;
const Input = styled("input")({
  display: "none",
});

const UpdateReps = () => {
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState({
    file: [],
    filepreview: null,
  });
  const [loadFile, setLoadFile] = useState();
  const [upload, setupload] = useState(true);
  const [size, setsize] = useState(false);
  ////////
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [shopname, setShopname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [joiningdate, setJoiningdate] = useState("");
  const [description, setDescription] = useState("");
  const [country, setCountry] = useState("");
  const [confirmpassword, setconfirmpassword] = useState("");
  const [done, setdone] = useState(false);
const [tokenauth, settokenauth] = useState("")
  const [PasswordInputType, ToggleIcon] = usePasswordToggle();
  const [PasswordInputTypex, ToggleIconx] = usePasswordToggleX();
  // ///////
  // ///////
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const HandleChange = (event) => {
    if (event.target.files[0].size > 100 * 1024) {
      setsize(true);
    } else if (event.target.files[0] == null) {
      setSelectedFile({
        // ...selectedFile,
        file: [],
        filepreview: null,
      });
      setupload(false);
      setsize(false);
    } else if (event.target.files[0].size <= 100 * 1024) {
      setSelectedFile({
        ...selectedFile,
        file: event.target.files[0],
        filepreview: URL.createObjectURL(event.target.files[0]),
      });
      setupload(false);
      setsize(false);
    }
  };
  const HandleSubmit = (e) => {
    e.preventDefault();
    setdone(true);
    UpdateEmployee();
  };
  const { mail } = useParams();
  //
  const getEmployees =  () => {
    axios.post("/get-token" ,{
      id: sessionStorage.getItem("token_id")
    }).then((res) => {
      settokenauth(res.data.token)
    axios.post(`/get-employees`, {} ,{
      
      headers: {
        
        "x-auth-header": res.data.token
      },
  }).then((response) => {
      setdone(true);
      response.data
        .filter((employees) => {
          return mail === employees.email;
        })
        // eslint-disable-next-line array-callback-return
        .map(function (employee) {
          setFname(employee.firstname);
          setLname(employee.lastname);
          setEmail(employee.email);
          setPhone(employee.phone);
          setJoiningdate(employee.joiningdate);
          setDescription(employee.description);
          setShopname(employee.storename);
          setSelectedFile(employee.image);
        });
    }).catch(err => console.log(err.response))
  }).catch((err) => console.log(err))
  };
  useEffect(() => {
    sessionStorage.getItem("payloadout") != "true"
      ? navigate("/")
      : getEmployees();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const UpdateEmployee = () => {
    const formData = new FormData();
    formData.append("firstname", fname);
    formData.append("lastname", lname);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("joiningdate", joiningdate);
    formData.append("storename", shopname);
    formData.append("description", description);
    formData.append("file", selectedFile == null ? "hello" : selectedFile.file);
    
    axios
      .post(`/update-employee/${mail}`, formData, {
      
        headers: {
          
          "x-auth-header": tokenauth
        },
    })
      .then((response) => {
        if (response.status === 200) {
          setdone(false);
          console.log("success");
          setNotify({
            isOpen: true,
            message: `Updated Successfully`,
            type: "success",
          });
        }
        setTimeout(() => {
          navigate("/AllReps");
        }, 1000);
      })
      .catch((err) => {
        setdone(false);
        console.log(err.response);
        setNotify({
          isOpen: true,
          message: `Update Failed`,
          type: "error",
        });
        console.log(err.response);
      });
  };

  return (
    <div>
      <ResponsiveDrawer heading="Selling Reps Management" />
      <Box
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
        component="main"
      >
        <Toolbar />
        {!done ? (
          <CircularIndeterminate className="AddAgentloader" />
        ) : (
          <div className="for_form my-5 mx-4">
            <Form onSubmit={HandleSubmit}>
              <div className=" d-flex  justify-content-between mt-5  pt-3 pr-3 pb-3 btnz">
                <h5 className="">Update&nbsp;Selling&nbsp;Representative</h5>
                <div className="btnp">
                  <button
                    className="btn1 mx-2"
                    onClick={() => navigate("/allagents")}
                  >
                    Cancel
                  </button>
                  <button className="btn2" type="submit">
                    Update
                  </button>
                </div>
              </div>
              {/* Form */}
              <Row xs="1" sm="1" md="2" lg="3" xl="3" className="mt-3">
                <Col>
                  <label htmlFor="icon-button-file">
                    <Input
                      accept="image/*"
                      id="icon-button-file"
                      type="file"
                      // defaultvalue={selectedFile.file}
                      onChange={HandleChange}
                    />
                    {!upload ? (
                      upload ? (
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                        >
                          <div className="addicon">
                            <AddIcon className="icon" />
                          </div>
                        </IconButton>
                      ) : (
                        <img
                          className="previewimg"
                          src={selectedFile.filepreview}
                          alt=""
                        />
                      )
                    ) : selectedFile == null ? (
                      <Avatar className="previewimg" />
                    ) : (
                      // <img src={Ellipse5} alt="Image" className={classes.xdiv} />
                      <img src={selectedFile} alt="" className="previewimg" />
                    )}

                    <br />
                  </label>

                  <div className={size ? "sizeshow" : "sizehide"}>
                    <small>File size excedded than 100KB</small>
                  </div>
                </Col>
              </Row>
              <Row xs={1} sm={1} md={2} lg={3} xl={3}>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="First Name"
                      required
                      onChange={(e) => setFname(e.target.value)}
                      value={fname}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Last Name"
                      required
                      value={lname}
                      onChange={(e) => setLname(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Email"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row xs={1} sm={1} md={2} lg={3} xl={3}>
                <Col>
                  <Form.Group className="mb-3" con>
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type={PasswordInputType}
                      placeholder="Password"
                      // required
                      // onChange={(e) => setFname(e.target.value)}
                    />
                    <span className="password-toogle-icon">{ToggleIcon}</span>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" con>
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                      type={PasswordInputTypex}
                      placeholder="Confirm Password"
                      name="confirmpassword"
                      // required
                      onChange={(e) => setconfirmpassword(e.target.value)}
                    />
                    <span className="password-toogle-icon">{ToggleIconx}</span>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Phone"
                      // required
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row xs={1} sm={1} md={2} lg={3} xl={3}>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Cell#</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Cell#"
                      // required
                      value={joiningdate}
                      onChange={(e) => setJoiningdate(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                {/* <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Store Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Store name"
                      // required
                      value={shopname}
                      onChange={(e) => setShopname(e.target.value)}
                    />
                  </Form.Group>
                </Col> */}
              </Row>
              {/* <Row>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Descrition</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      // required
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row> */}
            </Form>
          </div>
        )}
      </Box>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};

export default UpdateReps;
