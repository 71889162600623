import React, { useState, useEffect, useContext } from "react";

import { UserContext } from "../../App";


import Box from "@mui/material/Box";
import { useParams } from "react-router-dom";
import ResponsiveDrawer from "../Drawer/drawer";
import Toolbar from "@mui/material/Toolbar";
import axios from "axios";
import CircularIndeterminate from "../Loader/Loader";
import Avatar from "@mui/material/Avatar";
import SecondCard from "./SecondCard";
import ChartTwo from "./ChartTwo";
import { Row, Col } from "react-bootstrap";
import EstimatedBonus from "./EstimatedBonus";
import { useNavigate } from "react-router-dom";


// FOR DROP DOWN MENU
//
const drawerWidth = 240;
//
const RepsDetail = (props) => {
  const { mail } = useParams();
  // console.log(mail);
  const navigate = useNavigate();

  const [selectedFile, setSelectedFile] = useState({
    file: [],
    filepreview: null,
  });
  const [done, setdone] = useState(false);
  const [image, setImage] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setlastname] = useState("");

  const setAgents =  () => {
    axios.post("/get-token" ,{
      id: sessionStorage.getItem("token_id")
    }).then((res) => {
    axios.post(`/get-employees`,{} , {
      
      headers: {
        
        "x-auth-header": res.data.token
      },
  }).then((response) => {
    
    setdone(true);
    // console.log(response.data);
    response.data
      .filter((agent) => {
        return mail == agent.email;
      })
      .map((FindAgent) => {
        setSelectedFile(FindAgent.image);
        setPhone(FindAgent.phone);
        setEmail(FindAgent.email);
        setFirstname(FindAgent.firstname);
        setlastname(FindAgent.lastname);
      });
    }).catch(err => console.log(err.response))
  }).catch((err) => console.log(err))
  };


  useEffect(() => {
    sessionStorage.getItem("payloadout") != "true"
      ? navigate("/")
      :
    sessionStorage.setItem("id", "2");

    setAgents();
  }, []);

  return (
    <div>
      <ResponsiveDrawer heading="Agent Management" />
      <Box
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
        component="main"
      >
        <Toolbar />

        {!done ? (
          <CircularIndeterminate className="loader" />
        ) : (
          <div className="maindiv ms-2 me-2">
            <div className="top__section px-4 pt-3">
              <div className="d-flex">
                <div className="for__img me-3">
                  {selectedFile == null ? (
                    <Avatar className="previewimg" />
                  ) : (
                    <img
                      src={selectedFile}
                      alt="Image"
                      className="AgentProfileImg"
                    />
                  )}
                </div>
                <div>
                  <div className="d-flex">
                    <h5 className="fw-bolder me-2">{firstname}</h5>
                    <h5 className="fw-bolder">{lastname}</h5>
                  </div>
                  <p>
                    <small className="d-flex justify-content-left">
                      <span className="fw-bolder">Phone: </span>&nbsp;&nbsp;
                      {phone}
                    </small>

                    <small>
                      <span className="fw-bolder">Email: </span>
                      &nbsp;&nbsp;
                      {email}
                    </small>
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="my-3">
              <SecondCard />
            </div>
            <Row className="me-5 ms-2">
              <Col lg={8} md={6}>
                <div className="for__chart">
                  <ChartTwo />
                </div>
              </Col>
              <Col lg={4} md={6}>
                <div className="for__chart">
                  <EstimatedBonus />
                </div>
              </Col>
            </Row> */}
          </div>
        )}
      </Box>
    </div>
  );
};

export default RepsDetail;
