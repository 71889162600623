/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import ResponsiveDrawer from "../Drawer/drawer";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { Form, Row, Col } from "react-bootstrap";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import { BiSearch } from "react-icons/bi";
import Notification from "../AlertNotification/Message";
import ConfirmDialog from "../AlertNotification/confirmdialog";
import CircularIndeterminate from "../Loader/Loader";
////////////
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useReactToPrint } from "react-to-print";

////////////
const drawerWidth = 240;
const columns = [
  // {
  //   id: "img",
  //   label: "Avatar",
  //   minWidth: 100,
  //   align: "left",
  // },
  {
    id: "density",
    label: "Style Factory",
    minWidth: 200,
    align: "left",
    format: (value) => value.toFixed(2),
  },
  {
    id: "name",
    label: "",
    minWidth: 200,
    align: "left",
  },

  {
    id: "population",
    label: "Quantity",
    minWidth: 100,
    align: "left",
  },
];
//
const columnsTwo = [
  {
    id: "img",
    label: "",
    minWidth: 130,
    align: "left",
  },

  {
    id: "population",
    label: "Grand Total",
    minWidth: 100,
    align: "left",
  },
];
//
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
let newarr = [];
function SalesByStyleFactory() {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Total Pairs By Style Name",
    // onAfterPrint: ()=> alert('Print success')
  });
  const navigate = useNavigate();
  //
  //
  const [show, setShow] = useState(true);
  const SortBy = () => {
    setShow(!show);
  };
  // -------//
  const HightTOLow = () => {
    customers.sort((c, d) => d.PairsSold - c.PairsSold);
    setShow(true);
  };
  // -------//
  const LowToHigh = () => {
    customers.sort((f, g) => f.PairsSold - g.PairsSold);
    setShow(true);
  };
  const storeName = () => {
    customers.sort((a, b) => a.storeName.localeCompare(b.storeName));
    setShow(true);

    // customers.sort((a, b) => a.sku.localeCompare(b.sku));
  };
  // -------//
  const SortDescending = () => {
    customers.sort((c, d) => c.PairsSold - d.PairsSold);
    setShow(true);
  };
  //
  //
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  //
  const [One, setOne] = React.useState(null);
  //
  //   const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [filter, setfilter] = useState("");
  const [customers, setcustomers] = useState("");
  const [done, setdone] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(2000);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [tokenauth, settokenauth] = useState("");
  const [reportdata, setreportdata] = useState([]);
  useEffect(() => {
    setdone(true);

    axios
      .post("get-token", {
        id: sessionStorage.getItem("token_id"),
      })
      .then((response) => {
        settokenauth(response.data.token);
        axios
          .post(
            "/sales-report",
            {},
            {
              headers: {
                "x-auth-header": response.data.token,
              },
            }
          )
          .then((response) => {
            setreportdata(response.data.mainArray);
            response.data.mainArray.map((data) => {
              newarr.push({
                styleFactory: data[0].styleFactory,
                total: data[data.length - 1].totalQty,
              });
            });
            // console.log(response.data);
            // console.log(
            //   response.data.mainArray[0][0].styleFactory.split("-")[1]
            // );

            // const sortedData = response.data.mainArray.sort((a, b) => {
            //   let fa = a[0].styleFactory.split("-")[1].toLowerCase(),
            //     fb = b[0].styleFactory.split("-")[1].toLowerCase();

            //   if (fa < fb) {
            //     return -1;
            //   }
            //   if (fa > fb) {
            //     return 1;
            //   }
            //   return 0;
            // });
            // console.log(data.sort((a, b) => a[1] - b[1]));
            // console.log(sortedData);
            setcustomers(response.data.grandTotal);
            setdone(false);
          })
          .catch((err) => {
            console.log(err.response);
          });
      })
      .catch((err) => console.log(err));
  }, []);

  //
  const [sorat, setsorat] = useState(false);
  const [sorttotal, setsorttotal] = useState(false);

  const asendsort = () => {
    setsorat(false);
    // console.log(newarr);
    const finalArray = [];

    newarr.sort((a, b) => {
      const num1 = a.styleFactory.split("-")[0];
      const num2 = b.styleFactory.split("-")[0];
      if (num1 < num2) return -1;
      if (num1 > num2) return 1;
      return 0;
    });

    newarr.forEach((item) => {
      if (finalArray.length == 0) {
        const tempArray = newarr.filter((inner_item) => {
          return (
            inner_item.styleFactory.split("-")[0] ==
            item.styleFactory.split("-")[0]
          );
        });
        finalArray.push(tempArray);
      } else {
        const findData = finalArray?.find((a, index) => {
         
          return (
            a[0]?.styleFactory?.split("-")[0] ==
            item?.styleFactory.split("-")[0]
          );
        });

        if (!findData) {
          const tempArray = newarr.filter((inner_item) => {
            return (
              inner_item.styleFactory.split("-")[0] ==
              item.styleFactory.split("-")[0]
            );
          });
          finalArray.push(tempArray);
        }
      }
    });

    finalArray.forEach((item) => {
      item.sort((a, b) => {
        const num1 = a.styleFactory.split("-")[1];
        const num2 = b.styleFactory.split("-")[1];
        if (num1 < num2) return -1;
        if (num1 > num2) return 1;
        return 0;
      });
    });

    const finalarr = [];

    // console.log(finalArray);
    finalArray.map((data) => data.map((odata) => finalarr.push(odata)));
    // console.log(finalarr);
    newarr = finalarr;
  };
  // const asendsort = () => {
  //   setsorat(false);

  //   let albhabets = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"]
  //   let sortByAphabetArrays = []
  //   albhabets.forEach((item) => {
  //     let arr = newarr.filter((x) => {
  //       return x.styleFactory.split("-")[1].toLowerCase().startsWith(item);
  //     });
  //      sortByAphabetArrays.push(arr);
  //   })

  //   sortByAphabetArrays.forEach((item) => {
  //     item.sort((a, b) => {
  //       const alphaA = a.styleFactory.split("-")[0];
  //       const alphaB = b.styleFactory.split("-")[0];
  //       if (alphaA < alphaB) return -1;
  //       if (alphaA > alphaB) return 1;
  //       return 0;
  //     });
  //   });

  //   newarr = [];

  //    sortByAphabetArrays.forEach((item) => {
  //     item.forEach((item1) => {
  //       newarr.push(item1);
  //     });
  //    });

  //   // console.log(newarr);

  //   // reportdata.sort((a, b) => {
  //   //   let fa = a[0].styleFactory.split("-")[1].toLowerCase(),
  //   //     fb = b[0].styleFactory.split("-")[1].toLowerCase();

  //   //   if (fa < fb) {
  //   //     return -1;
  //   //   }
  //   //   if (fa > fb) {
  //   //     return 1;
  //   //   }
  //   //   return 0;
  //   // });
  // };
  // const desendsort = () => {
  //   setsorat(true);

  //   let albhabets = [
  //     "a",
  //     "b",
  //     "c",
  //     "d",
  //     "e",
  //     "f",
  //     "g",
  //     "h",
  //     "i",
  //     "j",
  //     "k",
  //     "l",
  //     "m",
  //     "n",
  //     "o",
  //     "p",
  //     "q",
  //     "r",
  //     "s",
  //     "t",
  //     "u",
  //     "v",
  //     "w",
  //     "x",
  //     "y",
  //     "z",
  //   ];
  //   let sortByAphabetArrays = [];
  //   albhabets.reverse().forEach((item) => {
  //     let arr = newarr.filter((x) => {
  //       return x.styleFactory.split("-")[1].toLowerCase().startsWith(item);
  //     });
  //     sortByAphabetArrays.push(arr);
  //   });

  //   sortByAphabetArrays.forEach((item) => {
  //     item.sort((a, b) => {
  //       const alphaA = a.styleFactory.split("-")[0];
  //       const alphaB = b.styleFactory.split("-")[0];

  //       if (alphaA < alphaB) return 1;
  //       if (alphaA > alphaB) return -1;
  //       return 0;
  //     });
  //   });

  //   newarr = [];

  //   sortByAphabetArrays.forEach((item) => {
  //     item.forEach((item1) => {
  //       newarr.push(item1);
  //     });
  //   });

  //   // console.log(newarr);
  //   // reportdata.sort((a, b) => {
  //   //   let fa = a[0].styleFactory.split("-")[1].toLowerCase(),
  //   //     fb = b[0].styleFactory.split("-")[1].toLowerCase();

  //   //   if (fa < fb) {
  //   //     return 1;
  //   //   }
  //   //   if (fa > fb) {
  //   //     return -1;
  //   //   }
  //   //   return 0;
  //   // });
  // };
  const desendsort = () => {
    setsorat(true);
    // console.log(newarr);
    const finalArray = [];

    newarr.sort((a, b) => {
      const num1 = a.styleFactory.split("-")[0];
      const num2 = b.styleFactory.split("-")[0];
      if (num1 < num2) return 1;
      if (num1 > num2) return -1;
      return 0;
    });

    newarr.forEach((item) => {
      if (finalArray.length == 0) {
        const tempArray = newarr.filter((inner_item) => {
          return (
            inner_item.styleFactory.split("-")[0] ==
            item.styleFactory.split("-")[0]
          );
        });
        finalArray.push(tempArray);
      } else {
        const findData = finalArray?.find((a, index) => {
          console.log(a);
          console.log("a", item);
          return (
            a[0]?.styleFactory?.split("-")[0] ==
            item?.styleFactory.split("-")[0]
          );
        });

        if (!findData) {
          const tempArray = newarr.filter((inner_item) => {
            return (
              inner_item.styleFactory.split("-")[0] ==
              item.styleFactory.split("-")[0]
            );
          });
          finalArray.push(tempArray);
        }
      }
    });

    finalArray.forEach((item) => {
      item.sort((a, b) => {
        const num1 = a.styleFactory.split("-")[1];
        const num2 = b.styleFactory.split("-")[1];
        if (num1 < num2) return 1;
        if (num1 > num2) return -1;
        return 0;
      });
    });

    const finalarr = [];

    console.log(finalArray);
    finalArray.map((data) => data.map((odata) => finalarr.push(odata)));
    console.log(finalarr);
    newarr = finalarr;
  };
  const asendsorttotal = () => {
    // console.log("assend");
    setsorttotal(false);
    newarr.sort((a, b) => {
      let fa = parseFloat(a.total),
        fb = parseFloat(b.total);
      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
    // reportdata.sort((a, b, index) => {
    //   if (
    //     a.slice(-1)[0].totalQty &&
    //     a.slice(-1)[0].totalQty < b.slice(-1)[0].totalQty &&
    //     b.slice(-1)[0].totalQty
    //   ) {
    //     return -1;
    //   }
    //   if (
    //     a.slice(-1)[0].totalQty &&
    //     a.slice(-1)[0].totalQty > b.slice(-1)[0].totalQty &&
    //     b.slice(-1)[0].totalQty
    //   ) {
    //     return 1;
    //   }
    //   return 0;
    // });
  };

  const desendsorttotal = () => {
    setsorttotal(true);
    newarr.sort((a, b) => {
      let fa = parseFloat(a.total),
        fb = parseFloat(b.total);
      if (fa < fb) {
        return 1;
      }
      if (fa > fb) {
        return -1;
      }
      return 0;
    });
    // reportdata.sort((a, b, index) => {
    //   if (
    //     a.slice(-1)[0].totalQty &&
    //     a.slice(-1)[0].totalQty < b.slice(-1)[0].totalQty &&
    //     b.slice(-1)[0].totalQty
    //   ) {
    //     return 1;
    //   }
    //   if (
    //     a.slice(-1)[0].totalQty &&
    //     a.slice(-1)[0].totalQty > b.slice(-1)[0].totalQty &&
    //     b.slice(-1)[0].totalQty
    //   ) {
    //     return -1;
    //   }
    //   return 0;
    // });
  };
  //
  //
  return (
    <div className="tableX">
      <ResponsiveDrawer heading="Reports" />
      <Box
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
        component="main"
      >
        <Toolbar />

        <div className="position-relative mt-5">
          <div className="d-flex justify-content-between mx-4">
            <div>
              <Button
                id="demo-customized-button"
                aria-controls="demo-customized-menu"
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                // variant="contained"
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon style={{ color: "#f00e0e" }} />}
              >
                <span className="fw-bolder text-dark">
                  Total&nbsp;Sales&nbsp;by&nbsp;StyleFactory
                </span>
              </Button>
              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                  "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <MenuItem
                  disableRipple
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  onClick={() => {
                    navigate("/AllStyleSold");
                  }}
                >
                  All&nbsp;Styles&nbsp;Sold
                </MenuItem>
                <MenuItem
                  disableRipple
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  onClick={() => {
                    navigate("/TotalSales");
                  }}
                >
                  Total&nbsp;Sales&nbsp;by&nbsp;Date
                </MenuItem>
                <MenuItem
                  disableRipple
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  onClick={() => {
                    navigate("/SalesByStyleFactory");
                  }}
                >
                  Total&nbsp;Sales&nbsp;by&nbsp;StyleFactory
                </MenuItem>
                <MenuItem
                  disableRipple
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  onClick={() => {
                    navigate("/StoreOrderStatus");
                  }}
                >
                  Store&nbsp;Order&nbsp;Status
                </MenuItem>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem
                  disableRipple
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  onClick={() => {
                    navigate("/SalesByStore");
                  }}
                >
                  Total&nbsp;Sales&nbsp;by&nbsp;Store&nbsp;Name
                </MenuItem>
                <MenuItem
                  disableRipple
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  onClick={() => {
                    navigate("/FactoryStyleByStoreName");
                  }}
                >
                  Factory&nbsp;Style&nbsp;by&nbsp;Store&nbsp;Name
                </MenuItem>
                <MenuItem
                  disableRipple
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  onClick={() => {
                    navigate("/TotalPairsByStyle");
                  }}
                >
                  Total&nbsp;Pairs&nbsp;by&nbsp;Style&nbsp;Name
                </MenuItem>
              </StyledMenu>
            </div>

            <div className="d-flex gap-3">
              <div>
                {sorat ? (
                  <Button
                    variant="contained"
                    endIcon={<ArrowUpwardIcon />}
                    onClick={asendsort}
                    className="cursor backgrnd"
                    color="info"
                  >
                    Sort&nbsp;Style&nbsp;Factory
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    endIcon={<ArrowDownwardIcon />}
                    onClick={desendsort}
                    className="cursor backgrnd"
                    color="info"
                  >
                    Sort&nbsp;Style&nbsp;Factory
                  </Button>
                )}
              </div>
              <div className="ml-3">
                {sorttotal ? (
                  <Button
                    variant="contained"
                    endIcon={<ArrowUpwardIcon />}
                    onClick={asendsorttotal}
                    className="cursor backgrnd"
                    color="info"
                  >
                    Quantity{" "}
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    endIcon={<ArrowDownwardIcon />}
                    onClick={desendsorttotal}
                    className="cursor backgrnd"
                    color="info"
                  >
                    Quantity
                  </Button>
                )}
              </div>
              <div className="position-relative ms-3 w-100">
                <Form>
                  <Form.Control
                    placeholder="Search by StyleFactory"
                    type="search"
                    className="input_field"
                    onChange={(e) => setfilter(e.target.value)}
                  />
                  <BiSearch className="search_Agent1" />
                </Form>
              </div>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-end m-4">
            <div>
              <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="ms-3 py-2 px-3 ExcelDownlad download-table-xls-button"
                table="table-to-xls"
                filename="Total Pairs By Style Name"
                sheet="Total Pairs By Style Name"
                buttonText="Export&nbsp;CSV"
                size="small"
              />
            </div>
            <div>
              <Button
                variant="contained"
                // endIcon={<ArrowUpwardIcon />}
                onClick={handlePrint}
                className="cursor backgrnd"
                color="info"
              >
                Download&nbsp;PDF
              </Button>
            </div>
          </div>
          {done ? (
            <CircularIndeterminate className="reportsales" />
          ) : (
            <div className="for__table m-4">
              <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer sx={{ maxHeight: 730 }}>
                  <Table
                    stickyHeader
                    aria-label="sticky table"
                    id="table-to-xls"
                    ref={componentRef}
                  >
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    {/* {console.log(newarr)} */}
                    {newarr.length > 0 ? (
                      newarr
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        // eslint-disable-next-line array-callback-return
                        .filter((admin) => {
                          if (filter === "") {
                            return newarr;
                          } else if (
                            admin.styleFactory &&
                            admin.styleFactory
                              .toString()
                              .toLowerCase()
                              .includes(filter.toString().toLowerCase())
                          ) {
                            return newarr;
                          }
                        })
                        .map((data) => (
                          <>
                            <TableBody className="">
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={data.styleFactory}
                              >
                                <TableCell className="fw-bold">
                                  {data.styleFactory}
                                </TableCell>
                                <TableCell></TableCell>

                                <TableCell className="fw-bold">
                                  {data.total}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </>
                        ))
                    ) : (
                      <h5 className="text-center mt-5 fw-bolder">No Data</h5>
                    )}
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        {columnsTwo.map((column) => (
                          <TableCell
                            className="fw-bolder"
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                      <TableRow hover role="checkbox" tabIndex={-1}>
                        <TableCell className="text-light"></TableCell>
                        <TableCell></TableCell>

                        <TableCell>{customers}</TableCell>
                      </TableRow>
                    </TableHead>
                  </Table>
                </TableContainer>
                <TablePagination
                  className="mx-5 mt-3 px-5"
                  rowsPerPageOptions={[2000, 5000, 8000]}
                  component="div"
                  count={newarr.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </div>
          )}
        </div>
      </Box>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </div>
  );
}

export default SalesByStyleFactory;
