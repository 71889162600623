import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "semantic-ui-css/semantic.min.css";
// import reportWebVitals from "./reportWebVitals";
// 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
// https://kinderland-apis.herokuapp.com
// http://3.13.138.90:3004
// https://venettini.app
// react-scripts --openssl-legacy-provider start
import axios from "axios";
axios.defaults.baseURL = "https://venettini.app";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
