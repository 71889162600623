import React, { useState, useEffect, useContext } from "react";

import { UserContext } from "../../App";
import { useNavigate } from "react-router-dom";

import CloseIcon from "@mui/icons-material/Close";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { Form, Col, Row } from "react-bootstrap";
import * as XLSX from "xlsx";
import axios from "axios";
import Notification from "../AlertNotification/Message";
import LinearWithValueLabel from "../Loader/progressLoad";
//
//
export default function ImportCSV() {
  //
  //
  const [done, setdone] = useState();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [items, setItems] = useState([]);
  //
  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
      //
      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: "buffer" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);
        //
        resolve(data);
        setItems(data);
        // setdone(true);
      };
      //
      fileReader.onerror = (error) => {
        console.log(error);
        reject(error);
      };
    });
    //
    promise.then((d) => {
      // console.log(d);
      setdone(true);
      axios
        .post("/import-customers", {
          customers: d,
        }, {

          headers: {
  
            "x-auth-header": tokenauth && tokenauth
          },
        })
        .then((response) => {
          console.log(response.status);
          if (response.status === 200) {
            setdone(false);
            setNotify({
              isOpen: true,
              message: `Customers Imported Successfully`,
              type: "success",
            });
          }
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((err) => {
          setdone(false);
          console.log(err);
          setNotify({
            isOpen: true,
            message: `Failed To Import Customers`,
            type: "error",
          });
          console.log(err);
        });
      setItems(d);
    });
  };
  //
  //
  //
  const [hx, sethx] = useState(false);
  const handleClose = () => {
    sethx(false);
  };
  const handleOpen = () => {
    sethx(!hx);
  };
  useEffect(() => {
    sessionStorage.getItem("payloadout") != "true"
      ? navigate("/")
      : console.log("Logged In");
  }, []);
  const [tokenauth, settokenauth] = useState("")
  useEffect(() => {

 axios.post("/get-token", {
      id: sessionStorage.getItem("token_id")
    }).then((res) => {
      settokenauth(res.data.token)
    }).catch(err => console.log(err.response))
  }, []);
  const navigate = useNavigate();

  return (
    <div>
      <div className="csvimport">
        {done ? (
          <LinearWithValueLabel className="loaderx" />
        ) : (
          <Form>
            <div className={!hx ? "main12" : "main11"}>
              <div className="appXp">
                <div className="position-relative">
                  <label>
                    <div>
                      <Form.Control
                        type="file"
                        onChange={(e) => {
                          setdone(true);

                          const file = e.target.files[0];
                          readExcel(file);
                        }}
                      />
                      <FileUploadIcon
                        className="iconX mt-5"
                        style={{ color: "#ba0000" }}
                      />
                    </div>
                  </label>
                  <small className="Selected">
                    <span className="fw-bolder">{items.length}</span> Selected
                  </small>
                </div>
                <div className="for_updateBtn"></div>
              </div>
            </div>
          </Form>
        )}
      </div>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
}
