import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Avatar1 from "../Images/Ellipse1.png";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import useStyles from "./style";
import axios from "axios";
import CircularIndeterminate from "../Loader/Loader";
import Notification from "../AlertNotification/Message";
import Dropdown from "react-bootstrap/Dropdown";
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <span className="threedots" />
  </a>
));
export default function FirebaseNotificationz({ heading }) {
  const navigate = useNavigate();
  const classes = useStyles();
  const [notification, setnotification] = useState([]);
  const [done, setdone] = useState(false);
  const [reload, setreload] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const GetNotifications = () => {
    axios.post("get-token", {
      id: sessionStorage.getItem("token_id")
     }).then((res) => {
     axios.post("/get-admin-notification", {
      admin_email: sessionStorage.getItem("email"),
    }, {
      
      headers: {
        
        "x-auth-header": res.data.token
      },
  }).then((response) => {
    setnotification(response.data);
    setdone(false);
    let filtered = response.data.filter((admin) => {
      return admin.read_status == "0";
    });
    // console.log(filtered.length);
    sessionStorage.setItem("notifylength", filtered.length)
    setdone(false);
  }).catch(err => console.log(err))
  }).catch(err => console.log(err))
    // console.log(response.data);
   
  };
const [leng, setleng] = useState(false);
  const handleClick = async (id) => {
    const response = await axios.post("/admin-notification-read-status", {
      message_id: id,
      read_status: "1",
    });
    setleng(true)
        GetNotifications();
    // window.location.reload();
    // alert("read status");
    // console.log(response.data);
    // setdone(false);
    // setTimeout(() => {

    //   return false;
    // }, 500);
  };

  useEffect(() => {
    setdone(true);

    GetNotifications();
  }, [reload]);

  const DeleteNotification = (id) => {
    // console.log(id);
    setdone(true);

    // setConfirmDialog({
    //   ...confirmDialog,
    //   isOpen: false,
    // });
    axios
      .post("/delete-single-notification", {
        message_id: id,
      })
      .then((response) => {
        setdone(false);
        console.log(response.status);
        if (response.status === 200) {
          setdone(false);
          setNotify({
            isOpen: true,
            message: `${response.data.message}`,
            type: "success",
          });
        }
        // window.location.reload();

        GetNotifications();
        // setTimeout(() => {
        //   window.location.reload();
        // }, 1000);
      })
      .catch((err) => {
        setdone(false);
        console.log(err.status);
        setNotify({
          isOpen: true,
          message: `${err.response}`,
          type: "error",
        });
        // console.log(err);
      });
  };
  const [delid, setdelid] = useState("");

  return (
    <>
      <div className={classes.notificationfire}>
        <div>
        <Typography
          sx={{
            mb: 1.5,
            fontSize: "15px",
            paddingLeft: "15px",
            paddingTop: "10px",
          }}
          color="#4F6367"
        >
          <span className="text-danger">Notifications</span>
        </Typography>
        {done ? (
          <CircularIndeterminate className={classes.loader} />
        ) : notification.length == 0 ? (
          <div className="">
            <small style={{ color: "black", marginLeft: 12 }}>
              No New Notifications
            </small>
          </div>
        ) : (
          <>
          <div>
            {
          notification.slice(0, 10).sort((a, b) => a.read_status - b.read_status )
            // .filter((admin) => {
            //   return admin.read_status == "0";
            // })
            .map((item) => (
              <>
                <div className={ delid == item.message_id ? "d-none"  : "d-flex flex-row forBorder" }>
                  <div
                    className={
                      item.read_status == 1 || leng
                        ? classes.notificationtext
                        : classes.notificationread
                    }
                  >
                    <div
                      className="d-flex justify-content-around flex-row "
                      onClick={() => {
                        handleClick(item.message_id)
                        setdelid(item.message_id)
                      }
                      }
                    >
                      <Typography sx={{ mt: 1 }} color="text.secondary">
                        <img src={Avatar1} alt="" />
                      </Typography>
                      <small className="text-left ps-3 pe-1">
                        {" "}
                        {item.content.body}
                      </small>
                    </div>
                    {/* <div className="App">
                    {/* <Dropdown className="">
                      <Dropdown.Toggle as={CustomToggle} />
                      <Dropdown.Menu className="">
                        <Dropdown.Item className="bg-white">
                          {" "}
                          <span
                            onClick={() => handleClick(item.message_id)}
                            className="abc"
                          >
                            <small>Read</small>
                          </span>
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="bg-white"
                          onClick={() => {
                            DeleteNotification(item.message_id);
                          }}
                        >
                          <span className="abc">
                            <small> Delete</small>
                          </span>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown> */}
                    {/* </div> */}
                  </div>
                </div>
              </>
            ))
                  }
            </div>
        
        <div>
        </div>
          {notification.length == 0  ? (
            <div className="d-flex justify-content-end allnot">
              <small
                style={{ color: "red", marginLeft: 12 }}
                onClick={() => navigate("/AllNotifications")}
                className="pntr"
              >
                See All Notifications
              </small>
            </div>
        ) : (
          <div className="d-flex justify-content-end mt-3 mb-3">
          <small
            style={{ color: "red", marginLeft: 12 }}
            onClick={() => navigate("/AllNotifications")}
            className="pntr"
          >
            See All Notifications
          </small>
        </div>
          )} 
          </>
          )}
        </div>

        <Notification notify={notify} setNotify={setNotify} />
      </div>
    </>
  );
}
