import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  // logo: {
  //   width: 80.62,
  //   height: 60.68,
  //   position: "absolute",
  //   left: "35%",
  //   right: "0.58%",
  //   top: "25px",
  //   // bottom: "25px",
  //   // marginBottom: "2%",
  // },
  btn: {
    position: "relative",
    top: 40,
    left: "15%",
    width: "70%",
    marginBottom: "20%",
  },

  btnlog: {
    position: "absolute",
    top: 70,
    bottom: 40,
    right: "3rem",
    width: "50%",
    color: "error",
  },
  btnlogout: {
    position: "absolute",
    top: "4rem",
    right: "3rem",
  },
  txtactive: {
    backgroundColor: "red",

    borderRadius: 5,
    marginBottom: "4%",
    "&:active": {
      backgroundColor: "red",
    },
  },
  txtnactive: {
    borderRadius: 5,
    marginBottom: "4%",
  },
  button: {
    backgroundColor: "green",
  },
  rightbox: {
    width: "100%",
    flex: 0.2,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  text: {
    width: "180px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    paddingRight: "10px",
  },
  uperrelative: {
    position: "relative",
  },
  rightboxp: {
    width: "200px",
    height: "120px",
    position: "absolute",
    top: "60px",
    right: "10px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    backgroundColor: "white",
    boxShadow: "1px 1px 1px 1px #888888",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "25px",
    paddingRight: "25",
  },
  xdiv: {
    verticalAlign: "middle",
    width: 50,
    height: 50,
    marginBottom: "10%",
    marginLeft: "10%",
    marginTop: "5px",
  },
  mdiv: {
    width: "auto",
    flex: 0.5,
    borderRightWidth: 2,
    borderRightColor: "#4F6367",
    borderRightStyle: "solid",
    position: "relative",
    top: 1,
    right: 60,
    bottom: 8,
    paddingTop: 12,
    paddingRight: 25,
    height: 40,
  },
  notify: {
    display: "none",
  },
  xnotify: {
    position: "absolute",
    top: "1.5rem",
    right: "22rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    backgroundColor: "white",
    boxShadow: "1px 1px 1px 1px #888888",
    overflow: "auto",
  },
  notificationfire: {
    display: "flex",
    flexDirection: "column",
    height: "30rem",
    width: "20rem",
    overflow: "auto",
    backgroundColor: "#FAFAFA",
    position: "relative",
  },
  notificationfireall: {
    display: "flex",
    flexDirection: "column",
    height: "35rem",
    width: "25rem",
    overflow: "auto",
    // backgroundColor: "#FAFAFA",
    position: "relative",
  },
  notificationfireallxx: {
    display: "flex",
    flexDirection: "column",
    height: "35rem",
    width: "35rem",
    overflow: "auto",
    // backgroundColor: "#FAFAFA",
    position: "relative",
  },
  notificationtext: {
    display: "flex",
    flexDirection: "row",
    // marginTop: "15px",
    borderBottom: "0.3px",
    borderBottomColor: "#CBCBCB",
    borderBottomStyle: "solid",
    backgroundColor: "#fff",
    paddingTop: "10px",
    paddingRight: "10px",
    paddingLeft: "10px",
    paddingBottom: "10px",
    "&:hover": {
      backgroundColor: "#F6F6F4",
    },
    cursor: "pointer",
  },
  notificationread: {
    display: "flex",
    flexDirection: "row",
    marginTop: "5px",
    borderBottom: "0.3px",
    borderBottomColor: "#CBCBCB",
    borderBottomStyle: "solid",
    // backgroundColor: "#F2F6F7",
    backgroundColor: "#F6F6F4",
    cursor: "pointer",
    paddingTop: "10px",
    paddingRight: "10px",
    paddingBottom: "10px",
    paddingLeft: "10px",
    "&:hover": {
      backgroundColor: "#F2F6F7",
    },
  },
  // notificationread:hover
  loader: {
    position: "absolute",
    top: "50%",
    left: "40%",
  },
  "@media screen and (min-width: 901px)": {
    hamburger_menu: {
      display: "none",
    },
    btnlogout: {
      display: "none",
    },
  },
  "@media screen and (max-width: 900px)": {
    xnotify: {
      position: "absolute",
      top: "4rem",
      right: "4rem",
    },
  },
  "@media screen and (max-width: 390px)": {
    xnotify: {
      right: "1rem",
    },
  },
  "@media screen and (min-width: 1520px)": {
    xnotify: {
      top: "4rem",
    },
  },
}));

export default useStyles;
