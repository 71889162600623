import React, { useState, useEffect, useContext } from "react";

import { UserContext } from "../../App";


      
      import axios from "axios";
import { Form, Col, Row } from "react-bootstrap";
import ResponsiveDrawer from "../Drawer/drawer";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Notification from "../AlertNotification/Message";
import usePasswordToggle from "../PasswordToggle/usePasswordToggle";
import usePasswordToggleX from "../PasswordToggle/usePasswordToggleX";

import CircularIndeterminate from "../Loader/Loader";

const drawerWidth = 240;

const Input = styled("input")({
  display: "none",
});
const AddNewReps = () => {
  useEffect(() => {
    sessionStorage.setItem("id", "2");
  }, []);

  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState({
    file: [],
    filepreview: null,
  });
  const [upload, setupload] = useState(false);
  const [size, setsize] = useState(false);
  ////////
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [shopname, setShopname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [cell, setCell] = useState("");
  const [description, setDescription] = useState("");
  const [country, setCountry] = useState("");
  const [Conpassword, setconfirmpassword] = useState("");
  const [password, setpassword] = useState("");
  const [done, setdone] = useState(false);
  const [tokenauth, settokenauth] = useState("")

  const [PasswordInputType, ToggleIcon] = usePasswordToggle();
  const [PasswordInputTypex, ToggleIconx] = usePasswordToggleX();
  // ///////
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const HandleChange = (event) => {
    if (event.target.files[0].size > 100 * 1024) {
      setsize(true);
    } else if (event.target.files[0].size <= 100 * 1024) {
      setSelectedFile({
        ...selectedFile,
        file: event.target.files[0],
        filepreview: URL.createObjectURL(event.target.files[0]),
      });
      setsize(false);
      setupload(true);
    }
  };
  // API calling
  const AddAgent = () => {
    console.log(tokenauth)
    const formData = new FormData();
    formData.append("firstname", fname);
    formData.append("lastname", lname);
    formData.append("email", email);
    formData.append("password", password);
    formData.append("Conpassword", Conpassword);
    formData.append("phone", phone);
    formData.append("cell", cell);
    formData.append("shopname", shopname);
    formData.append("description", description);
    formData.append("country", country);
    formData.append("file", selectedFile.file);
    axios
      .post("/add-employee", formData, {
      
        headers: {
          
          "x-auth-header": tokenauth
        },
    })
      .then((response) => {
        console.log(response.status);
        if (response.status === 200) {
          setdone(false);
          setNotify({
            isOpen: true,
            message: "Added Succcessfully",
            type: "success",
          });
        }
        setTimeout(() => {
          navigate("/AllReps");
        }, 1000);
      })
      .catch((err) => {
        setdone(false);
        console.log(err.response);
        setNotify({
          isOpen: true,
          message: `Added Fail`,
          type: "error",
        });
        // console.log(err);
      });
  };
  const HandleSubmit = (e) => {
    e.preventDefault();
    setdone(true);
    AddAgent();
  };
  useEffect(() => {
    sessionStorage.getItem("payloadout") != "true"
      ? navigate("/")
      : console.log("Logged In")
  }, [])
  useEffect(() => {
   axios.post("get-token", {
    id: sessionStorage.getItem("token_id")
   }).then((response) => {
    settokenauth(response.data.token) 
   }).catch(err => console.log(err))
  }, [])
  
  
  return (
    <div>
      <ResponsiveDrawer heading="Selling Reps Management" />
      <Box
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
        component="main"
      >
        <Toolbar />
        {done ? (
          <CircularIndeterminate className="AddAgentloader" />
        ) : (
          <div className="for_form my-5 mx-4">
            <Form onSubmit={HandleSubmit} novalidate>
              <div className=" d-flex  justify-content-between mt-5  pt-3 pr-3 pb-3 btnz">
                <h5 className="">
                  Add&nbsp;New&nbsp;Selling&nbsp;Representative
                </h5>
                <div className="btnp">
                  <button
                    className="btn1 mx-2"
                    onClick={() => navigate("/allagents")}
                  >
                    Cancel
                  </button>
                  <button className="btn2" type="submit">
                    Add
                  </button>
                </div>
              </div>
              {/* Form */}
              <Row xs="1" sm="1" md="2" lg="3" xl="3" className="mt-3">
                <Col>
                  <label htmlFor="icon-button-file">
                    <Input
                      accept="image/*"
                      id="icon-button-file"
                      type="file"
                      // value={selectedFile}
                      onChange={HandleChange}
                      // required
                    />

                    {!upload ? (
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                      >
                        <div className="addicon">
                          <AddIcon className="icon" />
                        </div>
                      </IconButton>
                    ) : (
                      <img
                        className="previewimg mb-3"
                        src={selectedFile.filepreview}
                        alt=""
                      />
                    )}
                    <br />
                  </label>
                  <div className={size ? "sizeshow" : "sizehide"}>
                    <small>File size excedded than 100KB</small>
                  </div>
                </Col>
              </Row>
              <Row xs={1} sm={1} md={2} lg={3} xl={3}>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="First Name"
                      required
                      name="firstname"
                      onChange={(e) => setFname(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Last Name"
                      name="lastname"
                      required
                      onChange={(e) => setLname(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      placeholder="Enter Email"
                      type="email"
                      required
                      name="email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row xs={1} sm={1} md={2} lg={3} xl={3}>
                <Col>
                  <Form.Group className="mb-3" con>
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type={PasswordInputType}
                      placeholder="Password"
                      name="password"
                      // required
                      onChange={(e) => setpassword(e.target.value)}
                    />
                    <span className="password-toogle-icon">{ToggleIcon}</span>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" con>
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                      type={PasswordInputTypex}
                      placeholder="Confirm Password"
                      name="Conpassword"
                      // required
                      onChange={(e) => setconfirmpassword(e.target.value)}
                    />
                    <span className="password-toogle-icon">{ToggleIconx}</span>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Enter Phone"
                      name="phone"
                      // required
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row xs={1} sm={2} md={2} lg={3} xl={3}>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Cell#</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Cell#"
                      name="cell"
                      // required
                      onChange={(e) => setCell(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                {/* <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Store Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Store name"
                      name="shopname"
                      // required
                      onChange={(e) => setShopname(e.target.value)}
                    />
                  </Form.Group>
                </Col> */}
              </Row>
              {/* <Row>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Descrition</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      name="description"
                      // required
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row> */}
            </Form>
          </div>
        )}
      </Box>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};

export default AddNewReps;
