import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

export default function CircularIndeterminate({ className }) {
  return (
    <Box className="boxloader">
      <CircularProgress size={40} className={className} color="error" />
    </Box>
  );
}
