import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Notification from "../AlertNotification/Message";
import CircularIndeterminate from "../Loader/Loader";
const ResetPassword = () => {
  const [values, setValues] = useState({});
  const [done, setdone] = useState(false);
  const navigate = useNavigate();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    PostApi();
    setdone(true);
  };
  const handleChange = (e) => {
    e.persist();
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const PostApi = () => {
    axios
      .post("/send-verification", {
        email: values.email,
      })
      .then((response) => {
        console.log(response.status);
        if (response.status === 200) {
          setdone(false);
          setNotify({
            isOpen: true,
            message: "Send Succcessfully",
            type: "success",
          });
        }
        OTPverification(values.email);
        console.log(response.status);
      })
      .catch((err) => {
        setdone(false);
        console.log(err.status);
        setNotify({
          isOpen: true,
          message: `Authentication Failed - ${err.response.data.message}`,
          type: "error",
        });
        console.log(err.response);
      });
  };
  const OTPverification = (email) => {
    navigate(`/OTPverification/${email}`);
  };
  return (
    <div className="Main__section text-center">
      <div className="forBg__image">
        <div className="for__inputs">
          <div className="Reset mt-3 fw-bolder">
            <h3 className="icon22">Reset&nbsp;your&nbsp;Password</h3>
            <small className="">Please enter your registered email</small>
          </div>
          <form onSubmit={handleSubmit}>
            <Form.Control
              className="for__input my-4 py-2"
              placeholder=" Enter Email"
              type="email"
              name="email"
              value={values.email}
              onChange={handleChange}
              required
            />
            {done ? (
              <CircularIndeterminate className="verifyloader" />
            ) : (
              <button type="submit" className="Login__button2 mb-2 py-2">
                Submit
              </button>
            )}
            <div className="text-right">
              <small>Remember Password? </small>
              <a href="/">
                <p className="for__link">Login</p>
              </a>
            </div>
          </form>
        </div>
        <Notification notify={notify} setNotify={setNotify} />
      </div>
    </div>
  );
};

export default ResetPassword;
