import React, { useEffect, useState, createContext, useReducer } from "react";
// import {
//   HashRouter,
//   BrowserRouter as Router,
//   Routes,
//   Route,
//   useNavigate,
// } from "react-router-dom";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Loginadmin from "./Components/RegisterSection/Login";
import Login from "./Components/RegisterSection/Splashscreen";
import "./Components/Fontawsomeicons/index";
import ResetPassword from "./Components/RegisterSection/ResetPassword";
import OTPverification from "./Components/RegisterSection/OTPverification";
import FormSignup from "./Components/secondPassvalid/CreateNewPassword";
import Dashboard from "./Components/Dashboard/Dashboard";
import UpdateCustomer from "./Components/CustomerManagement/UpdateCustomer";
import AllCustomer from "./Components/CustomerManagement/AllCustomers";
import AddCustomer from "./Components/CustomerManagement/AddCustomer";
import AllProducts from "./Components/Products/AllProducts";
import ProductDetails from "./Components/Products/ProductDetails";
import OrderHistory from "./Components/Orders/OrderHistory";
import Settings from "./Components/Setting/setting";
import AllAdmin from "./Components/Admin/AllAdmin";
import AddAdmin from "./Components/Admin/AddAdmin";
import UpdateAdmin from "./Components/Admin/updateAdmin";
import ViewProduct from "./Components/Products/ViewProduct";
import { onMessageListener } from "./firebaseInit";
import Notifications from "./Components/Notifications/Notifications";
import Toastnotify from "./Components/Notifications/toast";
import CustomerGrid from "./Components/CustomerManagement/CustomerGrid";
import Testing from "./Components/Admin/testing";
import TotalSales from "./Components/Report_Management/TotalSales";
import SalesByStore from "./Components/Report_Management/SalesByStore";
import AllStyleSold from "./Components/Report_Management/AllStyleSold";
import CustomerOrderStatus from "./Components/Report_Management/StoreOrderStatus";
import SortTesting from "./Components/Testing/SortTesting";
import SortAllStyleSold from "./Components/Report_Management/SortAllStyleSold";
import TestDatePicker from "./Components/Report_Management/TestDatePicker";
// import InformationOrder from "./Components/Orders/InformationOrder";
import AllFirebaseNotificationz from "./Components/Drawer/AllNotifications";
import ActiveCustomerOrder from "./Components/Report_Management/ShowCustomerStatus/ActiveCustomerOrder";
import InformationOrder from "./Components/Orders/InformationOrder";
import { initialState, reducer } from "../src/reducer/useReducer";

import Test from "./Components/CustomerManagement/test";
import ResponsiveDrawer from "./Components/Drawer/drawer";
import FactoryStyleByStoreName from "./Components/Report_Management/FactoryStyleByStoreName";
import PurchaseOrder from "./Components/Orders/PurchaseOrder";
import AllReps from "./Components/AgentsManagement/AllReps";
import AddNewReps from "./Components/AgentsManagement/AddReps";
import UpdateReps from "./Components/AgentsManagement/UpdateReps";
import RepsDetail from "./Components/AgentsManagement/RepsDetail";
import PendingOrders from "./Components/Orders/PendingOrders";
import CancelledOrders from "./Components/Orders/CanceledOrders";
import CompletedOrders from "./Components/Orders/CompleteOrders";
import PairByStyle from "./Components/Report_Management/TotalPairsbyStyle";
import SalesByStyleFactory from "./Components/Report_Management/SalesByStyleFactory";
// import AllFirebaseNotificationz from "./Components/Drawer/AllNotifications";
// KotU6O#fk-FJu0

const Routing = () => {
  return (
    <Router>
      <div className="App">
        {/* <ResponsiveDrawer  /> */}

        <Routes basename="/index.html">
          {/* ============Registration_Section========== */}
          {/* <Route exact path="/" element={<Login />} /> */}
          <Route path="/" element={<Loginadmin />} />
          <Route path="/ResetPassword" element={<ResetPassword />} />
          <Route path="/OTPverification/:email" element={<OTPverification />} />
          <Route path="/CreateNewPassword/:email" element={<FormSignup />} />
          {/* ===============Dashboard Section==================== */}
          <Route path="/Dashboard" element={<Dashboard />} />
          {/* ==========AGENTS SECTION ==================== */}
          <Route path="/AllReps" element={<AllReps />} />
          <Route path="/RepsDetail/:mail" element={<RepsDetail />} />
          <Route path="/AddNewReps" element={<AddNewReps />} />
          <Route path="/UpdateReps/:mail" element={<UpdateReps />} />
          {/* ============Products======================= */}
          <Route path="/AllProducts" element={<AllProducts />} />
          <Route path="/ProductDetails/:_id" element={<ProductDetails />} />
          <Route path="/ViewProduct/:_id" element={<ViewProduct />} />
          {/* ============Customer_management======================= */}
          <Route path="/UpdateCustomer/:mail" element={<UpdateCustomer />} />
          <Route path="/AllCustomers" element={<AllCustomer />} />
          <Route path="/AddCustomer" element={<AddCustomer />} />
          <Route path="/CustomerGrid" element={<CustomerGrid />} />
          {/* ============Orderr_management======================= */}
          <Route path="/OrderHistory" element={<OrderHistory />} />
          <Route path="/PendingOrders" element={<PendingOrders />} />
          <Route path="/CancelledOrders" element={<CancelledOrders />} />
          <Route path="/CompleteOrders" element={<CompletedOrders />} />
          <Route
            path="/PurchaseOrder/:order_number"
            element={<PurchaseOrder />}
          />
          <Route
            path="/InformationOrder/:order_number"
            element={<InformationOrder />}
          />
          {/* <Route path="/PDFVIEWER" element={<PDFVIEWER />} /> */}
          {/* ============Admin_management======================= */}
          <Route path="/AllAdmin" element={<AllAdmin />} />
          <Route path="/AddAdmin" element={<AddAdmin />} />
          <Route path="/updateAdmin/:mail" element={<UpdateAdmin />} />
          {/* ============Reports_management======================= */}
          <Route path="/TotalSales" element={<TotalSales />} />
          <Route path="/SalesByStore" element={<SalesByStore />} />
          <Route path="/AllStyleSold" element={<AllStyleSold />} />
          <Route path="/SalesByStyleFactory" element={<SalesByStyleFactory />} />
          <Route path="/test" element={<Test />} />
          <Route
            path="/AllNotifications"
            element={<AllFirebaseNotificationz />}
          />
          <Route
            path="/FactoryStyleByStoreName"
            element={<FactoryStyleByStoreName />}
          />
          <Route path="/StoreOrderStatus" element={<CustomerOrderStatus />} />
          {/* ============Setting======================= */}
          <Route path="/setting" element={<Settings />} />
          <Route path="/testing/:mail" element={<Testing />} />
          <Route path="/SortTesting" element={<SortTesting />} />
          <Route path="/SortAllStyleSold" element={<SortAllStyleSold />} />
          <Route path="/TestDatePicker" element={<TestDatePicker />} />
          <Route path="/TotalPairsByStyle" element={<PairByStyle />} />
        </Routes>
      </div>
    </Router>
  );
};

export const UserContext = createContext();
function App() {
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });
  //
  // console.log(show, notification);
  /////

  onMessageListener()
    .then((payload) => {
      // setShow(true);
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });
      setShow(true);
      // console.log(payload);
    })
    .catch((err) => console.log("failed: ", err));
  const [state, dispatch] = useReducer(reducer, initialState);
  //
  return (
    <>
      <div className="App">
        {/* {console.log(notification.title)}
        {console.log(notification.body)} */}
        {/* {alert(notification.title, notification.body )} */}
        {show ? (
          <Toastnotify title={notification.title} body={notification.body} />
        ) : null}
        <Notifications />
      </div>
      <UserContext.Provider value={{ state, dispatch }}>
        <Routing />
      </UserContext.Provider>
    </>
  );
}

export default App;
