import React, { useState, useEffect, useContext } from "react";

import { UserContext } from "../../App";

import ResponsiveDrawer from "../Drawer/drawer";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { BiSearch } from "react-icons/bi";
import { Form } from "react-bootstrap";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import axios from "axios";

import Dropdown from "react-bootstrap/Dropdown";

import Notification from "../AlertNotification/Message";

import ConfirmDialog from "../AlertNotification/confirmdialog";

import CircularIndeterminate from "../Loader/Loader";

////////////

const drawerWidth = 240;

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <span className="threedots" />
  </a>
));
const columns = [
  {
    id: "img",
    label: "Avatar",
    minWidth: 100,
    align: "left",
  },
  {
    id: "density",
    label: "Name",
    minWidth: 100,
    align: "left",
    format: (value) => value.toFixed(2),
  },
  {
    id: "name",
    label: "Email",
    minWidth: 130,
    align: "left",
  },

  {
    id: "population",
    label: "Phone",
    minWidth: 140,
    align: "left",
  },

  {
    id: "action",
    label: "Action",
    minWidth: 100,
    align: "left",
    format: (value) => value.toFixed(2),
  },
];

function AllAdmin() {
  const navigate = useNavigate();
  const [filter, setfilter] = useState("");
  const [customers, setcustomers] = useState([]);
  const [done, setdone] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const HandleClick = () => {
    navigate("/AddCustomer");
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [tokenauth, settokenauth] = useState("");

  const GetCustomers = () => {
    axios
      .post("/get-token", {
        id: sessionStorage.getItem("token_id"),
      })
      .then((res) => {
        settokenauth(res.data.token)
        axios
          .post(
            `/get-admins`,
            {},
            {
              headers: {
                "x-auth-header": res.data.token,
              },
            }
          )
          .then((response) => {
            setcustomers(response.data);
            setdone(true);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    sessionStorage.getItem("payloadout") != "true"
      ? navigate("/")
      : GetCustomers();
    sessionStorage.setItem("id", "6");
  }, []);

  const handleDelete = (mail) => {
    setdone(true);

    // console.log(mail);
    // setConfirmDialog({
    //   ...confirmDialog,
    //   isOpen: false,
    // });
    axios
      .post(
        "/delete-admin",
        {
          email: mail,
        },
        {
          headers: {
            "x-auth-header": tokenauth && tokenauth,
          },
        }
      )
      .then((response) => {
        setdone(false);
        console.log(response.status);
        if (response.status === 200) {
          setdone(false);
          setNotify({
            isOpen: true,
            message: `${response.data.message}`,
            type: "success",
          });
        }
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        setdone(false);
        console.log(err.status);
        setNotify({
          isOpen: true,
          message: `${err.response}`,
          type: "error",
        });
        console.log(err);
      });
  };

  return (
    <div className="tableX">
      <ResponsiveDrawer heading="Admin Management" />
      <Box
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
        component="main"
      >
        <Toolbar />
        {!done ? (
          <CircularIndeterminate className="allagentsLoader" />
        ) : (
          <div className="position-relative">
            <div className=" d-flex justify-content-between mt-3 p-4">
              <h4 className="">All&nbsp;Admins</h4>
              <div className="d-flex">
                <button
                  onClick={() => navigate("/AddAdmin")}
                  className="btn2 px-3"
                >
                  Add&nbsp;Admin
                </button>
              </div>
            </div>
            <div className="d-flex justify-content-between mx-2">
              <small className="ms-4 fw-bolder mt-1">Search&nbsp;By</small>
              <div className="position-relative w-75 me-3">
                <Form>
                  <Form.Control
                    placeholder="Name, Email or Phone"
                    type="search"
                    className="input_field"
                    onChange={(e) => setfilter(e.target.value)}
                  />
                  <BiSearch className="search_Agent" />
                </Form>
              </div>
            </div>

            <div className="for__table m-4">
              <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer sx={{ maxHeight: 730 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {customers
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .filter((admin) => {
                          if (filter === "") {
                            return customers;
                          } else if (
                            (admin.firstname &&
                              admin.firstname
                                .toString()
                                .toLowerCase()
                                .includes(filter.toString().toLowerCase())) ||
                            (admin.lastname &&
                              admin.lastname
                                .toString()
                                .toLowerCase()
                                .includes(filter.toString().toLowerCase())) ||
                            (admin.email &&
                              admin.email
                                .toString()
                                .toLowerCase()
                                .includes(filter.toString())) ||
                            (admin.phone &&
                              admin.phone
                                .toString()
                                .includes(filter.toString()))
                            // ||
                            // (admin.shopname &&
                            //   admin.shopname
                            //     .toLowerCase()
                            //     .toString()
                            //     .includes(filter.toLowerCase().toString()))
                          ) {
                            return customers;
                          }
                        })
                        .map((admin) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={admin._id}
                            >
                              <TableCell>
                                <img
                                  src={admin.image}
                                  className="newimages"
                                  alt=""
                                />
                              </TableCell>
                              <TableCell>
                                {admin.firstname}&nbsp;{admin.lastname}
                              </TableCell>
                              <TableCell>{admin.email}</TableCell>
                              <TableCell>{admin.phone}</TableCell>
                              {/* <TableCell>{customer.phonenumber}</TableCell> */}
                              <TableCell>
                                <div className="App">
                                  <Dropdown className="">
                                    <Dropdown.Toggle as={CustomToggle} />
                                    <Dropdown.Menu className="">
                                      <Dropdown.Item className="bg-white">
                                        {" "}
                                        <span
                                          onClick={() => {
                                            navigate(
                                              `/updateAdmin/${admin.email}`
                                            );
                                          }}
                                          className="abc"
                                        >
                                          Edit
                                        </span>
                                      </Dropdown.Item>
                                      <Dropdown.Item className="bg-white">
                                        <span
                                          className="abc"
                                          onClick={() => {
                                            // setConfirmDialog({
                                            //   isOpen: true,
                                            //   title:
                                            //     "Are you sure to delete this record?",
                                            //   subTitle:
                                            //     "You can't undo this operation",
                                            //   onConfirm: () => {
                                            handleDelete(admin.email);
                                            //   },
                                            // });
                                          }}
                                        >
                                          Delete
                                        </span>
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  className="mx-5 mt-3 px-5"
                  rowsPerPageOptions={[10, 20, 35]}
                  component="div"
                  count={customers.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </div>
          </div>
        )}
      </Box>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </div>
  );
}

export default AllAdmin;
