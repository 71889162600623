import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Notification from "../AlertNotification/Message";
import usePasswordToggle from "../PasswordToggle/usePasswordToggle";
import CircularIndeterminate from "../Loader/Loader";
import "../Notifications/Notifications";
import React, { useState, useContext, useEffect } from "react";

import { UserContext } from "../../App";

//
//
axios.defaults.withCredentials = true;
window.jwt = "";
const Auth = "hello";
function Loginadmin() {
  const navigate = useNavigate();
  const [auth, setauth] = useState(false);
  const [values, setValues] = useState({});
  // Loader & EYe icon
  const [PasswordInputType, ToggleIcon] = usePasswordToggle();
  // Notification Message
  const [done, setdone] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const { state, dispatch } = useContext(UserContext);
  const handleSubmit = (e) => {
    e.preventDefault();
    PostApi();
    setdone(true);
  };
  // Handle reset
  const handleChange = (e) => {
    e.persist();
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  // API calling using axios
  function getCookie(cName) {
    const name = cName + "=";
    const cDecoded = decodeURIComponent(document.cookie); //to be careful
    const cArr = cDecoded.split("; ");
    let res;
    cArr.forEach((val) => {
      if (val.indexOf(name) === 0) res = val.substring(name.length);
    });
    return res;
  }
  // console.log(global.data);

  let token = getCookie("auth");
  // console.log(global.data);
  const PostApi = () => {
    axios
      .post("/api/login", {
        email: values.email,
        password: values.password,
        fcmtoken: global.data,
        Authorization: token,
      })
      .then((response) => {
        global.jwt = response.data.jwt;
        // console.log(global.jwt);
        sessionStorage.setItem("token_id", response.data.id)
        sessionStorage.setItem("tokan" , response.headers["x-auth-header"])
        axios.post("/post-token", {
          id: response.data.id,
          token: response.headers["x-auth-header"]
        }).then((res) => {
          console.log(`token response = ${res.status}`)
        }).catch((err) => {
          console.log(err.response)
        })
        if (response.status === 200) {
          dispatch({ type: "USER", payload: true });
          sessionStorage.setItem("payloadout", "true");
          sessionStorage.setItem("email", values.email);
          setdone(false);
          setauth(true);
          setNotify({
            isOpen: true,
            message: "Login Successfull",
            type: "success",
          });
        }
        setTimeout(() => {
          navigate("/Dashboard");
        }, 1000);
      })
      .catch((err) => {
        setdone(false);
        console.log(err.response);
        setNotify({
          isOpen: true,
          message: `${err.response.data.message}`,
          type: "error",
        });
        // console.log(err);
      });
  };

  return (
    <div className="Main__section text-center">
      <div className="forBg__image">
        <div className="for__inputs">
          <div className="mb-3">
            <h4 className="icon22">Login</h4>
          </div>
          <form onSubmit={handleSubmit}>
            <Form.Control
              className="for__input mb-4 py-2"
              placeholder="Email"
              type="email"
              name="email"
              value={values.email}
              onChange={handleChange}
              required
            />
            <Form.Control
              className="input py-2"
              placeholder="Password"
              name="password"
              value={values.password}
              type={PasswordInputType}
              onChange={handleChange}
              required
            />
            <span className="password-toogle-icon">{ToggleIcon}</span>
            <div className="">
              <small
                className="for__link my-3 d-flex justify-content-start "
                onClick={() => navigate("/ResetPassword")}
              >
                Forget Password?
              </small>
            </div>
            {done ? (
              <div className="loginloader">
                <CircularIndeterminate />{" "}
              </div>
            ) : (
              <button type="submit" className="Login__button2 py-2">
                Login
              </button>
            )}
          </form>
        </div>
        <Notification notify={notify} setNotify={setNotify} />
      </div>
      {/* {console.log(auth)} */}
      <div className="d-none"></div>
    </div>
  );
}

export default Loginadmin;
export { Auth };
