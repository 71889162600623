import React, { useState, useEffect, useContext } from "react";

import { UserContext } from "../../App";

import ResponsiveDrawer from "../Drawer/drawer";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import XLSX from "xlsx";

import axios from "axios";

import Dropdown from "react-bootstrap/Dropdown";

import Notification from "../AlertNotification/Message";

import ConfirmDialog from "../AlertNotification/confirmdialog";
import { BiSearch } from "react-icons/bi";

import CircularIndeterminate from "../Loader/Loader";

import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CanceledOrders from "./CanceledOrders";
import CompleteOrders from "./CompleteOrders";
/////

///

import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
/////

const drawerWidth = 240;

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <span className="threedots" />
  </a>
));

const columns = [
  { id: "code", label: "Store Name", minWidth: 100, align: "left" },

  {
    label: "PO#",
    minWidth: 50,
    align: "left",
    icon: <ArrowDropUpIcon className="" />,
  },
  {
    id: "pairs",
    label: "Total\u00a0Pairs",
    minWidth: 50,
    align: "left",
  },
  {
    id: "storename",
    label: "Total\u00a0$",
    minWidth: 50,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "density",
    label: "Order\u00a0Date",
    minWidth: 100,
    align: "left",
    format: (value) => value.toFixed(2),
  },
  {
    id: "population",
    label: "Ship\u00a0Date",
    minWidth: 100,
    align: "left",

    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "population",
    label: "Cancel\u00a0Date",
    minWidth: 100,
    align: "left",

    format: (value) => value.toLocaleString("en-US"),
  },
  // {
  //   id: "name",
  //   label: "SKU",
  //   minWidth: 50,
  //   align: "left",
  // },

  // {
  //   id: "population",
  //   label: "Start\u00a0Date",
  //   minWidth: 50,
  //   align: "left",

  //   format: (value) => value.toLocaleString("en-US"),
  // },

  {
    id: "status",
    label: "Status",
    minWidth: 50,
    align: "left",
    format: (value) => value.toFixed(2),
  },

  {
    id: "action",
    label: "Action",
    minWidth: 10,
    align: "left",
    format: (value) => value.toFixed(2),
  },
];
function PendingOrders() {
  const [show, setShow] = useState(true);
  const SortBy = () => {
    setShow(!show);
  };
  //============////////////===============
  //============////////////===============
  //============////////////===============
  // -------//
  const PO = () => {
    orders.sort((a, b) => a.orderNumber - b.orderNumber);
    setShow(true);
    handleClose();
    // customers.reverse()
  };
  // -------//

  // ------- UPTODOWN //
  const PODown = () => {
    orders.sort((a, b) => b.orderNumber - a.orderNumber);
    setShow(true);
    handleClose();
    // customers.reverse()
  };
  // -------//
  const Sku = () => {
    // .sort((a, b) => a.name.localeCompare(b.name))
    orders.sort((c, d) => c.sku.localeCompare(d.sku));
    setShow(true);
    handleClose();
  };
  // -------//
  // -------//
  const Start = () => {
    orders.sort(function (a, b) {
      let arr1 = b.orderDate.split("-");
      let arr2 = a.orderDate.split("-");
      let date1 = arr1[1] + "-" + arr1[0] + "-" + arr1[2];
      let date2 = arr2[1] + "-" + arr2[0] + "-" + arr2[2];
      return date1 < date2 ? -1 : date1 > date2 ? 1 : 0;
    });
    setShow(true);
    handleClose();
  };
  // -------//
  // -------//
  const StartDown = () => {
    orders.sort(function (a, b) {
      let arr1 = b.orderDate.split("-");
      let arr2 = a.orderDate.split("-");
      let date1 = arr1[1] + "-" + arr1[0] + "-" + arr1[2];
      let date2 = arr2[1] + "-" + arr2[0] + "-" + arr2[2];
      return date1 < date2 ? 1 : date1 > date2 ? -1 : 0;
    });
    setShow(true);
    handleClose();
  };
  // -------//
  const prace = [];
  const pracefinal = [];
  const filtor = [];
  const Price = () => {
    orders.sort((a, b) => b.totalSales - a.totalSales);
    setShow(true);
    handleClose();
  };

  // .,.,.,.,.
  const PriceUp = () => {
    //////////

    orders.sort((a, b) => a.totalSales - b.totalSales);
    setShow(true);
    handleClose();
  };
  // .,.,.,/..,/////
  const StoreName = () => {
    orders.sort((a, b) =>
      a.storeName.toLowerCase().localeCompare(b.storeName.toLowerCase())
    );
    setShow(true);
    handleClose();

    // customers.sort((a, b) => a.sku.localeCompare(b.sku));
  };
  const StoreNameDown = () => {
    orders.sort((a, b) =>
      b.storeName.toLowerCase().localeCompare(a.storeName.toLowerCase())
    );
    setShow(true);
    handleClose();

    // customers.sort((a, b) => a.sku.localeCompare(b.sku));
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  //
  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));

  let pp = [];
  const navigate = useNavigate();
  const [filter, setfilter] = useState("");
  const [admins, setadmins] = useState([]);
  const [done, setdone] = useState(false);
  const [orderheading, setorderheading] = useState("All\u00a0Orders");
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const HandleClick = () => {
    navigate("/AddAgent");
  };
  let total = "";
  let newlist = [];
  let subtotal = [];
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(1000);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [tokenauth, settokenauth] = useState("");

  const [orders, setorders] = useState([]);
  const getorders = () => {
    axios
      .post("/get-token", {
        id: sessionStorage.getItem("token_id"),
      })
      .then((res) => {
        console.log();
        axios
          .get("/order-history-admin", {
            headers: {
              "x-auth-header": res.data.token,
            },
          })
          .then((resp) => {
            console.log(resp.data);
            setorders(resp.data);
            setdone(false);
          })
          .catch((err) => console.log(err.response));
      })
      .catch((err) => console.log(err.response));
  };

  /////FACTORY READY
  const [factoryready, setfactoryready] = useState([]);
  const GetFactoryReady = async () => {
    const response = await axios.post(
      `/factory-ready-orders`,
      {},
      {
        headers: {
          "x-auth-header": sessionStorage.getItem("tokan"),
        },
      }
    );
    setfactoryready(response.data);
    // console.log(response.data)
    //     response.data.forEach((value) => {
    //       let date = value.date.split('-');
    //       value.date = date[1] + date[0] + date[2];
    //     });

    //     arr.push(response.data);

    //     console.log(arr && arr);
  };
  let dollarUSLocale = Intl.NumberFormat("en-US");

  /////////BULK
  const [bulkorders, setbulkorders] = useState([]);
  const GetBulkOrders = async () => {
    const response = await axios.post(
      `/bulk-ready-orders`,
      {},
      {
        headers: {
          "x-auth-header": sessionStorage.getItem("tokan"),
        },
      }
    );

    setbulkorders(response.data);
  };

  ////
  /////////BULK Factory Ready Prices
  const [factory_ready_prices, setfactory_ready_prices] = useState([]);
  const Factory_Prices = async () => {
    // console.log(sessionStorage.getItem("tokan"))

    const response = await axios.post(
      `/factory-ready-prices`,
      {},
      {
        headers: {
          "x-auth-header": sessionStorage.getItem("tokan"),
        },
      }
    );

    setfactory_ready_prices(response.data);
  };

  ////

  useEffect(() => {
    sessionStorage.getItem("payloadout") != "true"
      ? navigate("/")
      : sessionStorage.setItem("id", "5");
    // GetAdmins();
    GetFactoryReady();
    GetBulkOrders();
    Factory_Prices();
    getorders();
    setdone(true);
  }, []);

  const [allorders, setallorders] = useState(true);
  const [activeorders, setactiveorders] = useState(false);
  const [completeorders, setcompleteorders] = useState(false);
  const [cancelledorders, setcancelledorders] = useState(false);

  const HandleAllOrders = () => {
    setorderheading("All\u00a0Orders");
    navigate("/OrderHistory");
    handleClose();
  };
  const HandleCompleteOrders = () => {
    setorderheading("Completed\u00a0Orders");
    
    setallorders(false);
    setactiveorders(false);
    setcompleteorders(true);
    setcancelledorders(false);
    handleClose();
    navigate("/CompleteOrders");
  };
  const HandleActiveOrders = () => {
    setorderheading("Active\u00a0Orders");

    navigate("/PendingOrders");
    handleClose();
  };
  const HandleCancelOrders = () => {
    setorderheading("Canceled\u00a0Orders");
    navigate("/CancelledOrders");

    handleClose();
  };

  const handleDelete = (orderno) => {
    setdone(true);
    // console.log(orderno);
    // setConfirmDialog({
    //   ...confirmDialog,
    //   isOpen: false,
    // });
    axios
      .post(
        "/delete-order",
        {
          orderNumber: orderno,
        },
        {
          headers: {
            "x-auth-header": tokenauth,
          },
        }
      )
      .then((response) => {
        setdone(false);
        console.log(response.status);
        if (response.status === 200) {
          setdone(false);
          setNotify({
            isOpen: true,
            message: `${response.data.message}`,
            type: "success",
          });
        }
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        setdone(false);
        console.log(err.status);
        setNotify({
          isOpen: true,
          message: `${err.response}`,
          type: "error",
        });
        // console.log(err.response);
      });
  };
  const ExportexcelFactory = () => {
    setdone(true);

    const workSheet = XLSX.utils.json_to_sheet(factoryready);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "order");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    let today = new Date().toLocaleDateString();
    XLSX.writeFile(workBook, `FactoryReadyExport-${today}.xlsx`);
    // setTimeout(() => {
    //   window.location.reload();
    // }, 1000);
    setdone(false);
  };
  const ExportexcelBulk = () => {
    setdone(true);

    const workSheet = XLSX.utils.json_to_sheet(bulkorders);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "order");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    let today = new Date().toLocaleDateString();
    XLSX.writeFile(workBook, `BulkExport-${today}.xlsx`);
    // setTimeout(() => {
    //   window.location.reload();
    // }, 1000);
    setdone(false);
  };

  ///////////////FACTORY READY PRICES
  const FactoryPrices = () => {
    setdone(true);

    const workSheet = XLSX.utils.json_to_sheet(factory_ready_prices);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "order");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    let today = new Date().toLocaleDateString();
    XLSX.writeFile(workBook, `FactoryPricesExport-${today}.xlsx`);
    // setTimeout(() => {
    //   window.location.reload();
    // }, 1000);
    setdone(false);
  };

  const ChangeStatusCancel = (orderno) => {
    setdone(true);

    // console.log(orderno);

    axios
      .post(
        "/change-order-status",
        {
          orderNumber: orderno,
          status: "0",
        },
        {
          headers: {
            "x-auth-header": tokenauth,
          },
        }
      )
      .then((response) => {
        setdone(false);
        console.log(response.status);
        if (response.status === 200) {
          setdone(false);
          setNotify({
            isOpen: true,
            message: `${response.data.message}`,
            type: "success",
          });
        }
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        setdone(false);
        console.log(err.status);
        setNotify({
          isOpen: true,
          message: `${err.response}`,
          type: "error",
        });
        // console.log(err);
      });
  };
  const ChangeStatusComplete = (orderno) => {
    setdone(true);

    // console.log(orderno);

    axios
      .post(
        "/change-order-status",
        {
          orderNumber: orderno,
          status: "2",
        },
        {
          headers: {
            "x-auth-header": tokenauth,
          },
        }
      )
      .then((response) => {
        setdone(false);
        if (response.status === 200) {
          setdone(false);
          setNotify({
            isOpen: true,
            message: `${response.data.message}`,
            type: "success",
          });
        }
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        setdone(false);
        setNotify({
          isOpen: true,
          message: `${err.response}`,
          type: "error",
        });
        // console.log(err);
      });
  };

  const ChangeStatusPending = (orderno) => {
    setdone(true);

    // console.log(orderno);

    axios
      .post(
        "/change-order-status",
        {
          orderNumber: orderno,
          status: "1",
        },
        {
          headers: {
            "x-auth-header": tokenauth,
          },
        }
      )
      .then((response) => {
        setdone(false);
        if (response.status === 200) {
          setdone(false);
          setNotify({
            isOpen: true,
            message: `${response.data.message}`,
            type: "success",
          });
        }
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        setdone(false);
        console.log(err.status);
        setNotify({
          isOpen: true,
          message: `${err.response}`,
          type: "error",
        });
        // console.log(err);
      });
  };
  // const arr = arr.split("-");
  const runCallback = (cb) => {
    return cb();
  };

  ////////

  /////

  /////
  const DeleteAllOrders = () => {
    setdone(true);
    axios
      .post("/delete-all-orders", {
        headers: {
          "x-auth-header": tokenauth,
        },
      })
      .then((response) => {
        console.log(response.status);
        if (response.status === 200) {
          setdone(false);
          setNotify({
            isOpen: true,
            message: "All Orders Deleted Successfully",
            // message: `${response.data.message}`,
            type: "success",
          });
        }
        window.location.reload();
        // setTimeout(() => {
        // }, 1000);
      })
      .catch((err) => {
        setdone(false);
        setNotify({
          isOpen: true,
          message: `${err.response}`,
          type: "error",
        });
      });
  };
  ///
     const commafunc = (amount) => {
       const optionsa = { style: "currency", currency: "USD" };
       return amount.toLocaleString("en-US", optionsa);
     };
  return (
    <div className="tableX">
      <ResponsiveDrawer heading="Order Management" />
      <Box
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
        component="main"
      >
        <Toolbar />
        <div className="position-relative">
          <div className=" d-flex justify-content-between mt-3 p-4">
            <h4 className="">{orderheading}</h4>
            <div className="Accordian overflow-auto">
              {/* <div className="mx-2">
                <p
                  className="forDropDown position-relative"
                  onClick={() => SortBy()}
                >
                  Sort&nbsp;by
                  <KeyboardArrowDownIcon style={{ color: "#f00e0e" }} />
                </p>
                <div className={show ? "sort" : "sort-by"}>
                  <ul>
                    <li onClick={() => PO()}>PO#</li>
                    <li onClick={() => Sku()}>SKU</li>
                    <li onClick={() => Start()}>Start Date</li>
                    <li onClick={() => Price()}>Price</li>
                    <li onClick={() => storeName()}>StoreName</li>
                  </ul>
                </div>
              </div> */}
              <div className="one">
                <div className="btncsv">
                  <button
                    className=" CSV btn1 mb-3 px-3"
                    onClick={ExportexcelFactory}
                  >
                    <small>FactoryReady&nbsp;Export </small>
                  </button>
                </div>
              </div>

              <div className="mx-2">
                <div className="btncsv">
                  <button
                    className="CSV btn1 mb-3 px-2"
                    onClick={ExportexcelBulk}
                  >
                    <small>Bulk&nbsp;Export</small>
                  </button>
                </div>
              </div>
              <div className="mx-2">
                <div className="btncsv">
                  <button
                    className="CSV btn1 mb-3 px-2"
                    onClick={FactoryPrices}
                  >
                    <small>Factory&nbsp;Ready&nbsp;Prices&nbsp;Export</small>
                  </button>
                </div>
              </div>
              <div className="ImportCSV1">
                <div className="ms-2">
                  <button
                    className="btnxcv mb-3 px-3"
                    onClick={() => {
                      setConfirmDialog({
                        isOpen: true,
                        title: "Delete All Orders?",
                        subTitle: "You can't undo this operation",
                        onConfirm: () => {
                          DeleteAllOrders();
                        },
                      });
                    }}
                  >
                    <small>Delete&nbsp;All&nbsp;Orders</small>
                  </button>
                </div>
              </div>
              <div className="ImportCSV1">
                <div className="sorting">
                  <div>
                    <Button
                      id="demo-customized-button"
                      aria-controls="demo-customized-menu"
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      // variant="contained"
                      disableElevation
                      onClick={handleClick}
                      endIcon={
                        <KeyboardArrowDownIcon style={{ color: "#f00e0e" }} />
                      }
                    >
                      <span className="fw-bolder text-dark">Sort&nbsp;By</span>
                    </Button>
                    <StyledMenu
                      id="demo-customized-menu"
                      MenuListProps={{
                        "aria-labelledby": "demo-customized-button",
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                    >
                      <MenuItem
                        disableRipple
                        // eslint-disable-next-line react/jsx-no-duplicate-props
                        onClick={() => PODown()}
                      >
                        PO&nbsp;
                        <ArrowDownwardIcon className="ml-auto " />
                      </MenuItem>
                      <MenuItem
                        disableRipple
                        // eslint-disable-next-line react/jsx-no-duplicate-props
                        onClick={() => PO()}
                      >
                        PO&nbsp;
                        <ArrowUpwardIcon className="ml-auto " />
                      </MenuItem>

                      <MenuItem
                        disableRipple
                        // eslint-disable-next-line react/jsx-no-duplicate-props
                        onClick={() => Start()}
                      >
                        Start&nbsp;Date&nbsp;
                        <ArrowDownwardIcon className="ml-auto " />
                      </MenuItem>
                      {/* //// */}
                      <MenuItem
                        disableRipple
                        // eslint-disable-next-line react/jsx-no-duplicate-props
                        onClick={() => StartDown()}
                      >
                        Start&nbsp;Date&nbsp;
                        <ArrowUpwardIcon className="ml-auto " />
                      </MenuItem>
                      <MenuItem
                        disableRipple
                        // eslint-disable-next-line react/jsx-no-duplicate-props
                        onClick={() => Price()}
                      >
                        Price&nbsp;
                        <ArrowDownwardIcon className="ml-auto " />
                      </MenuItem>
                      <MenuItem
                        disableRipple
                        // eslint-disable-next-line react/jsx-no-duplicate-props
                        onClick={() => PriceUp()}
                      >
                        Price&nbsp;
                        <ArrowUpwardIcon className="ml-auto " />
                      </MenuItem>
                      <MenuItem
                        disableRipple
                        // eslint-disable-next-line react/jsx-no-duplicate-props
                        onClick={() => StoreNameDown()}
                      >
                        Store&nbsp;Name&nbsp;
                        <ArrowDownwardIcon className="ml-auto " />
                      </MenuItem>
                      <MenuItem
                        disableRipple
                        // eslint-disable-next-line react/jsx-no-duplicate-props
                        onClick={() => StoreName()}
                      >
                        Store&nbsp;Name&nbsp;
                        <ArrowUpwardIcon className="ml-auto " />
                      </MenuItem>
                      <MenuItem
                        disableRipple
                        // eslint-disable-next-line react/jsx-no-duplicate-props
                        onClick={
                          HandleActiveOrders

                          // alert("/AllStyleSold");
                        }
                      >
                        Pending&nbsp;Orders
                      </MenuItem>
                      <MenuItem
                        disableRipple
                        // eslint-disable-next-line react/jsx-no-duplicate-props
                        onClick={HandleCompleteOrders}
                      >
                        Completed&nbsp;Orders
                      </MenuItem>
                      {/* <Divider sx={{ my: 0.5 }} /> */}
                      <MenuItem
                        disableRipple
                        // eslint-disable-next-line react/jsx-no-duplicate-props
                        onClick={
                          HandleCancelOrders
                          // alert("/CustomerOrderStatus");
                        }
                      >
                        Cancelled&nbsp;Orders
                      </MenuItem>
                      <MenuItem
                        disableRipple
                        // eslint-disable-next-line react/jsx-no-duplicate-props
                        onClick={HandleAllOrders}
                      >
                        All&nbsp;Orders
                      </MenuItem>
                    </StyledMenu>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={allorders ? "jhhgg" : "d-none"}>
            <div className="d-flex justify-content-between mx-2">
              <small className="ms-4 fw-bolder mt-1">Search&nbsp;By</small>
              <div className="position-relative w-75 me-3">
                <Form>
                  <Form.Control
                    placeholder="PO#, StoreName or SKU"
                    type="search"
                    className="input_field"
                    onChange={(e) => setfilter(e.target.value)}
                  />
                  <BiSearch className="search_Agent" />
                </Form>
              </div>
            </div>
            {done ? (
              <CircularIndeterminate className="resetLoading" />
            ) : orders.length > 0 ? (
              <div className="for__table m-4">
                <Paper sx={{ width: "100%", overflow: "hidden" }}>
                  <TableContainer sx={{ maxHeight: 730 }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {columns.map((column) => (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{ minWidth: column.minWidth }}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {orders.length > 0 ? (
                          orders.filter((admin) => {
                            return admin.status === "1";
                          })
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .filter((admin) => {
                              if (filter === "") {
                                return orders;
                              } else if (
                                (admin.orderNumber &&
                                  admin.orderNumber
                                    .toString()
                                    .includes(filter.toString())) ||
                                (admin.sku &&
                                  admin.sku
                                    .toString()
                                    .toLowerCase()
                                    .includes(filter)) ||
                                (admin.storeName &&
                                  admin.storeName
                                    .toString()
                                    .toLowerCase()
                                    .includes(filter.toString().toLowerCase()))
                              ) {
                                return orders;
                              }
                            })
                            .map((admin) => {
                              return (
                                <TableRow
                                  hover
                                  role="checkbox"
                                  tabIndex={-1}
                                  key={admin._id}
                                >
                                  <TableCell>{admin.storeName}</TableCell>
                                  <TableCell>{admin.orderNumber}</TableCell>
                                  <TableCell>{admin.totalPairs}</TableCell>

                                  {/* <TableCell>{admin.sku}</TableCell> */}
                                  <TableCell>
                                    {commafunc(admin.totalSales)}
                                  </TableCell>
                                  <TableCell>
                                    {runCallback(() => {
                                      let row = [];
                                      let arr = admin.orderDate.split("-");
                                      row.push(
                                        arr[1] + "-" + arr[2] + "-" + arr[0]
                                      );
                                      return row;
                                    })}
                                  </TableCell>
                                  <TableCell>
                                    {runCallback(() => {
                                      let row = [];
                                      let arr = admin.shipDate.split("-");
                                      row.push(
                                        arr[1] + "-" + arr[0] + "-" + arr[2]
                                      );
                                      return row;
                                    })}

                                    {/* {admin.shipDate} */}
                                  </TableCell>

                                  <TableCell>
                                    {runCallback(() => {
                                      let row = [];
                                      let arr = admin.cancelDate.split("-");
                                      row.push(
                                        arr[1] + "-" + arr[0] + "-" + arr[2]
                                      );
                                      return row;
                                    })}
                                    {/* {admin.cancelDate} */}
                                  </TableCell>
                                  {/* <TableCell>{admin.cancelDate}</TableCell> */}

                                  <TableCell>
                                    {admin.status == "1" ? (
                                      <div style={{ color: "green" }}>
                                        <small>Pending</small>{" "}
                                      </div>
                                    ) : admin.status == "2" ? (
                                      <div style={{ color: "blue" }}>
                                        <small>Completed</small>{" "}
                                      </div>
                                    ) : admin.status == "0" ? (
                                      <div style={{ color: "red" }}>
                                        <small> Canceled </small>{" "}
                                      </div>
                                    ) : (
                                      <div style={{ color: "red" }}>
                                        <small> Not Available </small>{" "}
                                      </div>
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    <div className="App">
                                      {admin.status == "1" ? (
                                        <Dropdown className="">
                                          <Dropdown.Toggle as={CustomToggle} />
                                          <Dropdown.Menu className="">
                                            <Dropdown.Item className="bg-white">
                                              {" "}
                                              <span
                                                onClick={() => {
                                                  ChangeStatusCancel(
                                                    admin.orderNumber
                                                  );
                                                }}
                                                className="abc"
                                              >
                                                Cancel
                                              </span>
                                            </Dropdown.Item>
                                            <Dropdown.Item className="bg-white">
                                              <span
                                                className="abc"
                                                onClick={() => {
                                                  ChangeStatusComplete(
                                                    admin.orderNumber
                                                  );
                                                }}
                                              >
                                                Complete
                                              </span>
                                            </Dropdown.Item>
                                            <Dropdown.Item className="bg-white">
                                              {" "}
                                              <span
                                                onClick={() => {
                                                  navigate(
                                                    `/PurchaseOrder/${admin.orderNumber}`
                                                  );
                                                }}
                                                className="abc"
                                              >
                                                View
                                              </span>
                                            </Dropdown.Item>
                                            <Dropdown.Item className="bg-white">
                                              <span
                                                className="abc"
                                                onClick={() => {
                                                  // setConfirmDialog({
                                                  //   isOpen: true,
                                                  //   title:
                                                  //     "Are you sure to delete this record?",
                                                  //   subTitle:
                                                  //     "You can't undo this operation",
                                                  //   onConfirm: () => {
                                                  handleDelete(
                                                    admin.orderNumber
                                                  );
                                                  //   },
                                                  // });
                                                }}
                                              >
                                                Delete
                                              </span>
                                            </Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      ) : admin.status == "2" ? (
                                        <Dropdown className="">
                                          <Dropdown.Toggle as={CustomToggle} />
                                          <Dropdown.Menu className="">
                                            <Dropdown.Item className="bg-white">
                                              {" "}
                                              <span
                                                onClick={() => {
                                                  navigate(
                                                    `/PurchaseOrder/${admin.orderNumber}`
                                                  );
                                                }}
                                                className="abc"
                                              >
                                                View
                                              </span>
                                            </Dropdown.Item>
                                            <Dropdown.Item className="bg-white">
                                              {" "}
                                              <span
                                                onClick={() => {
                                                  ChangeStatusCancel(
                                                    admin.orderNumber
                                                  );
                                                }}
                                                className="abc"
                                              >
                                                Cancel
                                              </span>
                                            </Dropdown.Item>
                                            <Dropdown.Item className="bg-white">
                                              {" "}
                                              <span
                                                onClick={() => {
                                                  ChangeStatusPending(
                                                    admin.orderNumber
                                                  );
                                                }}
                                                className="abc"
                                              >
                                                Pending
                                              </span>
                                            </Dropdown.Item>
                                            <Dropdown.Item className="bg-white">
                                              <span
                                                className="abc"
                                                onClick={() => {
                                                  handleDelete(
                                                    admin.orderNumber
                                                  );
                                                }}
                                              >
                                                Delete
                                              </span>
                                            </Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      ) : (
                                        <Dropdown className="">
                                          <Dropdown.Toggle as={CustomToggle} />
                                          <Dropdown.Menu className="">
                                            <Dropdown.Item className="bg-white">
                                              {" "}
                                              <span
                                                onClick={() => {
                                                  navigate(
                                                    `/PurchaseOrder/${admin.orderNumber}`
                                                  );
                                                }}
                                                className="abc"
                                              >
                                                View
                                              </span>
                                            </Dropdown.Item>
                                            <Dropdown.Item className="bg-white">
                                              <span
                                                className="abc"
                                                onClick={() => {
                                                  ChangeStatusComplete(
                                                    admin.orderNumber
                                                  );
                                                }}
                                              >
                                                Complete
                                              </span>
                                            </Dropdown.Item>
                                            <Dropdown.Item className="bg-white">
                                              {" "}
                                              <span
                                                onClick={() => {
                                                  ChangeStatusPending(
                                                    admin.orderNumber
                                                  );
                                                }}
                                                className="abc"
                                              >
                                                Pending
                                              </span>
                                            </Dropdown.Item>
                                            <Dropdown.Item className="bg-white">
                                              <span
                                                className="abc"
                                                onClick={() => {
                                                  handleDelete(
                                                    admin.orderNumber
                                                  );
                                                }}
                                              >
                                                Delete
                                              </span>
                                            </Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      )}
                                    </div>
                                  </TableCell>
                                </TableRow>
                              );
                            })
                        ) : (
                          <small className="text-danger">NO DATA!</small>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    className="mx-5 mt-3 px-5"
                    rowsPerPageOptions={[1000, 2000, 4000]}
                    component="div"
                    count={orders.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>
              </div>
            ) : (
              <small className="text-danger mx-5 my-5">No Orders Found!</small>
            )}
          </div>
        </div>
      </Box>

      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </div>
  );
}

export default PendingOrders;
