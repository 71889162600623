import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../App";
import ResponsiveDrawer from "../Drawer/drawer";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { Form } from "react-bootstrap";
import { BiSearch } from "react-icons/bi";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import Dropdown from "react-bootstrap/Dropdown";
import Notification from "../AlertNotification/Message";
import ConfirmDialog from "../AlertNotification/confirmdialog";
import CircularIndeterminate from "../Loader/Loader";


////////////
const drawerWidth = 240;
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <span className="threedots" />
  </a>
));
const columns = [
  {
    id: "img",
    label: "Avatar",
    minWidth: 50,
    align: "left",
  },
  {
    id: "name",
    label: "Name",
    minWidth: 100,
    align: "left",
  },
  { id: "code", label: "Email", minWidth: 50, align: "left" },
  {
    id: "population",
    label: "Phone",
    minWidth: 100,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "density",
    label: "Cell\u00a0Number",
    minWidth: 50,
    align: "left",
    format: (value) => value.toFixed(2),
  },
  {
    id: "action",
    label: "Action",
    minWidth: 50,
    align: "left",
    format: (value) => value.toFixed(2),
  },
];

function AllReps() {
  const navigate = useNavigate();
  const [filter, setfilter] = useState("");
  const [admins, setadmins] = useState([]);
  const [done, setdone] = useState(false);
  const [tokenauth, settokenauth] = useState("")

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const HandleClick = () => {
    navigate("/AddNewReps");
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(1000);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const GetAdmins =  () => {
    axios.post("/get-token" ,{
      id: sessionStorage.getItem("token_id")
    }).then((res) => {
      settokenauth(res.data.token)
     axios.post(`/get-employees`, {} ,{
      
        headers: {
          
          "x-auth-header": res.data.token
        },
    }).then((res) => {
      setadmins(res.data);
      setdone(true);
    }).catch(err => console.log(err.response))
   
  }).catch((err) => console.log(err))
    // console.log(response.data);
  };

  useEffect(() => {
    sessionStorage.getItem("payloadout") != "true"
      ? navigate("/")
      :
      sessionStorage.setItem("id", "2");
    GetAdmins();
  }, []);
  /////
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });


  /////
  const handleDelete = (mail) => {
    // console.log(mail);
    setdone(true);

    // setConfirmDialog({
    //   ...confirmDialog,
    //   isOpen: false,
    // });
    axios
      .post("/delete-employee", {
        email: mail,
      }, {
      
        headers: {
          
          "x-auth-header": tokenauth
        },
    })
      .then((response) => {
        setdone(false);
        console.log(response.status);
        if (response.status === 200) {
          setdone(false);
          setNotify({
            isOpen: true,
            message: `${response.data.message}`,
            type: "success",
          });
        }
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        setdone(false);
        console.log(err.status);
        setNotify({
          isOpen: true,
          message: `${err.response}`,
          type: "error",
        });
        // console.log(err);
      });
  };

  return (
    <div className="tableX">
      <ResponsiveDrawer heading="Sales Reps Management" />
      <Box
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
        component="main"
      >
        <Toolbar />
        {!done ? (
          <CircularIndeterminate className="allagentsLoader" />
        ) : (
          <div className="position-relative">
            <div className=" d-flex justify-content-between mt-3 p-4">
              <h4 className="">All&nbsp;Sales&nbsp;Reps</h4>
              <div className="d-flex">
                <button onClick={HandleClick} className="btn2 px-3">
                  Add&nbsp;Sales&nbsp;Rep
                </button>
              </div>
            </div>
            <div className="d-flex justify-content-between mx-2">
              <small className="ms-4 fw-bolder mt-1">Search&nbsp;By</small>
              <div className="position-relative w-75 me-3">
                <Form>
                  <Form.Control
                    placeholder="Name, Email or Phone"
                    type="search"
                    className="input_field"
                    onChange={(e) => setfilter(e.target.value)}
                  />
                  <BiSearch className="search_Agent" />
                </Form>
              </div>
            </div>
            {/*  */}
            {/*  */}
            {/*  */}
            <div className="for__table m-4">
              <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer sx={{ maxHeight: 730 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {admins
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        // eslint-disable-next-line array-callback-return
                        .filter(function (admin) {
                          if (filter === "") {
                            return admins;
                          } else if (
                            (admin.firstname &&
                              admin.firstname
                                .toString()
                                .toLowerCase()
                                .includes(filter.toString())) ||
                            (admin.lastname &&
                              admin.lastname
                                .toString()
                                .toLowerCase()
                                .includes(filter.toString())) ||
                            (admin.email &&
                              admin.email
                                .toString()
                                .toLowerCase()
                                .includes(filter.toLowerCase())) ||
                            (admin.phone &&
                              admin.phone
                                //
                                .includes(filter.toString())) ||
                            (admin.joiningdate &&
                              admin.joiningdate

                                .toLowerCase()
                                .toString()
                                .includes(filter.toLowerCase().toString())) ||
                            (admin.description &&
                              admin.description

                                .toLowerCase()
                                .includes(filter.toLowerCase())) ||
                            (admin.description &&
                              admin.description

                                .toLowerCase()
                                .includes(filter.toLowerCase())) ||
                            (admin.joiningdate &&
                              admin.joiningdate

                                .toLowerCase()
                                .includes(filter.toLowerCase())) ||
                            (admin.storename &&
                              admin.storename
                                .toLowerCase()
                                .includes(filter.toLowerCase()))
                          ) {
                            return admins;
                          }
                        })
                        .map((admin) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={admin._id}
                            >
                              <TableCell>
                                <img
                                  src={admin.image}
                                  className="newimages"
                                  alt=""
                                />
                              </TableCell>
                              <TableCell>
                                {admin.firstname}&nbsp;
                                {admin.lastname}
                              </TableCell>
                              <TableCell>{admin.email}</TableCell>
                              <TableCell>{admin.phone}</TableCell>
                              <TableCell>{admin.joiningdate}</TableCell>
                              <TableCell>
                                <div className="App">
                                  <Dropdown className="">
                                    <Dropdown.Toggle as={CustomToggle} />
                                    <Dropdown.Menu className="">
                                      <Dropdown.Item className="bg-white">
                                        {" "}
                                        <span
                                          onClick={() => {
                                            navigate(
                                              `/RepsDetail/${admin.email}`
                                            );
                                          }}
                                          className="abc"
                                        >
                                          View
                                        </span>
                                      </Dropdown.Item>
                                      <Dropdown.Item className="bg-white">
                                        {" "}
                                        <span
                                          onClick={() => {
                                            navigate(
                                              `/UpdateReps/${admin.email}`
                                            );
                                          }}
                                          className="abc"
                                        >
                                          Edit
                                        </span>
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        className="bg-white"
                                        onClick={() => {
                                          setConfirmDialog({
                                            isOpen: true,
                                            title: "Are you sure you want to delete this agent?",
                                            subTitle: "This might loss all the orders and data against this agent",
                                            onConfirm: () => {
                                              handleDelete(admin.email);
                                            },
                                          });
                                        }}


                                      >
                                        <span className="abc">Delete</span>
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>

                <TablePagination
                  className="mx-5 mt-3 px-5"
                  rowsPerPageOptions={[1000, 2000, 4000]}
                  component="div"
                  count={admins.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </div>
          </div>
        )}
      </Box>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </div>
  );
}

export default AllReps;
