import React, { useState, useEffect, useContext } from "react";

import { UserContext } from "../../App";

import ResponsiveDrawer from "../Drawer/drawer";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { BiSearch } from "react-icons/bi";
import XLSX from "xlsx";

import axios from "axios";

import Dropdown from "react-bootstrap/Dropdown";

import Notification from "../AlertNotification/Message";

import ConfirmDialog from "../AlertNotification/confirmdialog";

import CircularIndeterminate from "../Loader/Loader";
import CustomerGrid from "./CustomerGrid";
import GridViewIcon from "@mui/icons-material/GridView";
import ViewListIcon from "@mui/icons-material/ViewList";
import ImportCustomers from "./ImportCustomers";

////////////

const drawerWidth = 240;

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <span className="threedots" />
  </a>
));
const columns = [
  // {
  //   id: "img",
  //   label: "Avatar",
  //   minWidth: 100,
  //   align: "left",
  // },
  {
    id: "density",
    label: "ID",
    minWidth: 100,
    align: "left",
    format: (value) => value.toFixed(2),
  },
  // {
  //   id: "name",
  //   label: "Name",
  //   minWidth: 130,
  //   align: "left",
  // },
  { id: "code", label: "Store\u00a0Name", minWidth: 80, align: "left" },

  { id: "code", label: "Buyer\u00a0Name", minWidth: 80, align: "left" },
  {
    id: "population",
    label: "Phone",
    minWidth: 140,
    align: "left",
  },
  {
    id: "population",
    label: "Cell",
    minWidth: 140,
    align: "left",
  },
  { id: "code", label: "Email", minWidth: 80, align: "left" },

  {
    id: "action",
    label: "Action",
    minWidth: 100,
    align: "left",
    format: (value) => value.toFixed(2),
  },
];

function AllCustomer() {
  const navigate = useNavigate();
  const [filter, setfilter] = useState("");
  const [customers, setcustomers] = useState([]);
  const [done, setdone] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [showlist, setshowlist] = useState(true);
  const [showgrid, setshowgrid] = useState(false);
  const [exportarray, setexportarray] = useState([]);

  const ShowList = () => {
    setshowlist(!showlist);
    setshowgrid(false);
  };
  const ShowGrid = () => {
    setshowgrid(!showgrid);
    setshowlist(false);
  };

  const HandleClick = () => {
    navigate("/AddCustomer");
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(1000);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [tokenauth, settokenauth] = useState("")

  const GetCustomers = () => {
    axios.post("/get-token", {
      id: sessionStorage.getItem("token_id")
    }).then((res) => {
      settokenauth(res.data.token)
      console.log(res.data.token)
      axios
        .post("/get-customers", {}
        , {

          headers: {

            "x-auth-header": res.data.token
          },
        }).then((response) => {
          setcustomers(response.data);
          // console.log(response.data)
          setdone(true);
          let arr = [];
          // console.log(response);
          response.data.map((data) => {
            let obj = {};

            obj["customerid"] = data.customerid;
            obj["firstName"] = data.firstname;
            obj["lastName"] = data.lastname;
            obj["storeName"] = data.StoreName;
            obj["email_1"] = data.email_1;
            obj["email_2"] = data.email_2;
            obj["storePhone"] = data.StorePhone;
            obj["cell_1"] = data.cell_1;
            obj["cell_2"] = data.cell_2;
            obj["firstcontact_firstName"] = data.sc_firstname;
            obj["firstcontact_lastName"] = data.sc_lastname;
            obj["firstcontact_email"] = data.sc_email;
            obj["firstontact_officePhone"] = data.sc_OfficePhone;
            obj["firstontact_cell_1"] = data.sc_cell_1;
            obj["firstontact_cell_2"] = data.sc_cell_2;
            obj["secondcontact_firstname"] = data.tc_firstname;
            obj["secondcontact_lastname"] = data.tc_lastname;
            obj["secondcontact_email"] = data.tc_email;
            obj["secondcontact_OfficePhone"] = data.tc_OfficePhone;
            obj["secondcontact_cell_1"] = data.tc_cell_1;
            obj["secondcontact_cell_2"] = data.tc_cell_2;
            obj["shippingAddress_address1"] = data.s_address1;
            obj["shippingAddress_address2"] = data.s_address2;
            obj["shippingAddress_street"] = data.s_street;
            obj["shippingAddress_postcode"] = data.s_postcode;
            obj["shippingAddress_city"] = data.s_city;
            obj["shippingAddress_state"] = data.s_state;
            obj["shippingAddress_country"] = data.s_country;
            obj["billingAddress_address1"] = data.b_address1;
            obj["billingAddress_address2"] = data.b_address2;
            obj["billingAddress_street"] = data.b_street;
            obj["billingAddress_postcode"] = data.b_postcode;
            obj["billingAddress_city"] = data.b_city;
            obj["billingAddress_state"] = data.b_state;
            obj["billingAddress_country"] = data.b_country;

            arr.push(obj);
          });
          setexportarray(arr);
          // setTimeout(() => {
          //   setNotify({
          //     isOpen: true,
          //     message: "Request Timeout",
          //     type: "error",
          //   });
          //   setdone(false);
          // }, 100);

          // setdone(true);
        }).catch(err => console.log(err.response))
    }).catch((err) => console.log(err))
  };
  // console.log(exportarray && exportarray);
  useEffect(() => {
    sessionStorage.getItem("payloadout") != "true"
      ? navigate("/")
      : sessionStorage.setItem("id", "4");

    GetCustomers();
  }, []);

  const handleDelete = (id) => {
    setdone(true);
    // console.log(id);
    // setConfirmDialog({
    //   ...confirmDialog,
    //   isOpen: false,
    // });
    axios
      .post("/delete-customer", {
        customerid: id,
      }, {

        headers: {

          "x-auth-header": tokenauth
        },
      })
      .then((response) => {
        setdone(false);
        console.log(response.status);
        if (response.status === 200) {
          setdone(false);
          setNotify({
            isOpen: true,
            message: `${response.data.message}`,
            type: "success",
          });
        }
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        setdone(false);
        console.log(err.status);
        setNotify({
          isOpen: true,
          message: `${err.response}`,
          type: "error",
        });
        // console.log(err);
      });
  };

  const ExportCustomers = () => {
    setcsv(false);

    setdone(true);

    const workSheet = XLSX.utils.json_to_sheet(exportarray && exportarray);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "order");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    let today = new Date().toLocaleDateString();
    XLSX.writeFile(workBook, `Customers-${today}.xlsx`);
    // setTimeout(() => {
    //   window.location.reload();
    // }, 1000);
  };
  const [csv, setcsv] = useState(false);

  const handleCSVopen = () => {
    setcsv(!csv);
  };

  return (
    <div className="tableX">
      <ResponsiveDrawer heading="Customer Management" />
      <Box
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
        component="main"
      >
        <Toolbar />
        {!done ? (
          <CircularIndeterminate className="allagentsLoader" />
        ) : (
          <div className="position-relative">
            <div className=" d-flex justify-content-between mt-3 p-4">
              <h4 className="">All&nbsp;Customers</h4>
              <div className="d-flex">
                <div className="d-flex">
                  <button onClick={ExportCustomers} className="btn3 px-3">
                    Export&nbsp;Customer
                  </button>
                </div>

                <div className="d-flex">
                  <button onClick={handleCSVopen} className="btn3 ms-3">
                    Import&nbsp;Customers
                  </button>
                </div>
                <div className="d-flex">
                  <button onClick={HandleClick} className="btn2 ms-3">
                    Add&nbsp;Customers
                  </button>
                </div>
              </div>
            </div>
            <div className={!csv ? "d-none" : ""}>
              <ImportCustomers />
            </div>
            <div className=" d-flex flex-row align-items-end justify-content-end me-5 mb-5">
              <div>
                <GridViewIcon
                  fontSize="small"
                  className="me-3 icon22 cursor-pointer"
                  onClick={() => ShowGrid()}
                />
              </div>
              <div>
                <ViewListIcon
                  className="icon22"
                  fontSize="small"
                  onClick={() => ShowList()}
                />
              </div>
            </div>
            <div className={!showlist ? "showlistgrid" : null}>
              <div className="d-flex justify-content-between mx-2">
                <small className="ms-4 fw-bolder mt-1">Search&nbsp;By</small>
                <div className="position-relative w-75 me-3">
                  <Form>
                    <Form.Control
                      placeholder="CustomerID, Store Name, Email or Phone"
                      type="search"
                      className="input_field"
                      onChange={(e) => setfilter(e.target.value)}
                    />
                    <BiSearch className="search_Agent" />
                  </Form>
                </div>
              </div>

              <div className="for__table m-4">
                <Paper sx={{ width: "100%", overflow: "hidden" }}>
                  <TableContainer sx={{ maxHeight: 730 }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {columns.map((column) => (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{ minWidth: column.minWidth }}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {customers
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          // eslint-disable-next-line array-callback-return
                          .filter((admin) => {
                            if (filter === "") {
                              return customers;
                            } else if (
                              admin.customerid && admin.customerid
                                .toString()
                                .includes(filter.toString())
                              ||
                              (admin.email_1 &&
                                admin.email_1
                                  .toString().toUpperCase()
                                  .includes(filter.toString().toUpperCase()))
                              ||
                              (admin.StoreName &&
                                admin.StoreName
                                  .toString().toUpperCase()
                                  .includes(filter.toString().toUpperCase()))
                              ||
                              (admin.StorePhone &&
                                admin.StorePhone.toString().includes(
                                  filter.toString()
                                ))
                            ) {
                              return customers;
                            }
                          })
                          .map((customer) => {
                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={customer._id}
                              >
                                {/* <TableCell>
                                  <img
                                    src={customer.image}
                                    className="newimages"
                                  />
                                </TableCell> */}
                                <TableCell>{customer.customerid}</TableCell>
                                <TableCell>{customer.StoreName}</TableCell>
                                <TableCell>
                                  {customer.firstname}&nbsp;{customer.lastname}
                                </TableCell>
                                <TableCell>{customer.StorePhone}</TableCell>
                                <TableCell>{customer.cell_1}</TableCell>
                                <TableCell>{customer.email_1}</TableCell>

                                <TableCell>
                                  <div className="App">
                                    <Dropdown className="">
                                      <Dropdown.Toggle as={CustomToggle} />
                                      <Dropdown.Menu className="">
                                        <Dropdown.Item className="bg-white">
                                          {" "}
                                          <span
                                            onClick={() => {
                                              navigate(
                                                `/UpdateCustomer/${customer._id}`
                                              );
                                            }}
                                            className="abc"
                                          >
                                            Edit
                                          </span>
                                        </Dropdown.Item>
                                        <Dropdown.Item className="bg-white">
                                          <span
                                            className="abc"
                                            onClick={() => {
                                              // setConfirmDialog({
                                              //   isOpen: true,
                                              //   title:
                                              //     "Are you sure to delete this record?",
                                              //   subTitle:
                                              //     "You can't undo this operation",
                                              //   onConfirm: () => {
                                              handleDelete(customer.customerid);
                                              //   },
                                              // });
                                            }}
                                          >
                                            Delete
                                          </span>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    className="mx-5 mt-3 px-5"
                    rowsPerPageOptions={[1000, 2000, 4000]}
                    component="div"
                    count={customers.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>
              </div>
            </div>
          </div>
        )}
        <div className={!showgrid ? "showlistgrid" : null}>
          <CustomerGrid />
        </div>
      </Box>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </div>
  );
}

export default AllCustomer;
