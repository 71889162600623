import { Image } from "react-bootstrap";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PieChartIcon from "@mui/icons-material/PieChart";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import DashboardCustomizeOutlinedIcon from "@mui/icons-material/DashboardCustomizeOutlined";
import FlipToBackOutlinedIcon from "@mui/icons-material/FlipToBackOutlined";
import SupervisorAccountOutlinedIcon from "@mui/icons-material/SupervisorAccountOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import kinderland3 from "../Images/kinderland3.png";
import { useNavigate, Link } from "react-router-dom";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import useStyles from "./style";
import axios from "axios";
import CircularIndeterminate from "../Loader/Loader";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import Logoutloader from "../Loader/loaderlogout";
import FirebaseNotificationz from "./notification";
import "../RegisterSection/Login";
import Notification from "../AlertNotification/Message";
import "./style";
import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../App";
import { initialState } from "../../reducer/useReducer";
//
const drawerWidth = 240;
//
function ResponsiveDrawer(props) {
  const [selectedFile, setSelectedFile] = useState({
    file: [],
    filepreview: null,
  });
  const [admins, setadmins] = useState("");
  const [firebasenotify, setfirebasenotify] = useState(false);
  const [done, setdone] = useState(false);
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  // Notification Message
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  ////////
  const { state, dispatch } = useContext(UserContext);
  // LogOut api call
  const handleLogOut = (e) => {
    e.preventDefault();
    // setPristine();
    setdone(true);
    axios.post("/delete-token", {
      id: sessionStorage.getItem("token_id")
    }).then((res) => {
      console.log(`token vich status = ${res.status}`)
      sessionStorage.setItem("payloadout", "false");
      dispatch({ type: "USER", payload: false });

      sessionStorage.setItem("fname", "");
      sessionStorage.setItem("lname", "");

      navigate("/");
    }).catch(err => console.log(err.response))

  };

  //
  //
  const { window } = props;
  const { heading } = props;
  // console.log(email);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [menuactive, setmenuactive] = useState(false);
  const navigate = useNavigate();
  const [lengthx, setlengthx] = useState();

  const GetAdminInfo = () => {
    console.log(sessionStorage.getItem("email"))
    axios.post("get-token", {
      id: sessionStorage.getItem("token_id")
    }).then((response) => {
    axios
      .post("/get-admins", {
        email: sessionStorage.getItem("email"),
      }, {
      
        headers: {
          
          "x-auth-header": response.data.token
        },
    })
      .then((response) => {
        // console.log(response.data);
        response.data.AdminProfile.map(function (admin) {
          // setEmail(admin.email);
          setFname(admin.firstname);
          setLname(admin.lastname);
          sessionStorage.setItem("fname", admin.firstname);
          sessionStorage.setItem("lname", admin.lastname);
          sessionStorage.setItem("imge", admin.image);
        });
      })
      .catch((err) => console.log(err.response));
      })
      .catch((err) => console.log(err.response));
    setSelectedFile(sessionStorage.getItem("imge"));
  };
  // console.log(admins);
  const GetNotifications =  () => {
    axios.post("get-token", {
      id: sessionStorage.getItem("token_id")
     }).then((response) => {
    axios.post("/get-admin-notification", {
      admin_email: sessionStorage.getItem("email"),
    },{
      
      headers: {
        
        "x-auth-header": response.data.token
      },
  }).then((response) => {
 // setnotification(response.data);
 let filtered = response.data.filter((admin) => {
  return admin.read_status == "0";
});
let len = filtered.length;
setlengthx(len);
// console.log(len);
    }).catch(err => console.log(err))
  }).catch(err => console.log(err))
   
  };

  // console.log(lengthx);
  useEffect(() => {
    // GetAdminEmail();
    // GetNotifications();
    GetAdminInfo();
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  /////
  const [idside, setidside] = useState("1");
  /////SIDEBAR ITEM'S ARRAY START
  const ItemList = [
    {
      text: "Dashboard",
      icon: (
        <PieChartIcon
          className={
            sessionStorage.getItem("id") == "1" ? "clickicon" : " icon22"
          }
        />
      ),
      id: "1",
      onClick: (id) => {
        setidside(id && id);
        navigate("/Dashboard");
      },
    },
    {
      text: "Sales Reps",
      icon: (
        <PermIdentityIcon
          className={
            sessionStorage.getItem("id") == "2" ? "clickicon " : " icon22"
          }
        />
      ),
      id: "2",
      onClick: (id) => {
        setidside(id && id);
        navigate("/AllReps");
      },
    },
    {
      text: "Products",
      icon: (
        <DashboardCustomizeOutlinedIcon
          className={
            sessionStorage.getItem("id") == "3" ? "clickicon " : " icon22"
          }
        />
      ),
      id: "3",
      onClick: (id) => {
        navigate("/AllProducts");
      },
    },
    {
      text: "Customers",
      icon: (
        <FlipToBackOutlinedIcon
          className={
            sessionStorage.getItem("id") == "4" ? "clickicon " : " icon22"
          }
        />
      ),
      id: "4",
      onClick: () => {
        navigate("/AllCustomers");
      },
    },
    {
      text: "Orders",
      icon: (
        <SupervisorAccountOutlinedIcon
          className={
            sessionStorage.getItem("id") == "5" ? "clickicon " : " icon22"
          }
        />
      ),
      id: "5",
      onClick: () => {
        navigate("/OrderHistory");
      },
    },
    {
      text: "Admins",
      icon: (
        <PeopleOutlineIcon
          className={
            sessionStorage.getItem("id") == "6" ? "clickicon " : " icon22"
          }
        />
      ),
      id: "6",
      onClick: () => {
        navigate("/AllAdmin");
      },
    },
    {
      text: "Reports",
      icon: (
        <AnalyticsIcon
          className={
            sessionStorage.getItem("id") == "7" ? "clickicon " : " icon22"
          }
        />
      ),
      id: "7",
      onClick: () => {
        navigate("/TotalSales");
      },
    },
    {
      text: "Settings",
      icon: (
        <SettingsOutlinedIcon
          className={
            sessionStorage.getItem("id") == "8" ? "clickicon " : " icon22"
          }
        />
      ),
      id: "8",
      onClick: () => {
        navigate("/setting");
      },
    },
  ];
  /////SIDEBAR ITEM'S ARRAY END
  const classes = useStyles();
  const drawer = (
    <div>
      <div className="maindrawer">
        <Link to="">
          <Image src={kinderland3} alt="kinderland3.png" className="logo" />
        </Link>
        <Toolbar />
        {/* SIDEBAR LINKS  START*/}
        <div className="lmain">
          <List className="mt-5">
            {ItemList.map((item, index) => {
              const { text, icon, id, onClick } = item;
              return (
                <div
                  className={
                    sessionStorage.getItem("id") == id
                      ? "clickdiv ms-4"
                      : " ms-4"
                  }
                >
                  <ListItem button key={id} onClick={onClick}>
                    {icon && <ListItemIcon>{icon}</ListItemIcon>}
                    <ListItemText primary={text} />
                  </ListItem>
                </div>
              );
            })}
            {done ? (
              <CircularIndeterminate className="logoutloader" />
            ) : (
              <Button
                // className="text-center ms-2"
                variant="contained"
                color="error"
                className={classes.btn}
                onClick={handleLogOut}
              >
                Logout
              </Button>
            )}
          </List>
        </div>
      </div>
    </div>
  );
  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          boxShadow: "none",
          color: "#000",
          backgroundColor: "#fff",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{
              color: "#4F6367",
              display: "flex",
              flexDirection: "row",
              flex: 1,
              justifyContent: "left",
            }}
          >
            {heading}
          </Typography>
          <Box className={classes.rightbox}>
            <Box className={classes.mdiv}>
              <Box className={classes.divx}>
                {/* {console.log(lengthx)} */}
                <Badge
                  variant="dot"
                  badgeContent=""
                  color={lengthx === 0 ? "default" : "error"}
                >
                  {" "}
                  <NotificationsIcon
                    sx={{
                      position: "relative",
                      bottom: 4,
                      // right: 3,
                      left: 5,
                      textDecoration: "none",
                      color: "#4F6367",
                      cursor: "pointer",
                    }}
                    onClick={() => setfirebasenotify(!firebasenotify)}
                  />
                </Badge>
              </Box>
            </Box>
            {
              <div className={firebasenotify ? classes.xnotify : "d-none"}>
                <FirebaseNotificationz />
              </div>
            }

            <div
              className={!menuactive ? "rightsection" : classes.uperrelative}
            >
              <Box className={!menuactive ? classes.text : classes.rightboxp}>
                <Typography
                  variant="h6"
                  noWrap
                  component="div"
                  sx={{
                    fontSize: "small",
                    position: "relative",
                    top: 17,
                    right: 45,
                    color: "#4F6367",
                    width: "100%",
                  }}
                >
                  <span className="">
                    {sessionStorage.getItem("fname")}&nbsp;
                    {sessionStorage.getItem("lname")}
                  </span>
                </Typography>
                {selectedFile == null ? (
                  <Avatar className={classes.xdiv} />
                ) : (
                  <img src={selectedFile} alt="" className={classes.xdiv} />
                )}
              </Box>
              <div className={classes.btnlogout}>
                {done ? (
                  <Logoutloader className="toplogoutloader" />
                ) : (
                  <Button
                    variant="contained"
                    color="error"
                    className={classes.btnlog}
                    onClick={handleLogOut}
                  >
                    <small>Logout</small>
                  </Button>
                )}
              </div>
            </div>
          </Box>

          <div className="hamburger_menu">
            <MenuOpenIcon
              onClick={() => {
                setmenuactive(!menuactive);
                // console.log(menuactive);
              }}
            />
          </div>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          classes={classes.drawer}
          container={container}
          variant="temporary"
          open={mobileOpen}
          color="primary"
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Notification notify={notify} setNotify={setNotify} />
    </Box>
  );
}
export default ResponsiveDrawer;
