import React, { useState, useEffect, useContext } from "react";

import { UserContext } from "../../App";

import ResponsiveDrawer from "../Drawer/drawer";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { Form } from "react-bootstrap";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { BiSearch } from "react-icons/bi";
import axios from "axios";
import Dropdown from "react-bootstrap/Dropdown";
import Notification from "../AlertNotification/Message";
import ConfirmDialog from "../AlertNotification/confirmdialog";
import ImageDialog from "../AlertNotification/image";

import CircularIndeterminate from "../Loader/Loader";
import ImportCSV from "./importCSV";
import UploadImages from "./UploadImages";
import UpdatePrice from "./UpdatePrice";
////////////
////////////
////////////
const drawerWidth = 240;
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <span className="threedots" />
  </a>
));
const columns = [
  {
    id: "img",
    label: "Image",
    minWidth: 150,
    align: "left",
  },
  {
    id: "density",
    label: "Season",
    minWidth: 30,
    align: "left",
    format: (value) => value.toFixed(2),
  },

  {
    id: "storename",
    label: "Factory",
    minWidth: 30,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "population",
    label: "Style",
    minWidth: 30,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "name",
    label: "SKU",
    minWidth: 100,
    align: "left",
  },
  {
    id: "name",
    label: "Article\u000aName",
    minWidth: 100,
    align: "left",
  },

  {
    id: "density",
    label: "Color",
    minWidth: 270,
    align: "left",
    format: (value) => value.toFixed(2),
  },
  { id: "code", label: "Stitch\u00a0", minWidth: 120, align: "left" },
  {
    id: "density",
    label: "Size1",
    minWidth: 80,
    align: "left",
    format: (value) => value.toFixed(2),
  },
  {
    id: "density",
    label: "Size2",
    minWidth: 80,
    align: "left",
    format: (value) => value.toFixed(2),
  },
  {
    id: "density",
    label: "Size3",
    minWidth: 80,
    align: "left",
    format: (value) => value.toFixed(2),
  },
  {
    id: "density",
    label: "Price1",
    minWidth: 80,
    align: "left",
    format: (value) => value.toFixed(2),
  },

  {
    id: "density",
    label: "Price2",
    minWidth: 50,
    align: "left",
    format: (value) => value.toFixed(2),
  },
  {
    id: "density",
    label: "Price3",
    minWidth: 50,
    align: "left",
    format: (value) => value.toFixed(2),
  },
  {
    id: "action",
    label: "Action",
    minWidth: 10,
    align: "left",
    format: (value) => value.toFixed(2),
  },
];

function AllProducts() {
  const navigate = useNavigate();
  const [filter, setfilter] = useState("");
  const [admins, setadmins] = useState([]);
  const [done, setdone] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [confirmDialogimg, setConfirmDialogimg] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(1000);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [tokenauth, settokenauth] = useState("");

  const GetAdmins = () => {
    axios
      .post("/get-token", {
        id: sessionStorage.getItem("token_id"),
      })
      .then((res) => {
        settokenauth(res.data.token);
        axios
          .get(`/get-products`, {
            headers: {
              "x-auth-header": res.data.token,
            },
          })
          .then((response) => {
            setadmins(response.data);
            setdone(true);
          })
          .catch((err) => console.log(err.response));
      })
      .catch((err) => console.log(err));
    // console.log(response.data);
  };

  useEffect(() => {
    sessionStorage.getItem("payloadout") != "true"
      ? navigate("/")
      : sessionStorage.setItem("id", "3");
    GetAdmins();
  }, []);

  const handleDelete = (sku) => {
    // console.log(sku);
    setdone(true);

    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    setConfirmDialogimg({
      ...confirmDialogimg,
      isOpen: false,
    });
    axios
      .post(
        "/delete-product-data",
        {
          sku: sku,
        },
        {
          headers: {
            "x-auth-header": tokenauth,
          },
        }
      )
      .then((response) => {
        setdone(false);
        console.log(response.status);
        if (response.status === 200) {
          setdone(false);
          setNotify({
            isOpen: true,
            message: "Product Deleted Successfully",
            // message: `${response.data.message}`,
            type: "success",
          });
        }
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        setdone(false);
        console.log(err.status);
        setNotify({
          isOpen: true,
          message: `${err.response}`,
          type: "error",
        });
        // console.log(err);
      });
  };
  const [csv, setcsv] = useState(true);
  const [images, setimages] = useState(true);
  const [price, setprice] = useState(true);

  const handleCSVopen = () => {
    setcsv(!csv);
    setimages(true);
    setprice(true);
  };

  const handleImagesOpen = () => {
    setcsv(true);
    setimages(!images);
    setprice(true);
  };

  const handleUpdatePricesOpen = () => {
    setcsv(true);
    setimages(true);
    setprice(!price);
  };

  const DeleteAllProducts = () => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    setdone(true);
    axios
      .post(
        "/delete-products",
        {},
        {
          headers: {
            "x-auth-header": tokenauth,
          },
        }
      )
      .then((response) => {
        console.log(response.status);
        if (response.status === 200) {
          setdone(false);
          setNotify({
            isOpen: true,
            message: "All Products Deleted Successfully",
            // message: `${response.data.message}`,
            type: "success",
          });
        }
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        setdone(false);
        console.log(err.response);
        setNotify({
          isOpen: true,
          message: `${err.response}`,
          type: "error",
        });
      });
  };
  /////IMAGE PAPUP//////
  const [isOpen, setisOpen] = useState(false);

  return (
    <div className="tableX">
      <ResponsiveDrawer heading="Product Management" />
      <Box
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
        component="main"
      >
        <Toolbar />
        {!done ? (
          <CircularIndeterminate className="allagentsLoader" />
        ) : (
          <div className="position-relative">
            <div className=" d-flex justify-content-between p-4">
              <h4 className="">All&nbsp;Products</h4>
              <div className="Accordian">
                <div className="one">
                  <div className="btncsv">
                    <button className="btn1 mb-2 px-3" onClick={handleCSVopen}>
                      <small>Import&nbsp;CSV</small>
                    </button>
                  </div>
                </div>
                <div className="mx-2">
                  <div className="btncsv">
                    <button
                      className="btn1 mb-3 px-2"
                      onClick={handleImagesOpen}
                    >
                      <small>Upload&nbsp;Image</small>
                    </button>
                  </div>
                </div>
                <div className="ImportCSV1">
                  <div className="btncsv">
                    <button
                      className="btn1 mb-3 px-2"
                      onClick={handleUpdatePricesOpen}
                    >
                      <small>Update&nbsp;Prices</small>
                    </button>
                  </div>
                </div>
                <div className="ImportCSV1">
                  <div className="ms-2">
                    <button
                      className="btnxcv mb-3 px-3"
                      onClick={() => {
                        setConfirmDialog({
                          isOpen: true,
                          title: "Delete All Products?",
                          subTitle: "You can't undo this operation",
                          onConfirm: () => {
                            DeleteAllProducts();
                          },
                        });
                      }}
                    >
                      <small>Delete&nbsp;All&nbsp;Products</small>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className={!csv ? null : "main11"}>
              <ImportCSV />
            </div>{" "}
            <div className={!images ? null : "main11"}>
              <UploadImages />
            </div>
            <div className={!price ? null : "main11"}>
              <UpdatePrice />
            </div>
            <div className="d-flex justify-content-between mx-2">
              <small className="ms-4 fw-bolder mt-1">Search&nbsp;By</small>
              <div className="position-relative w-75 me-3">
                <Form>
                  <Form.Control
                    placeholder="SKU, ArticleName or StyleName"
                    type="search"
                    className="input_field"
                    onChange={(e) => setfilter(e.target.value)}
                  />
                  <BiSearch className="search_Agent" />
                </Form>
              </div>
            </div>
            <div className="for__table mx-4 my-3">
              <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer sx={{ maxHeight: 730 }}>
                  <Table stickyHeader aria-label="sticky table bg-white">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {admins
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .filter((admin) => {
                          if (filter === "") {
                            return admins;
                          } else if (
                            (admin.firstname &&
                              admin.firstname
                                .toString()
                                .toLowerCase()

                                .includes(filter.toString())) ||
                            (admin.sku &&
                              admin.sku
                                .toString()
                                .toLowerCase()

                                .includes(filter.toString())) ||
                            (admin.ArticleName &&
                              admin.ArticleName.toString()
                                .toLowerCase()
                                .includes(filter.toLowerCase())) ||
                            (admin.StyleName &&
                              admin.StyleName.toLowerCase().includes(
                                filter.toString()
                              )) ||
                            (admin.joiningdate &&
                              admin.joiningdate

                                .toLowerCase()
                                .toString()
                                .includes(filter.toLowerCase().toString())) ||
                            (admin.description &&
                              admin.description

                                .toLowerCase()
                                .includes(filter.toLowerCase())) ||
                            (admin.description &&
                              admin.description

                                .toLowerCase()
                                .includes(filter.toLowerCase())) ||
                            (admin.joiningdate &&
                              admin.joiningdate

                                .toLowerCase()
                                .includes(filter.toLowerCase())) ||
                            (admin.storename &&
                              admin.storename
                                .toLowerCase()
                                .includes(filter.toLowerCase()))
                          ) {
                            return admins;
                          }
                        })
                        .map((admin) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={admin._id}
                            >
                              <TableCell>
                                <img
                                  src={admin.Picture}
                                  className="newimagesproducts"
                                  onClick={() => {
                                    setConfirmDialogimg({
                                      isOpen: true,
                                      title: "Delete All Products?",
                                      subTitle: admin.Picture,
                                    });
                                  }}
                                  alt=""
                                />
                              </TableCell>

                              <TableCell>{admin.Season}</TableCell>
                              <TableCell>{admin.Factory}</TableCell>

                              <TableCell>{admin.Stylename}</TableCell>
                              <TableCell>{admin.sku}</TableCell>
                              <TableCell>{admin.ArticleName}</TableCell>
                              <TableCell>
                                {admin.Material1 == "" ||
                                admin.Material1 == null
                                  ? ""
                                  : admin.Material1}

                                {admin.Material2 == "" ||
                                admin.Material2 == null
                                  ? ""
                                  : "/" + admin.Material2}

                                {admin.Material3 == "" ||
                                admin.Material3 == null
                                  ? ""
                                  : "/" + admin.Material3}
                              </TableCell>

                              <TableCell>{admin.Stitch}</TableCell>

                              <TableCell>{admin.Size1}</TableCell>
                              <TableCell>{admin.Size2}</TableCell>
                              <TableCell>{admin.Size3}</TableCell>

                              <TableCell>{admin.Price1}</TableCell>
                              <TableCell>{admin.Price2}</TableCell>
                              <TableCell>{admin.Price3}</TableCell>

                              <TableCell>
                                <div className="App">
                                  <Dropdown className="">
                                    <Dropdown.Toggle as={CustomToggle} />
                                    <Dropdown.Menu className="">
                                      <Dropdown.Item className="bg-white">
                                        {" "}
                                        <span
                                          onClick={() => {
                                            navigate(
                                              `/ViewProduct/${admin._id}`
                                            );
                                          }}
                                          className="abc"
                                        >
                                          View
                                        </span>
                                      </Dropdown.Item>
                                      <Dropdown.Item className="bg-white">
                                        {" "}
                                        <span
                                          onClick={() => {
                                            navigate(
                                              `/ProductDetails/${admin._id}`
                                            );
                                          }}
                                          className="abc"
                                        >
                                          Edit
                                        </span>
                                      </Dropdown.Item>
                                      <Dropdown.Item className="bg-white">
                                        <span
                                          className="abc"
                                          onClick={() => {
                                            // setConfirmDialog({
                                            //   isOpen: true,
                                            //   title:
                                            //     "Are you sure to delete this record?",
                                            //   subTitle:
                                            //     "You can't undo this operation",
                                            // onConfirm: () => {
                                            handleDelete(admin.sku);
                                            //   },
                                            // });
                                          }}
                                        >
                                          Delete
                                        </span>
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  className="mx-5 mt-3 px-5"
                  rowsPerPageOptions={[1000, 2000, 4000]}
                  component="div"
                  count={admins.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </div>
          </div>
        )}
      </Box>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      <ImageDialog
        confirmDialog={confirmDialogimg}
        setConfirmDialog={setConfirmDialogimg}
      />
    </div>
  );
}

export default AllProducts;
