import React, { useState, useEffect } from "react";

import Logo from "./blue.png"
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import useStyles from "./style";
import axios from "axios";
import CircularIndeterminate from "../Loader/Loader";
import Notification from "../AlertNotification/Message";


// AllFirebaseNotificationz

export default function NotifyComp({ heading }) {
  const navigate = useNavigate();
  const classes = useStyles();
  const [notification, setnotification] = useState([]);
  const [done, setdone] = useState(false);
  const [reload, setreload] = useState(false)
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const GetNotifications = () => {
    axios.post("get-token", {
      id: sessionStorage.getItem("token_id")
     }).then((res) => {
       console.log(res.data.token)
    axios.post("/get-admin-notification", {
      admin_email: sessionStorage.getItem("email")
    }, {
      
      headers: {
        
        "x-auth-header": res.data.token
      },
  }).then((response) => {

    console.log(response.data)
    setnotification(response.data);
    setdone(false);
    let filtered = response.data.filter((admin) => {
      return admin.read_status == "0";
    });
    // console.log(filtered.length);
    sessionStorage.setItem("notifylength", filtered.length)
    setdone(false);
  }).catch((err) => console.log(err.response))
  }).catch((err) => console.log(err))
  };

 
  // };
  const [tokenauth, settokenauth] = useState("")

  

  useEffect(() => {
   
      GetNotifications();
    
   
  }, [reload]);

  const DeleteNotification = (id) => {
    // console.log(id);
    // setdone(true);

    // setConfirmDialog({
    //   ...confirmDialog,
    //   isOpen: false,
    // });
    axios
      .post("/delete-single-notification", {
        message_id: id,
      }, {
      
        headers: {
          
          "x-auth-header": tokenauth && tokenauth
        },
    })
      .then((response) => {
        setdone(false);
        console.log(response.status);
        if (response.status === 200) {
          setdone(false);
          
          // setNotify({
          //   isOpen: true,
          //   message: `${response.data.message}`,
          //   type: "success",
          // });
        }
        // window.location.reload();

        GetNotifications();
        // setTimeout(() => {
        //   window.location.reload();
        // }, 1000);
      })
      .catch((err) => {
        setdone(false);
        console.log(err.status);
        // setNotify({
        //   isOpen: true,
        //   message: `${err.response}`,
        //   type: "error",
        // });
        // console.log(err);
      });
  };
  const [delid, setdelid] = useState("");
  return (
    <>
      <div className={done ? classes.notificationfireallxx : classes.notificationfireall}>
        <Typography
          sx={{
            mb: 1.5,
            fontSize: "15px",
            paddingLeft: "15px",
            paddingTop: "10px",
          }}
          color="#4F6367"
        >
          {/* <span className="text-danger">All Notifications</span> */}
        </Typography>
        {done ? (
     <CircularIndeterminate className="notificationloader" />
   ) :
        notification.length == 0 ? (
          <div className="">
            <small style={{ color: "black", marginLeft: 17 }}>
              No New Notifications
            </small>
          </div>
        ) : (
          notification.map((item) => (
            <>
              <div className={ delid == item.message_id ? "d-none"  : "d-flex flex-row forBorder"}>
                <div
                  className={
                    item.read_status == 1
                      ? classes.notificationtext
                      : classes.notificationread
                  }
                >
                  <div className={ delid == item.message_id ? "d-none"  : "d-flex justify-content-around flex-row "} key={item.message_id}>
                    <Typography sx={{ mt: 1 }} color="text.secondary">
                      <img src={Logo} alt="" className="imagx"/>
                    </Typography>
                    <small className="text-left ps-3 pe-1">
                      {" "}
                      {item.content.body}
                    </small>
                  </div>
                  <div className={ delid == item.message_id ? "d-none"  : "App "}>
                      <small onClick={() => {
                            DeleteNotification(item.message_id)
                            setdelid(item.message_id)
                          }} className="text-danger">X</small>
                     {/* <Dropdown className="">
                      <Dropdown.Toggle as={CustomToggle} />
                      <Dropdown.Menu className="">
                        <Dropdown.Item className="bg-white">
                          {" "}
                          <span
                            onClick={() => handleClick(item.message_id)}
                            className="abc"
                          >
                            <small>Read</small>
                          </span>
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="bg-white"
                          onClick={() => {
                            DeleteNotification(item.message_id);
                          }}
                        >
                          <span className="abc">
                            <small> Delete</small>
                          </span>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>  */}
                   </div> 
                </div>
             
              </div>
              
            </>
          ))
        )
          
                  }
                  
        <div>
        {/* {notification.length > 0 ?  <div className="d-flex justify-content-end">
            <small style={{ color: "red", marginLeft: 12 }}>
              See All Notification
            </small>
          </div> : <small></small> } */}
        </div>
   
        <Notification notify={notify} setNotify={setNotify} />
        
      </div>
      
    </>
  );
}
