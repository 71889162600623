/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import ResponsiveDrawer from "../Drawer/drawer";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { Form, Row, Col } from "react-bootstrap";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import { BiSearch } from "react-icons/bi";
import Notification from "../AlertNotification/Message";
import ConfirmDialog from "../AlertNotification/confirmdialog";
import CircularIndeterminate from "../Loader/Loader";
////////////
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import "react-datepicker/dist/react-datepicker.css";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
//
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import ActiveCustomerOrder from "./ShowCustomerStatus/ActiveCustomerOrder";
import CancelOrderStatus from "./ShowCustomerStatus/CancelOrder";
import CompleteCustomerOrder from "./ShowCustomerStatus/CompletedOrder";
////////////
////////////
const drawerWidth = 240;
const columns = [
  {
    id: "img",
    label: "Store Name",
    minWidth: 100,
    align: "left",
  },

  {
    id: "name",
    label: "Order Number",
    minWidth: 130,
    align: "left",
  },

  {
    id: "population",
    label: "Order Status",
    minWidth: 140,
    align: "left",
  },
];
//
//
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
//////////////////////
function CustomerOrderStatus() {
  const ForSorting = () => {};
  const [show, setShow] = useState(true);
  const SortBy = () => {
    setShow(!show);
    setAnchorEl(null);
  };
  //
  const [orderheading, setorderheading] = useState("All\u00a0Orders");
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  //
  const [filter, setfilter] = useState("");
  const [customers, setcustomers] = useState([]);
  const [done, setdone] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(2000);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  //////////////////////////
  //////////////////////////
  const [StartDate, setStartDate] = useState("");
  const [EndDate, setEndDate] = useState("");

  const GetCustomers = async () => {
    StartDate == "" && EndDate == ""
      ? alert("fill data")
      : axios
          .post(`/customer-order-status-report`, {
            StartDate: StartDate && StartDate,
            EndDate: EndDate && EndDate,
          }, {
      
            headers: {
              
              "x-auth-header": tokenauth && tokenauth
            },
        })
          .then((response) => {
            setcustomers(response.data);
            setdone(false);
            // console.log(response.data);
          })
          .catch((err) => {
            console.log(err);
          });
  };
  ////////////////////
  ////////////////////
  ////////////////////
  const handlesubmit = (e) => {
    e.preventDefault();
    StartDate == "" && EndDate == "" ? alert("fill data") : GetCustomers();
    setdone(true);
  };
  const [tokenauth, settokenauth] = useState("")

 useEffect(() => {
    axios.post("get-token", {
     id: sessionStorage.getItem("token_id")
    }).then((response) => {
     settokenauth(response.data.token) 
    }).catch(err => console.log(err))
   }, [])
  ///////////////////////////////////
  ///////////////////////////////////
  ///////////////////////////////////
  const [activeorders, setactiveorders] = useState(false);
  const [completeorders, setcompleteorders] = useState(false);
  const [cancelledorders, setcancelledorders] = useState(false);
  const [allorders, setallorders] = useState(true);
  ///////////////////////////////////
  const HandleAllOrders = () => {
    setorderheading("All\u00a0Orders");
    setallorders(true);
    setactiveorders(false);
    setcompleteorders(false);
    setcancelledorders(false);
    handleClose();
  };
  const HandleCompleteOrders = () => {
    setorderheading("Complete\u00a0Orders");
    setcompleteorders(true);
    setallorders(false);
    setactiveorders(false);
    setcancelledorders(false);
    handleClose();
  };
  const HandleActiveOrders = () => {
    setorderheading("Active\u00a0Orders");
    setactiveorders(true);
    setallorders(false);
    setcompleteorders(false);
    setcancelledorders(false);
    handleClose();
  };
  const HandleCancelOrders = () => {
    setorderheading("Cancel\u00a0Orders");
    setcancelledorders(true);
    setallorders(false);
    setactiveorders(false);
    setcompleteorders(false);
    handleClose();
  };
  return (
    <div className="tableX">
      <ResponsiveDrawer heading="Reports" />
      <Box
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
        component="main"
      >
        <Toolbar />

        <div className="position-relative mt-5">
          {/*  */}
          {/*  */}
          {/* <Row className="mx-3">
              <Col lg={3} md={3} sm={4}> */}
          <div className="d-flex justify-content-between ps-3 pe-4">
            <div className="">
              <Button
                id="demo-customized-button"
                aria-controls="demo-customized-menu"
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                // variant="contained"
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon style={{ color: "#f00e0e" }} />}
              >
                <span className="fw-bolder text-dark">
                  Store&nbsp;Order&nbsp;Status
                </span>
              </Button>
              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                  "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <MenuItem
                  disableRipple
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  onClick={() => {
                    navigate("/AllStyleSold");
                  }}
                >
                  All&nbsp;Styles&nbsp;Sold
                </MenuItem>
                <MenuItem
                  disableRipple
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  onClick={() => {
                    navigate("/TotalSales");
                  }}
                >
                  Total&nbsp;Sales&nbsp;by&nbsp;Date
                </MenuItem>
                <MenuItem
                  disableRipple
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  onClick={() => {
                    navigate("/SalesByStyleFactory");
                  }}
                >
                  Total&nbsp;Sales&nbsp;by&nbsp;StyleFactory
                </MenuItem>
                <MenuItem
                  disableRipple
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  onClick={() => {
                    navigate("/StoreOrderStatus");
                  }}
                >
                  Store&nbsp;Order&nbsp;Status
                </MenuItem>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem
                  disableRipple
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  onClick={() => {
                    navigate("/SalesByStore");
                  }}
                >
                  Total&nbsp;Sales&nbsp;by&nbsp;Store&nbsp;Name
                </MenuItem>
                <MenuItem
                  disableRipple
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  onClick={() => {
                    navigate("/FactoryStyleByStoreName");
                  }}
                >
                  Factory&nbsp;Style&nbsp;by&nbsp;Store&nbsp;Name
                </MenuItem>
                <MenuItem
                  disableRipple
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  onClick={() => {
                    navigate("/TotalPairsByStyle");
                  }}
                >
                  Total&nbsp;Pairs&nbsp;by&nbsp;Style&nbsp;Name
                </MenuItem>
              </StyledMenu>
            </div>
            <div className="w-50">
              <div className="position-relative">
                <Form>
                  <Form.Control
                    placeholder="Store Name, Order Number"
                    type="search"
                    className="input_field"
                    onChange={(e) => setfilter(e.target.value)}
                  />
                  <BiSearch className="search_Agent1" />
                </Form>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between mt-3 resclass">
            <div className="EndDateWidth for_DateTime ms-4 mb-3">
              <Form onSubmit={handlesubmit}>
                <div className="d-flex">
                  <div>
                    <Form.Control
                      placeholder="date/month/year"
                      className=""
                      type="date"
                      label="Start Date"
                      name="startdate"
                      required
                      onChange={(e) => {
                        let arr = e.target.value.split("-");
                        setStartDate(arr[2] + "/" + arr[1] + "/" + arr[0]);
                      }}
                    />
                  </div>
                  <div>
                    <Form.Control
                      placeholder="date/month/year"
                      type="date"
                      className="mx-2"
                      label="End Date"
                      name="enddate"
                      required
                      onChange={(e) => {
                        let arr = e.target.value.split("-");
                        setStartDate(arr[2] + "/" + arr[1] + "/" + arr[0]);
                      }}
                    />
                  </div>
                  <div>
                    <button type="submit" className="CSV mx-3 py-2">
                      Search
                    </button>
                  </div>
                </div>
              </Form>
            </div>
            <div className="d-flex">
              <div className="me-3">
                <p className="forDropDown" onClick={() => SortBy()}>
                  Sort&nbsp;by
                  <KeyboardArrowDownIcon style={{ color: "#f00e0e" }} />
                </p>
                <div className={show ? "sort" : "sort-by"}>
                  <ul>
                    <li onClick={HandleCompleteOrders}>Completed</li>
                    <li onClick={HandleActiveOrders}>Active</li>
                    <li onClick={HandleCancelOrders}>Cancelled</li>
                    {/* <li onClose={handleClose} onClick={HandleAllOrders}>
                      All Orders
                    </li> */}
                  </ul>
                </div>
              </div>
              {/* </Col>
              <Col lg={2} md={6} sm={6}> */}
              <div>
                <ReactHTMLTableToExcel
                  id="test-table-xls-button"
                  className="me-4 py-2 px-3 ExcelDownlad download-table-xls-button"
                  table="table-to-xls"
                  filename="tablexls"
                  sheet="tablexls"
                  buttonText="Export&nbsp;CSV"
                  size="small"
                />
              </div>
            </div>
          </div>
          {/*  */}
          {/*  */}
          <div className=" ms-4 mb-3">{orderheading}</div>
          {done ? (
            <CircularIndeterminate className="reportsales" />
          ) : (
            <div
              className={allorders ? "jhhgg" : "d-none"}
              //
            >
              <div className="for__table m-4">
                <Paper sx={{ width: "100%", overflow: "hidden" }}>
                  <TableContainer sx={{ maxHeight: 730 }}>
                    <Table
                      stickyHeader
                      aria-label="sticky table"
                      id="table-to-xls"
                    >
                      <TableHead>
                        <TableRow>
                          {columns.map((column) => (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{ minWidth: column.minWidth }}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {customers.length > 0 ? (
                          customers
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .filter((admin) => {
                              if (filter === "") {
                                return customers;
                              } else if (
                                (admin.StoreName &&
                                  admin.StoreName.toString()
                                    .toLowerCase()
                                    .includes(
                                      filter.toString().toLowerCase()
                                    )) ||
                                (admin.OrderNumber &&
                                  admin.OrderNumber.toString()
                                    .toLowerCase()
                                    .includes(filter.toString().toLowerCase()))
                              ) {
                                return customers;
                              }
                            })
                            .map((admin) => {
                              return (
                                <TableRow
                                  hover
                                  role="checkbox"
                                  tabIndex={-1}
                                  key={admin._id}
                                >
                                  <TableCell>{admin.StoreName}</TableCell>
                                  <TableCell>{admin.OrderNumber}</TableCell>
                                  <TableCell>
                                    {admin.OrderStatus == "1" ? (
                                      <div style={{ color: "green" }}>
                                        <small>Active</small>{" "}
                                      </div>
                                    ) : admin.OrderStatus == "2" ? (
                                      <div style={{ color: "blue" }}>
                                        <small>Completed</small>{" "}
                                      </div>
                                    ) : admin.OrderStatus == "0" ? (
                                      <div style={{ color: "red" }}>
                                        <small> Canceled </small>{" "}
                                      </div>
                                    ) : (
                                      <div style={{ color: "red" }}>
                                        <small> Not Available </small>{" "}
                                      </div>
                                    )}
                                  </TableCell>
                                </TableRow>
                              );
                            })
                        ) : (
                          <p className="text-center mt-5">No Data</p>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    className="mx-5 mt-3 px-5"
                    rowsPerPageOptions={[2000, 5000, 8000]}
                    component="div"
                    count={customers.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>
              </div>
            </div>
          )}
        </div>
      </Box>
      <div className={cancelledorders ? "jjhj" : "d-none"}>
        <CancelOrderStatus />
      </div>
      <div className={completeorders ? "jjhj" : "d-none"}>
        <CompleteCustomerOrder />
      </div>
      <div className={activeorders ? "jjhj" : "d-none"}>
        <ActiveCustomerOrder />
      </div>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </div>
  );
}

export default CustomerOrderStatus;
