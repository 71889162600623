import React, { useState, useEffect, useContext } from "react";

import { UserContext } from "../../App";

import axios from "axios";
import { Form, Col, Row } from "react-bootstrap";
import ResponsiveDrawer from "../Drawer/drawer";
import { useNavigate, useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Notification from "../AlertNotification/Message";
import CircularIndeterminate from "../Loader/Loader";

const drawerWidth = 240;
const UpdateCustomer = () => {
  const { mail } = useParams();
  // console.log(mail);

  const navigate = useNavigate();
  // const [selectedFile, setSelectedFile] = useState({
  //   file: [],
  //   filepreview: null,
  // });
  const [upload, setupload] = useState(false);
  const [size, setsize] = useState(false);
  ////////
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [emailsecond, setEmailsecond] = useState("");
  const [cell, setCell] = useState("");
  const [celltwo, setCelltwo] = useState("");

  const [storename, setStorename] = useState("");
  const [phone, setPhone] = useState("");
  const [address1, setaddress1] = useState("");
  const [address2, setaddress2] = useState("");
  const [city, setcity] = useState("");
  const [state, setstate] = useState("");
  const [zipcode, setzipcode] = useState("");
  const [cid, setcid] = useState("");
  const [street, setstreet] = useState("");
  const [country, setCountry] = useState("");
  /////////
  const [pfname, setPfname] = useState("");
  const [plname, setPlname] = useState("");
  const [pemail, setPemail] = useState("");
  const [pemailsecond, setPemailsecond] = useState("");
  const [pphone, setPphone] = useState("");

  const [pcell, setPcell] = useState("");
  ////
  const [ocfname, setOcfname] = useState("");
  const [oclname, setOclname] = useState("");
  const [ocemail, setOcemail] = useState("");
  const [ocemailsecond, setOcemailsecond] = useState("");
  const [ocphone, setOcphone] = useState("");

  const [occell, setOccell] = useState("");
  const [baddress1, setBaddress1] = useState("");
  const [baddress2, setBaddress2] = useState("");
  const [bcity, setBcity] = useState("");
  const [bstate, setBstate] = useState("");
  const [bzipcode, setBzipcode] = useState("");
  const [bstreet, setBstreet] = useState("");
  const [bcountry, setBcountry] = useState("");
  const [done, setdone] = useState(false);
  const [showpartner, setshowpartner] = useState(false);
  const [showcontact, setshowcontact] = useState(false);

  const ShowPartner = () => {
    setshowpartner(!showpartner);
  };
  const ShowOtherContact = () => {
    setshowcontact(!showcontact);
  };
  // ///////
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  // const HandleChange = (event) => {
  //   if (event.target.files[0].size > 100 * 1024) {
  //     setsize(true);
  //   } else if (event.target.files[0].size <= 100 * 1024) {
  //     setSelectedFile({
  //       ...selectedFile,
  //       file: event.target.files[0],
  //       filepreview: URL.createObjectURL(event.target.files[0]),
  //     });
  //     setsize(false);
  //     setupload(true);
  //   }
  // };
  // API calling
  const UpdateCustomers = () => {
    const formData = new FormData();
    formData.append("customerid", cid);
    formData.append("firstname", fname);
    formData.append("lastname", lname);
    formData.append("email_1", email);
    formData.append("email_2", emailsecond);
    formData.append("StoreName", storename);
    formData.append("cell_1", cell);
    formData.append("cell_2", celltwo);
    formData.append("StorePhone", phone);

    ///formData.append("firstname", fname);

    formData.append("sc_firstname", pfname);
    formData.append("sc_lastname", plname);

    formData.append("sc_cell_2", pcell);
    formData.append("sc_email", pemail);
    formData.append("sc_OfficePhone", pemailsecond);
    formData.append("sc_cell_1", pphone);
    formData.append("tc_firstname", ocfname);
    formData.append("tc_lastname", oclname);
    formData.append("tc_email", ocemail);
    formData.append("tc_OfficePhone", ocemailsecond);

    formData.append("tc_cell_1", ocphone);
    formData.append("tc_cell_2", occell);

    ////
    formData.append("s_address1", address1);
    formData.append("s_address2", address2);

    formData.append("s_street", street);
    formData.append("s_postcode", zipcode);
    formData.append("s_city", city);
    formData.append("s_state", state);
    formData.append("s_country", country);
    ///////

    formData.append("b_address1", baddress1);
    formData.append("b_address2", baddress2);
    formData.append("b_street", bstreet);

    formData.append("b_postcode", bzipcode);
    formData.append("b_city", bcity);
    formData.append("b_state", bstate);
    formData.append("b_country", bcountry);
    axios
      .post(`/update-customer/${cid}`, formData, {
        headers: {
          "x-auth-header": tokenauth || sessionStorage.getItem("tokan"),
        },
      })
      .then((response) => {
        // console.log(response.status);
        if (response.status === 200) {
          setdone(false);
          setNotify({
            isOpen: true,
            message: "Updated Succcessfully",
            type: "success",
          });
        }
        setTimeout(() => {
          // navigate("/AllCustomers");
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        setdone(false);
        console.log(err.response);
        setNotify({
          isOpen: true,
          message: `${err.response.data.message}`,
          type: "error",
        });
        console.log(err);
      });
  };
  const HandleSubmit = (e) => {
    e.preventDefault();
    setdone(true);
    UpdateCustomers();
  };
  const [tokenauth, settokenauth] = useState("");

  const getCustomers = () => {
    axios
      .post("/get-token", {
        id: sessionStorage.getItem("token_id"),
      })
      .then((res) => {
        settokenauth(res.data.token);
        axios
          .post(
            `/get-customers`,
            {},
            {
              headers: {
                "x-auth-header": res.data.token,
              },
            }
          )
          .then((response) => {
            setdone(true);
            console.log(response.data);
            console.log( response.data
              .filter((customers) => {
                return mail == customers._id;
              }));
            response.data
              .filter((customers) => {
                return mail == customers._id;
              })
              .map((customer) => {
                // setSelectedFile(customer.image);
                ///
                setFname(customer.firstname);
                setLname(customer.lastname);
                setEmail(customer.email_1);
                setEmailsecond(customer.email_2);
                setCell(customer.cell_1);
                setCelltwo(customer.cell_2);
                setStorename(customer.StoreName);
                setPhone(customer.StorePhone);
                setaddress1(customer.s_address1);
                setaddress2(customer.s_address2);
                setcity(customer.s_city);
                setstate(customer.s_state);
                setzipcode(customer.s_postcode);
                setcid(customer.customerid);
                setstreet(customer.s_street);
                setCountry(customer.s_country);
                setPfname(customer.sc_firstname);
                setPlname(customer.sc_lastname);
                setPemail(customer.sc_email);
                setPemailsecond(customer.sc_OfficePhone);
                setPphone(customer.sc_cell_1);
                setPcell(customer.sc_cell_2);
                setOcfname(customer.tc_firstname);
                setOclname(customer.tc_lastname);
                setOcemail(customer.tc_email);
                setOcemailsecond(customer.tc_OfficePhone);
                setOcphone(customer.tc_cell_1);
                setOccell(customer.tc_cell_2);
                setBaddress1(customer.b_address1);
                setBaddress2(customer.b_address2);
                setBcity(customer.b_city);
                setBstate(customer.b_state);
                setBzipcode(customer.b_postcode);
                setBstreet(customer.b_street);
                setBcountry(customer.b_country);

                ////
              });
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    sessionStorage.getItem("payloadout") != "true"
      ? navigate("/")
      : getCustomers();
  }, []);

  return (
    <div>
      <ResponsiveDrawer heading="Customer Management" />
      <Box
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
        component="main"
      >
        <Toolbar />
        {!done ? (
          <CircularIndeterminate className="AddAgentloader" />
        ) : (
          <div className="for_form my-5 mx-4 margnbottom">
            <Form onSubmit={HandleSubmit}>
              <div className=" d-flex  justify-content-between mt-5  pt-3 pr-3 pb-3 btnz">
                <h5 className="">Update&nbsp;Customer</h5>
                <div className="btnp">
                  <button
                    className="btn1 mx-2"
                    onClick={() => navigate("/AllCustomers")}
                  >
                    Cancel
                  </button>
                  <button className="btn2" type="submit">
                    Update
                  </button>
                </div>
              </div>
              {/* Form */}
              {/* <Row xs="1" sm="1" md="2" lg="3" xl="3" className="mt-3">
                <Col>
                  <label htmlFor="icon-button-file">
                    <Input
                      accept="image/*"
                      id="icon-button-file"
                      type="file"
                      // value={selectedFile}
                      onChange={HandleChange}
                      
                    />

                    {!upload ? (
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                      >
                        <div className="addicon">
                          <AddIcon className="icon" />
                        </div>
                      </IconButton>
                    ) : (
                      <img
                        className="previewimg mb-3"
                        src={selectedFile.filepreview}
                        alt="Image"
                      />
                    )}

                    <br />
                  </label>

                  <div className={size ? "sizeshow" : "sizehide"}>
                    <small>File size excedded than 100KB</small>
                  </div>
                </Col>
              </Row> */}
              <Row xs={1} sm={1} md={2} lg={3} xl={3} className="mt-3">
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Customer ID</Form.Label>
                    <Form.Control
                      type="text"
                      // //="Customer id"
                      value={cid}
                      onChange={(e) => setcid(e.target.value)}
                      readOnly
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type="text"
                      //="First name"
                      value={fname}
                      onChange={(e) => setFname(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      //="Last name"
                      value={lname}
                      onChange={(e) => setLname(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row xs={1} sm={1} md={2} lg={3} xl={3}>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Store Name</Form.Label>
                    <Form.Control
                      type="text"
                      //="Store name"
                      value={storename}
                      onChange={(e) => setStorename(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Email 1</Form.Label>
                    <Form.Control
                      type="text"
                      //="Enter email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Email 2</Form.Label>
                    <Form.Control
                      type="text"
                      //="Enter other email"
                      value={emailsecond}
                      //
                      onChange={(e) => setEmailsecond(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row xs={1} sm={1} md={2} lg={3} xl={3}>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Store Phone</Form.Label>
                    <Form.Control
                      type="text"
                      //="store phone"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Cell 1</Form.Label>
                    <Form.Control
                      type="text"
                      //="Cell 1"
                      value={cell}
                      onChange={(e) => setCell(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Cell 2</Form.Label>
                    <Form.Control
                      type="text"
                      //="Cell 2"
                      value={celltwo}
                      onChange={(e) => setCelltwo(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>

              {/* /////////// ADD PARTNER  //////////////// */}
              <Row xs="1" sm="1" md="2" lg="3"></Row>
              <div className=" d-flex  justify-content-between mt-3">
                <div className="d-flex flex-row  mb-4">
                  <Form.Check
                    aria-label="option 1"
                    className="checkmargin"
                    onChange={() => ShowPartner()}
                  />
                  <small className="ms-3 mt-1">2nd Contact</small>
                </div>
              </div>
              <div className={!showpartner ? "showpartnercontact" : null}>
                <Row className="mt-3">
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        type="text"
                        //="First name"
                        value={pfname}
                        onChange={(e) => setPfname(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        type="text"
                        //="Last name"
                        value={plname}
                        onChange={(e) => setPlname(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="text"
                        //="Enter email"
                        value={pemail}
                        onChange={(e) => setPemail(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Office</Form.Label>
                      <Form.Control
                        type="text"
                        value={pemailsecond}
                        //="office no"
                        //
                        onChange={(e) => setPemailsecond(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Cell 1</Form.Label>
                      <Form.Control
                        type="text"
                        //="cell 1"
                        value={pphone}
                        onChange={(e) => setPphone(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Cell 2</Form.Label>
                      <Form.Control
                        type="text"
                        //="cell 2"
                        value={pcell}
                        onChange={(e) => setPcell(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>
              {/* ///////////////  ADD OTHER CONTACT ///////////////////// */}
              {/* ///////////////  ADD OTHER CONTACT ///////////////////// */}
              <div className=" d-flex  justify-content-between mt-3">
                <div className="d-flex flex-row  mb-4">
                  <Form.Check
                    aria-label="option 1"
                    className="checkmargin"
                    onChange={() => ShowOtherContact()}
                  />
                  <small className="ms-3 mt-1">3rd Contact</small>
                </div>
                <div></div>
              </div>
              <div className={!showcontact ? "showpartnercontact" : null}>
                <Row className="">
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        type="text"
                        //="First name"
                        value={ocfname}
                        onChange={(e) => setOcfname(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        type="text"
                        //="Last name"
                        value={oclname}
                        onChange={(e) => setOclname(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="text"
                        //="Enter email"
                        value={ocemail}
                        onChange={(e) => setOcemail(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Office</Form.Label>
                      <Form.Control
                        type="text"
                        //="office no"
                        //
                        value={ocemailsecond}
                        onChange={(e) => setOcemailsecond(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Cell 1</Form.Label>
                      <Form.Control
                        type="text"
                        //="cell 1"
                        value={ocphone}
                        onChange={(e) => setOcphone(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Cell 2</Form.Label>
                      <Form.Control
                        type="text"
                        //="cell 2"
                        value={occell}
                        onChange={(e) => setOccell(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>
              {/* ///
              ///
              // */}

              {/* //////////// SHIPING ADRESS ////////////// */}
              <Row xs="1" sm="1" md="2" lg="3"></Row>

              <div className=" d-flex  justify-content-between mb-4 mt-4">
                <h5 className="">Shipping&nbsp;Address</h5>
              </div>
              <Row xs={1} sm={1} md={2} lg={3} xl={3}>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Street, Adress 1</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Street, Adress 1"
                      //="adress 1"
                      value={address1}
                      onChange={(e) => setaddress1(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Adress 2</Form.Label>
                    <Form.Control
                      type="text"
                      //="address 2"
                      value={address2}
                      onChange={(e) => setaddress2(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Street</Form.Label>
                    <Form.Control
                      type="text"
                      //="street"
                      value={street}
                      onChange={(e) => setstreet(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row xs={1} sm={1} md={2} lg={3} xl={3}>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Zip Code</Form.Label>
                    <Form.Control
                      type="text"
                      //="zip code"
                      value={zipcode}
                      onChange={(e) => setzipcode(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      type="text"
                      //="city"
                      value={city}
                      onChange={(e) => setcity(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>State</Form.Label>
                    <Form.Control
                      type="text"
                      //="state"
                      value={state}
                      onChange={(e) => setstate(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Country</Form.Label>
                    <Form.Control
                      type="text"
                      //="country"
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row xs={1} sm={1} md={2} lg={3} xl={3}></Row>
              {/* //////////// Billing ADRESS ////////////// */}
              <Row xs="1" sm="1" md="2" lg="3"></Row>

              <div className=" d-flex  justify-content-between mb-4  mt-4">
                <h5 className="">Billing&nbsp;Address</h5>
              </div>
              <Row xs={1} sm={1} md={2} lg={3} xl={3}>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label> Adress 1</Form.Label>
                    <Form.Control
                      type="text"
                      //="adress 1"
                      value={baddress1}
                      onChange={(e) => setBaddress1(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Adress 2</Form.Label>
                    <Form.Control
                      type="text"
                      //="address 2"
                      value={baddress2}
                      onChange={(e) => setBaddress2(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Street</Form.Label>
                    <Form.Control
                      type="text"
                      //="street"
                      value={bstreet}
                      onChange={(e) => setBstreet(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row xs={1} sm={1} md={2} lg={3} xl={3}>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Zip Code</Form.Label>
                    <Form.Control
                      type="text"
                      //="zip code"
                      value={bzipcode}
                      onChange={(e) => setBzipcode(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      type="text"
                      //="city"
                      value={bcity}
                      onChange={(e) => setBcity(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>State</Form.Label>
                    <Form.Control
                      type="text"
                      //="state"
                      value={bstate}
                      onChange={(e) => setBstate(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col className="margnbottom">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Country</Form.Label>
                    <Form.Control
                      type="text"
                      //="country"
                      value={bcountry}
                      onChange={(e) => setBcountry(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row xs={1} sm={1} md={2} lg={3} xl={3}></Row>
            </Form>
          </div>
        )}
      </Box>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};

export default UpdateCustomer;
