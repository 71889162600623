// For Firebase JS SDK v7.20.0 and later, measurementId is optional

/////

import firebase from "firebase/compat/app";
import "firebase/compat/messaging";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBIIamclkbYkDhClgp8EArequ-v9NLacsM",
  authDomain: "kinderland-inc.firebaseapp.com",
  projectId: "kinderland-inc",
  storageBucket: "kinderland-inc.appspot.com",
  messagingSenderId: "942841887050",
  appId: "1:942841887050:web:c3881c26876f14764269b3",
  measurementId: "G-WNW8WN0CW6"
};

firebase.initializeApp(firebaseConfig);

const messaging = firebase.messaging();

const { REACT_APP_VAPID_KEY } = process.env;
const publicKey = REACT_APP_VAPID_KEY;

export const getToken = async (setTokenFound) => {
  let currentToken = "";

  try {
    currentToken = await messaging.getToken({ vapidKey: publicKey });
    if (currentToken) {
      setTokenFound(true);
    } else {
      setTokenFound(false);
    }
  } catch (error) {
    console.log("An error occurred while retrieving token. ", error);
  }

  return currentToken;
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });
