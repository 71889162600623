/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, Component } from "react";
import ResponsiveDrawer from "../Drawer/drawer";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { Form, Row, Col } from "react-bootstrap";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import { BiSearch } from "react-icons/bi";
import Notification from "../AlertNotification/Message";
import ConfirmDialog from "../AlertNotification/confirmdialog";
import CircularIndeterminate from "../Loader/Loader";
import DropdownButton from "react-bootstrap/DropdownButton";
////////////
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
// import required react-datepicker styling file
import "react-datepicker/dist/react-datepicker.css";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import DatePicker from "react-datepicker";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import arrowUp from "@fortawesome/fontawesome-svg-core";
import "react-datepicker/dist/react-datepicker.css";
import { Dropdown } from "semantic-ui-react";
////////////
////////////
const drawerWidth = 240;
const columns = [
  {
    Key: "density",
    label: "Factory Style",
    dataIndex: "FactoryStyle",
    minWidth: 100,
    align: "left",
    format: (value) => value.toFixed(2),
  },
  {
    id: "name",
    label: "Sku",
    field: "Sku",
    headerName: "Sku",
    minWidth: 130,
    align: "left",
  },

  {
    id: "population",
    label: "3 String Colors",
    minWidth: 140,
    align: "left",
    fontWeight: 900,
  },
  {
    id: "population",
    label: "Total Pairs Sold",
    minWidth: 140,
    align: "left",
    fontWeight: 900,
  },
  // {
  //   field: "FactoryStyle",
  //   headerName: "FactoryStyle",
  //   description: "This column has a value getter and is not sortable.",
  //   sortable: false,
  //   width: 160,
  //   valueGetter: (params) =>
  //     `${params.getValue(params.id, "FactoryStyle") || ""} ${
  //       params.getValue(params.id, "FactoryStyle") || ""
  //     }`,
  // },
];
const columnsTwo = [
  {
    id: "density",
    label: "",
    minWidth: 350,
    align: "left",
    fontWeight: "bolder",
    format: (value) => value.toFixed(2),
  },

  {
    id: "density",
    label: "",
    minWidth: 100,
    align: "left",
    fontWeight: "bolder",
    format: (value) => value.toFixed(2),
  },
  // {
  //   id: "density",
  //   label: "Total",
  //   minWidth: 100,
  //   align: "left",
  //   fontWeight: "bolder",
  //   color: "red",
  //   format: (value) => value.toFixed(2),
  // },
];
const columnsThree = [
  {
    id: "density",
    label: "",
    minWidth: 100,
    align: "left",
    format: (value) => value.toFixed(2),
  },
  {
    id: "name",
    label: "",
    minWidth: 130,
    align: "left",
  },

  {
    id: "population",
    label: "",
    minWidth: 140,
    align: "left",
    fontWeight: 900,
  },
  {
    id: "population",
    label: "",
    minWidth: 140,
    align: "left",
    fontWeight: 900,
  },
];
//
//
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
let newcustomers = [];
function AllStyleSold() {
  const [data, setData] = useState([]);
  const [sortType, setSortType] = useState("albums");
  const [show, setShow] = useState(true);
  const SortBy = () => {
    setShow(!show);
  };
  const [tokenauth, settokenauth] = useState("");

  useEffect(() => {
    axios
      .post("get-token", {
        id: sessionStorage.getItem("token_id"),
      })
      .then((response) => {
        settokenauth(response.data.token);
      })
      .catch((err) => console.log(err));
  }, []);
  // -------//
  const asendsort = () => {
    // setsorat(false);
// console.log(customers);
    const finalArray = [];

    customers.sort((a, b) => {
      const num1 = a.Style.split("-")[0];
      const num2 = b.Style.split("-")[0];
      if (num1 < num2) return 1;
      if (num1 > num2) return -1;
      return 0;
    });

    customers.forEach((item) => {
      if (finalArray.length == 0) {
        const tempArray = customers.filter((inner_item) => {
          return inner_item.Style.split("-")[0] == item.Style.split("-")[0];
        });
        finalArray.push(tempArray);
      } else {
        const findData = finalArray.find((a) => {
          return a[0].Style.split("-")[0] == item.Style.split("-")[0];
        });

        if (!findData) {
          const tempArray = customers.filter((inner_item) => {
            return inner_item.Style.split("-")[0] == item.Style.split("-")[0];
          });
          finalArray.push(tempArray);
        }
      }
    });

    finalArray.forEach((item) => {
      item.sort((a, b) => {
        const num1 = a.Style.split("-")[1];
        const num2 = b.Style.split("-")[1];
        if (num1 < num2) return 1;
        if (num1 > num2) return -1;
        return 0;
      });
    });

    const finalarr = [];

    // console.log(finalArray);
    finalArray.map((data) => data.map((odata) => finalarr.push(odata)));
    // console.log(finalarr);
    setcustomers(finalarr);
    // setcustomers(finalarr);
    // console.log(customers);

    // customers.sort((a, b) => {
    //   let fa = a[0].Style.split("-")[1].toLowerCase(),
    //     fb = b[0].Style.split("-")[1].toLowerCase();

    //   if (fa < fb) {
    //     return -1;
    //   }
    //   if (fa > fb) {
    //     return 1;
    //   }
    //   return 0;
    // });
  };
  const desendsort = () => {
    // setsorat(false);

    const finalArray = [];

    customers.sort((a, b) => {
      const num1 = a.Style.split("-")[0];
      const num2 = b.Style.split("-")[0];
      if (num1 < num2) return -1;
      if (num1 > num2) return 1;
      return 0;
    });

    customers.forEach((item) => {
      if (finalArray.length == 0) {
        const tempArray = customers.filter((inner_item) => {
          return inner_item.Style.split("-")[0] == item.Style.split("-")[0];
        });
        finalArray.push(tempArray);
      } else {
        const findData = finalArray.find((a) => {
          return a[0].Style.split("-")[0] == item.Style.split("-")[0];
        });

        if (!findData) {
          const tempArray = customers.filter((inner_item) => {
            return inner_item.Style.split("-")[0] == item.Style.split("-")[0];
          });
          finalArray.push(tempArray);
        }
      }
    });

    finalArray.forEach((item) => {
      item.sort((a, b) => {
        const num1 = a.Style.split("-")[1];
        const num2 = b.Style.split("-")[1];
        if (num1 < num2) return -1;
        if (num1 > num2) return 1;
        return 0;
      });
    });

    const finalarr = [];

    // console.log(finalArray);
    finalArray.map((data) => data.map((odata) => finalarr.push(odata)));
    // console.log(finalarr);
    setcustomers(finalarr);
    // setcustomers(finalarr);
    // console.log(customers);

    // customers.sort((a, b) => {
    //   let fa = a[0].Style.split("-")[1].toLowerCase(),
    //     fb = b[0].Style.split("-")[1].toLowerCase();

    //   if (fa < fb) {
    //     return -1;
    //   }
    //   if (fa > fb) {
    //     return 1;
    //   }
    //   return 0;
    // });
  };
  
  const Style = () => {
    customers.sort((a, b) => a.Style && a.Style.localeCompare(b.Style));
    setShow(true);

    // customers.reverse()
  };
  // -------//
  const Sku = () => {
    // .sort((a, b) => a.name.localeCompare(b.name))
    customers.sort((c, d) => c.sku.localeCompare(d.sku));
    setShow(true);
  };
  // -------//
  // -------//
  const HightTOLow = () => {
    customers.sort((x, z) => z.PairsSold - x.PairsSold);
    setShow(true);
  };
  // -------//
  const LowToHigh = () => {
    customers.sort((f, g) => f.PairsSold - g.PairsSold);
    setShow(true);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();
  const [filter, setfilter] = useState("");
  const [orders, setrders] = useState("");
  const [customers, setcustomers] = useState([]);
  const [done, setdone] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(2000);
  const [products, setproducts] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [Mesage, setMesage] = useState("");
  const GetCustomers = async () => {
    // console.log(StartDate);
    // console.log(EndDate);
    StartDate == "" && EndDate == ""
      ? alert("fill date")
      : axios
          .post(
            `/all-styles-sold-report`,
            {
              StartDate: StartDate,
              EndDate: EndDate,
            },
            {
              headers: {
                "x-auth-header": tokenauth && tokenauth,
              },
            }
          )
          .then((response) => {
            // console.log(response.data);
            newcustomers = response.data;
            setcustomers(response.data);
            setdone(false);
            setMesage(response.data.message);
          })
          .catch((err) => {
            console.log(err);
          });
  };
  // useEffect(() => {
  //   GetCustomers();
  //   setdone(true);
  // }, []);
  const handlesubmit = (e) => {
    e.preventDefault();

    GetCustomers();
    setdone(true);
  };
  // const handlesubmit = () => {
  //   value == "" ? alert("select start date") : GetCustomers();
  //   setdone(true);
  // };
  //
  const [StartDate, setStartDate] = useState("");
  const [EndDate, setEndDate] = useState("");
  //
  //

  return (
    <div className="tableX">
      <ResponsiveDrawer heading="Reports" />
      <Box
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
        component="main"
      >
        <Toolbar />

        <div className="position-relative my-5">
          {/*  */}
          {/*  */}
          <div className="d-flex justify-content-between forRowReverse foralignment mx-4">
            <div className="">
              <Button
                id="demo-customized-button"
                aria-controls="demo-customized-menu"
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon style={{ color: "#f00e0e" }} />}
              >
                <span className="fw-bolder text-dark">
                  All&nbsp;Styles&nbsp;Sold
                </span>
              </Button>
              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                  "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <MenuItem
                  disableRipple
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  onClick={() => {
                    navigate("/AllStyleSold");
                  }}
                >
                  All&nbsp;Styles&nbsp;Sold
                </MenuItem>
                <MenuItem
                  disableRipple
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  onClick={() => {
                    navigate("/TotalSales");
                  }}
                >
                  Total&nbsp;Sales&nbsp;by&nbsp;Date
                </MenuItem>
                <MenuItem
                  disableRipple
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  onClick={() => {
                    navigate("/SalesByStyle");
                  }}
                >
                  Total&nbsp;Sales&nbsp;by&nbsp;Style
                </MenuItem>
                <MenuItem
                  disableRipple
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  onClick={() => {
                    navigate("/StoreOrderStatus");
                  }}
                >
                  Store&nbsp;Order&nbsp;Status
                </MenuItem>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem
                  disableRipple
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  onClick={() => {
                    navigate("/SalesByStore");
                  }}
                >
                  Total&nbsp;Sales&nbsp;by&nbsp;Store&nbsp;Name
                </MenuItem>
                <MenuItem
                  disableRipple
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  onClick={() => {
                    navigate("/FactoryStyleByStoreName");
                  }}
                >
                  Factory&nbsp;Style&nbsp;by&nbsp;Store&nbsp;Name
                </MenuItem>
                <MenuItem
                  disableRipple
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  onClick={() => {
                    navigate("/TotalPairsByStyle");
                  }}
                >
                  Total&nbsp;Pairs&nbsp;by&nbsp;Style&nbsp;Name
                </MenuItem>
              </StyledMenu>
            </div>
            <div className="w-50">
              <div className="position-relative">
                <Form>
                  <Form.Control
                    placeholder="Search By Style & Sku"
                    type="search"
                    className="input_field"
                    onChange={(e) => setfilter(e.target.value)}
                  />
                  <BiSearch className="search_Agent1" />
                </Form>
              </div>
            </div>
          </div>
          {/*  */}
          <div className="d-flex justify-content-between mt-4 resclass">
            <div className="EndDateWidth for_DateTime ms-4">
              <Form onSubmit={handlesubmit}>
                <div className="d-flex">
                  <div>
                    <Form.Control
                      placeholder="date/month/year"
                      className=""
                      type="date"
                      label="Start Date"
                      name="startdate"
                      required
                      onChange={(e) => {
                        // let arr = e.target.value.split("-");
                        // var day = arr[2].split("");
                        // var month = arr[1].split("");
                        // var dayx = parseInt(arr[2], 10) < 10 ? day[1] : arr[2];
                        // var monthx =
                        //   parseInt(arr[1], 10) < 10 ? month[1] : arr[1];
                        // setStartDate(
                        //   dayx.toString() +
                        //     "/" +
                        //     monthx.toString() +
                        //     "/" +
                        //     arr[0]
                        // );
                        let arr = e.target.value.split("-");
                        setStartDate(arr[2] + "/" + arr[1] + "/" + arr[0]);
                      }}
                    />
                  </div>
                  <div>
                    <div></div>
                    <Form.Control
                      placeholder="date/month/year"
                      type="date"
                      className="mx-2"
                      label="End Date"
                      name="enddate"
                      required
                      onChange={(e) => {
                        let arr = e.target.value.split("-");
                        setEndDate(arr[2] + "/" + arr[1] + "/" + arr[0]);

                        // let arr = e.target.value.split("-");
                        // var day = arr[2].split("");
                        // var month = arr[1].split("");
                        // var dayx = parseInt(arr[2], 10) < 10 ? day[1] : arr[2];
                        // var monthx =
                        //   parseInt(arr[1], 10) < 10 ? month[1] : arr[1];
                        // setEndDate(
                        //   dayx.toString() +
                        //     "/" +
                        //     monthx.toString() +
                        //     "/" +
                        //     arr[0]
                        // );
                      }}
                    />
                  </div>
                  <div>
                    <button type="submit" className="CSV mx-3 py-2">
                      Search
                    </button>
                  </div>
                </div>
              </Form>
            </div>
            {/*  */}
            <div className="d-flex me-4 mx-4">
              <div className="">
                <p
                  className="forDropDown position-relative"
                  onClick={() => SortBy()}
                >
                  Sort&nbsp;by
                  <KeyboardArrowDownIcon style={{ color: "#f00e0e" }} />
                </p>
                <div className={show ? "sort" : "sort-by"}>
                  <ul>
                    <li onClick={() => asendsort()}>
                      Factory Style <ArrowUpwardIcon className="UpArrow" />
                    </li>
                    <li onClick={() => desendsort()}>
                      Factory Style <ArrowDownwardIcon className="UpArrow" />
                    </li>
                    <li onClick={() => Sku()}>Sku</li>
                    <li onClick={() => HightTOLow()}>
                      Pair Sold&nbsp;
                      <ArrowUpwardIcon className="UpArrow" />
                    </li>
                    <li onClick={() => LowToHigh()}>
                      Pair Sold&nbsp;
                      <ArrowDownwardIcon className="UpArrow" />
                    </li>
                  </ul>
                </div>
              </div>
              {/*  */}
              <div>
                <ReactHTMLTableToExcel
                  id="test-table-xls-button"
                  className="ms-2 py-2 px-2 ExcelDownlad download-table-xls-button"
                  table="table-to-xls"
                  filename="tablexls"
                  sheet="tablexls"
                  buttonText="Export&nbsp;CSV"
                  size="small"
                />
              </div>
            </div>
          </div>

          {done ? (
            <CircularIndeterminate className="reportsales" />
          ) : (
            <div className="for__table m-4">
              <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer sx={{ maxHeight: 730 }}>
                  <Table
                    stickyHeader
                    aria-label="sticky table"
                    id="table-to-xls"
                  >
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            className="fw-bolder"
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {customers.length > 0 ? (
                        customers
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          // eslint-disable-next-line array-callback-return
                          .filter((admin) => {
                            if (filter === "") {
                              return customers;
                            } else if (
                              (admin.sku &&
                                admin.sku
                                  .toString()
                                  .toLowerCase()
                                  .includes(filter.toString().toLowerCase())) ||
                              (admin.Style &&
                                admin.Style.toString()
                                  .toLowerCase()
                                  .includes(filter.toString().toLowerCase()))
                            ) {
                              return customers;
                            }
                          })
                          .map((admin) => {
                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={admin._id}
                              >
                                <TableCell>{admin.Style}</TableCell>
                                <TableCell>{admin.sku}</TableCell>
                                <TableCell>{admin.Color}</TableCell>
                                <TableCell>{admin.PairsSold}</TableCell>
                              </TableRow>
                            );
                          })
                      ) : (
                        <h5 className="text-center mt-5 fw-bolder">No Data</h5>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>

                <TablePagination
                  className="mx-5 mt-3 px-5"
                  rowsPerPageOptions={[2000, 5000, 8000]}
                  component="div"
                  count={customers.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </div>
          )}
        </div>
      </Box>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </div>
  );
}

export default AllStyleSold;
