import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { Form, Col, Row } from "react-bootstrap";
import * as XLSX from "xlsx";
import axios from "axios";
import Notification from "../AlertNotification/Message";
import LinearWithValueLabel from "../Loader/progressLoad";
//
//
export default function UpdatePrice() {
  //
  //
  const [done, setdone] = useState();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [items, setItems] = useState([]);
  //
  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
      //
      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: "buffer" });
        const wsname = wb.SheetNames[2];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);
        //
        resolve(data);
        setItems(data);
        // setdone(true);
      };
      //
      fileReader.onerror = (error) => {
        console.log(error);
        reject(error);
      };
    });
    //
    promise.then((d) => {
      // console.log(d);
      setdone(true);
      axios
        .post("/update-size-price", {
          products: d,
        }, {

          headers: {
    
            "x-auth-header": sessionStorage.getItem("tokan")
          },
        })
        .then((response) => {
          // console.log(response.data);
          if (response.status === 200) {
            // ${response.data.message}
            setdone(false);
            setNotify({
              isOpen: true,
              message: `Prices Updated Successfully!`,
              type: "success",
            });
          }
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((err) => {
          setdone(false);
          console.log(err);
          setNotify({
            isOpen: true,
            message: `${err.response.data.message}`,
            type: "error",
          });
          // console.log(err);
        });
      setItems(d);
    });
  };
  //
  //
  //
  const [hx, sethx] = useState(false);
  const handleClose = () => {
    sethx(false);
  };
  const handleOpen = () => {
    sethx(!hx);
  };
  return (
    <div>
      <div className="csvimport">
        {done ? (
          <LinearWithValueLabel className="loaderx" />
        ) : (
          <Form>
            <div className={!hx ? "main12" : "main11"}>
              <div className="appXp">
                <div className="position-relative">
                  <label>
                    <div>
                      <Form.Control
                        type="file"
                        onChange={(e) => {
                          const file = e.target.files[0];
                          readExcel(file);
                        }}
                      />
                      <FileUploadIcon
                        className="iconX mt-5"
                        style={{ color: "#ba0000" }}
                      />
                    </div>
                  </label>
                  <b
                    className="d-flex justify-content-center"
                    style={{ color: "#ba0000" }}
                  >
                    Update Prices
                  </b>
                  <small className="Selected">
                    <span className="fw-bolder">{items.length}</span> Selected
                  </small>
                </div>
                <div className="for_updateBtn"></div>
              </div>
            </div>
          </Form>
        )}
      </div>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
}

///
