import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Avatar1 from "../Images/Ellipse1.png";
import Typography from "@mui/material/Typography";

const data = [
  {
    avatar: <img src={Avatar1} />,
    name: "Sneaker-1890",
    quantity: 10,
  },
  {
    avatar: <img src={Avatar1} />,
    name: "Sneaker-1890",
    quantity: 10,
  },
  {
    avatar: <img src={Avatar1} />,
    name: "Sneaker-1890",
    quantity: 10,
  },
];

export default function EstimatedBonus({ heading }) {
  return (
    <div className="dcards">
      <Card sx={{ minWidth: 275 }}>
        <CardContent>
          <Typography
            variant="p"
            sx={{ fontWeight: "bold" }}
            component="div"
            className="heading1"
          >
            <h5 className="fw-bolder">Revenue</h5>
          </Typography>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            <small>as of 25 May 2019, 09:41 PM</small>
          </Typography>
          <div className="my-5 text-center Bonus">
            <div className="fw-bolder">
              <h1 className="fw-bolder">$15K</h1>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
