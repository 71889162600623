/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import ResponsiveDrawer from "../Drawer/drawer";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { Form, Row, Col } from "react-bootstrap";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import { BiSearch } from "react-icons/bi";
import Dropdown from "react-bootstrap/Dropdown";
import Notification from "../AlertNotification/Message";
import ConfirmDialog from "../AlertNotification/confirmdialog";
import CircularIndeterminate from "../Loader/Loader";
import DropdownButton from "react-bootstrap/DropdownButton";
import { DataGrid } from "@mui/x-data-grid";

////////////
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
////////////
const drawerWidth = 240;

const columns = [
  { field: "FactoryStyle", width: 130 },
  { field: "FactoryStyle", headerName: "FactoryStyle", width: 130 },
  {
    field: "Sku",
    headerName: "Sku",
    type: "number",
    width: 90,
  },
  {
    field: "StringColors",
    headerName: "StringColors",
    type: "number",
    width: 90,
  },
  {
    field: "fullName",
    headerName: "Full name",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params) =>
      `${params.getValue(params.id, "FactoryStyle") || ""} ${
        params.getValue(params.id, "FactoryStyle") || ""
      }`,
  },
];
//
const rows = [
  {
    id: 1,
    lastName: "Snow",
    FactoryStyle: "Jon",
    Sku: 35,
    StringColors: "StringColors",
  },
  {
    id: 2,
    lastName: "Lannister",
    FactoryStyle: "Cersei",
    Sku: 42,
    StringColors: "StringColors",
  },
  {
    id: 3,
    lastName: "Lannister",
    FactoryStyle: "Jaime",
    Sku: 42,
    StringColors: "StringColors",
  },
  {
    id: 4,
    lastName: "Stark",
    FactoryStyle: "Arya",
    Sku: 16,
    StringColors: "StringColors",
  },
  {
    id: 5,
    lastName: "Targaryen",
    FactoryStyle: "Daenerys",
    Sku: null,
    StringColors: "StringColors",
  },
  {
    id: 6,
    lastName: "Melisandre",
    FactoryStyle: null,
    Sku: 150,
    StringColors: "StringColors",
  },
  {
    id: 7,
    lastName: "Clifford",
    FactoryStyle: "Ferrara",
    Sku: 44,
    StringColors: "StringColors",
  },
  {
    id: 8,
    lastName: "Frances",
    FactoryStyle: "Rossini",
    Sku: 36,
    StringColors: "StringColors",
  },
  {
    id: 9,
    lastName: "Roxie",
    FactoryStyle: "Harvey",
    Sku: 65,
    StringColors: "StringColors",
  },
];
//
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

function SortAllStyleSold() {
  const [show, setShow] = useState(true);
  const [data, setData] = useState([]);
  const [sortType, setSortType] = useState("albums");
  const SortBy = () => {
    setShow(!show);
  };

  //
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();
  const [filter, setfilter] = useState("");
  const [customers, setcustomers] = useState([]);
  const [done, setdone] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [tokenauth, settokenauth] = useState("")

  useEffect(() => {
     axios.post("get-token", {
      id: sessionStorage.getItem("token_id")
     }).then((response) => {
      settokenauth(response.data.token) 
     }).catch(err => console.log(err))
    }, [])

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(2000);
  const [products, setproducts] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const GetCustomers = async () => {
    const response = await axios.post(`/all-styles-sold-report`, {
      
      headers: {
        
        "x-auth-header": tokenauth && tokenauth
      },
  });
    setcustomers(response.data);
    setdone(true);
    // console.log(response.data);
  };

  useEffect(() => {
    GetCustomers();
  }, []);

  return (
    <div className="tableX">
      <ResponsiveDrawer heading="Reports" />
      <Box
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
        component="main"
      >
        <Toolbar />
        <div className="position-relative my-5">
          {/*  */}
          {/*  */}
          <Row className="mx-2 mb-5">
            <Col>
              <div>
                <Button
                  id="demo-customized-button"
                  aria-controls="demo-customized-menu"
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  disableElevation
                  onClick={handleClick}
                  endIcon={
                    <KeyboardArrowDownIcon style={{ color: "#f00e0e" }} />
                  }
                >
                  <span className="fw-bolder text-dark">
                    All&nbsp;Styles&nbsp;Sold
                  </span>
                </Button>
                <StyledMenu
                  id="demo-customized-menu"
                  MenuListProps={{
                    "aria-labelledby": "demo-customized-button",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem
                      disableRipple
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    onClick={() => {
                      navigate("/AllStyleSold");
                    }}
                  >
                    All&nbsp;Styles&nbsp;Sold
                  </MenuItem>
                  <MenuItem
                      disableRipple
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    onClick={() => {
                      navigate("/TotalSales");
                    }}
                  >
                    Total&nbsp;Sales&nbsp;by&nbsp;Date
                  </MenuItem>
                  <MenuItem
                      disableRipple
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    onClick={() => {
                      navigate("/StoreOrderStatus");
                    }}
                  >
                    Store&nbsp;Order&nbsp;Status
                  </MenuItem>
                  <Divider sx={{ my: 0.5 }} />
                  <MenuItem
                      disableRipple
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    onClick={() => {
                      navigate("/SalesByStore");
                    }}
                  >
                    Total&nbsp;Sales&nbsp;by&nbsp;Store&nbsp;Name
                  </MenuItem>
                  <MenuItem
                      disableRipple
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    onClick={() => {
                      navigate("/FactoryStyleByCustomer");
                    }}
                  >
                    Factory&nbsp;Style&nbsp;by&nbsp;Customer
                  </MenuItem>
                  <MenuItem
                  disableRipple
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  onClick={() => {
                    navigate("/TotalPairsByStyle");
                  }}
                >
                  Total&nbsp;Pairs&nbsp;by&nbsp;Style&nbsp;Name
                </MenuItem>
                </StyledMenu>
              </div>
            </Col>
            <Col>
              <div className="position-relative">
                <Form>
                  <Form.Control
                    placeholder="Search By StoreName & Sku"
                    type="search"
                    className="input_field"
                    onChange={(e) => setfilter(e.target.value)}
                  />
                  <BiSearch className="search_Agent1" />
                </Form>
              </div>
            </Col>
            {/*  */}
            {/*  */}
            <Col>
              <div className="mt-1 ms-lg-5">
                <p className="forDropDown" onClick={() => SortBy()}>
                  Sort by
                  <KeyboardArrowDownIcon style={{ color: "#f00e0e" }} />
                </p>
                <div className={show ? "sort" : "sort-by"}>
                  <ul>
                    <a href="#">
                      {" "}
                      <li onChange={(e) => setSortType(e.target.value)}>
                        Factory Style
                      </li>
                    </a>
                    <a href="#">
                      {" "}
                      <li>Total Pairs Sold</li>
                    </a>
                    <a href="#">
                      {" "}
                      <li>High to Low</li>
                    </a>
                    <a href="">
                      {" "}
                      <li>Low to High</li>
                    </a>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>

          {!done ? (
            <CircularIndeterminate className="resetLoading" />
          ) : (
            <div className="for__table m-4">
              <Paper sx={{ overflow: "hidden" }}>
                {/* <div style={{ height: 400, width: "100%" }}>
                  <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    checkboxSelection
                  />
                </div> */}
                <TableContainer sx={{ maxHeight: 730 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {customers
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        // eslint-disable-next-line array-callback-return
                        .filter((admin) => {
                          if (filter === "") {
                            return customers;
                          } else if (
                            (admin.SKU &&
                              admin.SKU.toString()
                                .toLowerCase()
                                .includes(filter.toString().toLowerCase())) ||
                            (admin.PairsSold &&
                              admin.PairsSold.toString()
                                .toLowerCase()
                                .includes(filter.toString().toLowerCase()))
                          ) {
                            return customers;
                          }
                        })
                        .map((admin) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={admin._id}
                            >
                              <TableCell>Nothing from backend</TableCell>
                              <TableCell>{admin.SKU}</TableCell>
                              <TableCell>Nothing from backend</TableCell>
                              <TableCell>{admin.PairsSold}</TableCell>
                            </TableRow>
                          );
                        })}
                      {/* <TableHead className="fw-bolder">
                        <TableRow>
                          {columnsTwo.map((column) => (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{ minWidth: column.minWidth }}
                            > 
                              {column.label}
                            </TableCell>
                            // <TableCell>{PairsSold}</TableCell>
                          ))}
                        </TableRow>
                      </TableHead> */}
                    </TableBody>
                  </Table>
                </TableContainer>

                <TablePagination
                  className="mx-5 mt-3 px-5"
                  rowsPerPageOptions={[2000, 5000, 8000]}
                  component="div"
                  count={customers.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </div>
          )}
        </div>
      </Box>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </div>
  );
}

export default SortAllStyleSold;
