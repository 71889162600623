/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import ResponsiveDrawer from "../Drawer/drawer";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { Form, Row, Col } from "react-bootstrap";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import { BiSearch } from "react-icons/bi";
import Notification from "../AlertNotification/Message";
import ConfirmDialog from "../AlertNotification/confirmdialog";
import CircularIndeterminate from "../Loader/Loader";
////////////
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
//
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
////////////
////////////
////////////
const drawerWidth = 240;
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <span className="threedots" />
  </a>
));
const columns = [
  {
    id: "img",
    label: "Store Name",
    minWidth: 50,
    align: "left",
  },
  {
    id: "density",
    label: "Factory Style",
    minWidth: 50,
    align: "left",
    format: (value) => value.toFixed(2),
  },

  {
    id: "population",
    label: "Sku ",
    minWidth: 50,
    align: "left",
  },
  {
    id: "name",
    label: "Color",
    minWidth: 50,
    align: "left",
  },

  {
    id: "population",
    label: "Total Pairs Sold",
    minWidth: 50,
    align: "left",
  },
];
//
//
const columnsTwo = [
  {
    id: "img",
    label: "05-STYLER TOTAL",
    minWidth: 50,
    align: "left",
  },

  {
    id: "population",
    label: "Total:",
    minWidth: 50,
    align: "left",
  },
];
//
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

function FactoryStyleByStoreName() {
  const [show, setShow] = useState(true);
  const SortBy = () => {
    setShow(!show);
  };

  // -------//
  const storeName = () => {
    customers.sort(
      (a, b) => a.storeName && a.storeName.localeCompare(b.storeName)
    );
    setShow(true);
    // customers.sort((a, b) => a.sku.localeCompare(b.sku));
  };
  // -------//
  const HightTOLow = () => {
    customers.sort((c, d) => d.Pairs - c.Pairs);
    setShow(true);
  };
  // -------//
  const LowToHigh = () => {
    customers.sort((f, g) => f.Pairs - g.Pairs);
    setShow(true);
  };
  // -------//
  const Sku = () => {
    customers.sort((h, i) => h.SKU - i.SKU);
    customers.sort((x, z) => x.SKU.localeCompare(z.SKU));

    // customers.sort((a, b) => a.sku.localeCompare(b.sku));
    setShow(true);
  };
  // -------//
  const FactoryStyleSort = () => {
    customers.sort(
      (x, z) => x.StyleFactory && x.StyleFactory.localeCompare(z.StyleFactory)
    );
    setShow(true);

    // customers.sort((a, b) => a.sku.localeCompare(b.sku));
  };
  // -------//
  const CustomerSort = () => {
    customers.sort((s, t) => s.Customer.localeCompare(t.Customer));
    setShow(true);

    // customers.sort((a, b) => a.sku.localeCompare(b.sku));
  };
  const navigate = useNavigate();
  //
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [orders, setOrders] = useState([]);
  const [filter, setfilter] = useState("");
  const [customers, setcustomers] = useState([]);
  const [Mesage, setMesage] = useState("");
  const [done, setdone] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(2000);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [StartDate, setStartDate] = useState("");
  const [EndDate, setEndDate] = useState("");
  // console.log("start date", StartDate);
  // console.log("end date", EndDate);
  //
  // let arr = StartDate.split("/");
  // let sd = arr[1] + "/" + arr[0] + "/" + [2];
  // const handlesubmit = (e) => {
  //   GetFactoryStyle();
  //   setdone(true);
  // };
  // useEffect(() => {
  //   GetFactoryStyle();
  //   setdone(true);
  // }, []);
  const [tokenauth, settokenauth] = useState("")

  useEffect(() => {
     axios.post("get-token", {
      id: sessionStorage.getItem("token_id")
     }).then((response) => {
      settokenauth(response.data.token) 
     }).catch(err => console.log(err))
    }, [])
  const GetFactoryStyle = async () => {
    const response = await axios.post(`/factory-style-customer-report`, {
      StartDate: StartDate,
      EndDate: EndDate,
    }, {
      
      headers: {
        
        "x-auth-header": tokenauth && tokenauth
      },
  });
    setcustomers(response.data);
    setMesage(response.data.message);
    setdone(false);
    // console.log(response.data);
  };
  /////////////
  /////////////
  /////////////
  const handlesubmit = (e) => {
    e.preventDefault();
    // value == "" ? alert("select start date") : GetFactoryStyle();
    setdone(true);
    GetFactoryStyle();
  };
  let arrx = new Date().toLocaleDateString().split("/");
  let date = arrx[1] + "/" + arrx[0] + "/" + arrx[2];
  const [value, setValue] = React.useState(null);
  const [valueend, setValueend] = React.useState(null);
  const handleChange = (newValue) => {
    setValue(newValue);
    let arr = newValue.toLocaleDateString().split("/");
    // console.log(arr);
    setStartDate(arr[1] + "/" + arr[0] + "/" + arr[2]);
    // console.log(value);
  };
  const handleChangeend = (newValue) => {
    setValueend(newValue);
    let arr = newValue.toLocaleDateString().split("/");

    setEndDate(arr[1] + "/" + arr[0] + "/" + arr[2]);
  };

  return (
    <div className="tableX">
      <ResponsiveDrawer heading="Reports" />
      <Box
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
        component="main"
      >
        <Toolbar />

        <div className="position-relative mt-5">
          <div className="d-flex flex-column">
            <div className="d-flex justify-content-between mx-3">
              <div>
                <Button
                  id="demo-customized-button"
                  aria-controls="demo-customized-menu"
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  disableElevation
                  onClick={handleClick}
                  endIcon={
                    <KeyboardArrowDownIcon style={{ color: "#f00e0e" }} />
                  }
                >
                  <span className="fw-bolder text-dark">
                    Factory&nbsp;Style&nbsp;by&nbsp;Store&nbsp;Name
                  </span>
                </Button>
                <StyledMenu
                  id="demo-customized-menu"
                  MenuListProps={{
                    "aria-labelledby": "demo-customized-button",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem
                    disableRipple
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    onClick={() => {
                      navigate("/AllStyleSold");
                    }}
                  >
                    All&nbsp;Styles&nbsp;Sold
                  </MenuItem>
                  <MenuItem
                    disableRipple
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    onClick={() => {
                      navigate("/TotalSales");
                    }}
                  >
                    Total&nbsp;Sales&nbsp;by&nbsp;Date
                  </MenuItem>
                  <MenuItem
                    disableRipple
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    onClick={() => {
                      navigate("/StoreOrderStatus");
                    }}
                  >
                    Store&nbsp;Order&nbsp;Status
                  </MenuItem>
                  <Divider sx={{ my: 0.5 }} />
                  <MenuItem
                    disableRipple
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    onClick={() => {
                      navigate("/SalesByStore");
                    }}
                  >
                    Total&nbsp;Sales&nbsp;by&nbsp;Store&nbsp;Name
                  </MenuItem>
                  <MenuItem
                    disableRipple
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    onClick={() => {
                      navigate("/FactoryStyleByStoreName");
                    }}
                  >
                    Factory&nbsp;Style&nbsp;by&nbsp;Store&nbsp;Name
                  </MenuItem>
                  <MenuItem
                  disableRipple
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  onClick={() => {
                    navigate("/TotalPairsByStyle");
                  }}
                >
                  Total&nbsp;Pairs&nbsp;by&nbsp;Style&nbsp;Name
                </MenuItem>
                </StyledMenu>
              </div>
              <div className="position-relative me-3 w-50">
                <Form>
                  <Form.Control
                    placeholder="Sku, Style, Store Name"
                    type="search"
                    className="input_field"
                    onChange={(e) => setfilter(e.target.value)}
                  />
                  <BiSearch className="search_Agent1" />
                </Form>
              </div>
            </div>
            <div className="d-flex justify-content-between ms-4 mt-4 resclass">
              <div className="EndDateWidth for_DateTime">
                <Form onSubmit={handlesubmit}>
                  <div className="d-flex">
                    <div>
                      <Form.Control
                        placeholder="date/month/year"
                        className=""
                        type="date"
                        label="Start Date"
                        name="startdate"
                        onChange={(e) => {
                          let arr = e.target.value.split("-");
                          // console.log(arr);
                          setStartDate(arr[2] + "/" + arr[1] + "/" + arr[0]);
                          // console.log(StartDate);
                        }}
                      />
                    </div>
                    <div>
                      <Form.Control
                        placeholder="date/month/year"
                        type="date"
                        className="mx-2"
                        label="End Date"
                        name="enddate"
                        onChange={(e) => {
                          let arr = e.target.value.split("-");
                          setEndDate(arr[2] + "/" + arr[1] + "/" + arr[0]);
                          setTimeout(() => {}, 2000);
                        }}
                      />
                    </div>
                    <div>
                      <button type="submit" className="CSV mx-3 py-2">
                        Search
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
              {/* </Col>

              <Col lg={2} md={6} sm={6}> */}
              <div className="d-flex me-4 ">
                <div className="">
                  <p className="forDropDown" onClick={() => SortBy()}>
                    Sort&nbsp;by
                    <KeyboardArrowDownIcon style={{ color: "#f00e0e" }} />
                  </p>
                  <div className={show ? "sort" : "sort-by"}>
                    <ul>
                      {/* <li onClick={() => CustomerSort()}>Customer</li> */}
                      <li onClick={() => storeName()}>store Name</li>
                      <li onClick={() => FactoryStyleSort()}>Factory Style</li>
                      <li onClick={() => Sku()}>Sku</li>
                      <li onClick={() => HightTOLow()}>
                        Pair&nbsp;Sold&nbsp;
                        <ArrowUpwardIcon className="UpArrow" />
                      </li>
                      <li onClick={() => LowToHigh()}>
                        Pair&nbsp;Sold&nbsp;
                        <ArrowDownwardIcon className="UpArrow" />
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="ms-3">
                  <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="py-2 px-3 ExcelDownlad download-table-xls-button"
                    table="table-to-xls"
                    filename="tablexls"
                    sheet="tablexls"
                    buttonText="Export&nbsp;CSV"
                    size="small"
                  />
                </div>
              </div>
            </div>
          </div>
          {done ? (
            <CircularIndeterminate className="reportsales" />
          ) : (
            <div className="for__table m-4">
              <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer sx={{ maxHeight: 730 }}>
                  <Table
                    stickyHeader
                    aria-label="sticky table"
                    id="table-to-xls"
                  >
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {customers.length > 0 ? (
                        customers
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .filter((admin) => {
                            if (filter === "") {
                              return customers;
                            } else if (
                              (admin.SKU &&
                                admin.SKU.toString()
                                  .toLowerCase()
                                  .includes(filter.toString().toLowerCase())) ||
                              (admin.StyleFactory &&
                                admin.StyleFactory.toString()
                                  .toLowerCase()
                                  .includes(filter.toString().toLowerCase())) ||
                              (admin.storeName &&
                                admin.storeName
                                  .toString()
                                  .toLowerCase()
                                  .includes(filter.toString().toLowerCase()))
                            ) {
                              return customers;
                            }
                          })
                          .map((admin) => {
                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={admin._id}
                              >
                                <TableCell>{admin.storeName}</TableCell>
                                <TableCell>{admin.StyleFactory}</TableCell>
                                <TableCell>{admin.SKU}</TableCell>
                                <TableCell>{admin.color}</TableCell>
                                <TableCell>{admin.Pairs}</TableCell>
                              </TableRow>
                            );
                          })
                      ) : (
                        <h5 className="text-center mt-5 fw-bolder">No Data</h5>
                      )}
                    </TableBody>
                  </Table>
                  {/*  */}
                  {/*  */}
                  {/*  */}
                  {/* <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columnsTwo.map((column) => (
                          <TableCell
                            className="fw-bolder text-danger"
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow hover role="checkbox" tabIndex={-1}>
                        <TableCell>--</TableCell>
                        <TableCell>
                          {" "}
                          {customers.reduce((total, item) => {
                            return +total + +item.Pairs;
                          }, 0)}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table> */}
                </TableContainer>
                <TablePagination
                  className="mx-5 mt-3 px-5"
                  rowsPerPageOptions={[2000, 5000, 8000]}
                  component="div"
                  count={customers.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </div>
          )}
        </div>
      </Box>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </div>
  );
}

export default FactoryStyleByStoreName;
