import React from 'react';
import axios from "axios"

export default function Test() {
    const Submit = (e) => {
        e.preventDefault()
        axios.post("https://comcodecue.herokuapp.com/add-product-data" , {
            sku:"hjhjhjhjhh",
Bow:"hjhjhjhjhh",
Brand:"hjhjhjhjhh",
Description:"hjhjhjhjhh",
Factory:"hjhjhjhjhh",
ID:"hjhjhjhjhh",
Last:"hjhjhjhjhh",
Location1:"hjhjhjhjhh",
Location2:"hjhjhjhjhh",
Location3:"hjhjhjhjhh",
Location4:"hjhjhjhjhh",
Location5:"hjhjhjhjhh",
Location6:"hjhjhjhjhh",
Material1:"hjhjhjhjhh",
Material2:"hjhjhjhjhh",
Material3:"hjhjhjhjhh",
Material4:"hjhjhjhjhh",
Material5:"hjhjhjhjhh",
        }).then(res => console.log(res)).catch(err => console.log(err))
    }
  return <div>
      <button onClick={Submit}>bgt</button>
  </div>;
}
