import React, { useState, useEffect } from "react";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { Form } from "react-bootstrap";
import { BiSearch } from "react-icons/bi";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import Dropdown from "react-bootstrap/Dropdown";
import XLSX from "xlsx";
import CircularIndeterminate from "../../Loader/Loader";

////////////

const drawerWidth = 240;
const columns = [
  {
    id: "img",
    label: "Customer Name",
    minWidth: 100,
    align: "left",
  },

  {
    id: "name",
    label: "Order Number",
    minWidth: 130,
    align: "left",
  },

  {
    id: "population",
    label: "Order Status",
    minWidth: 140,
    align: "left",
  },
];

function ActiveCustomerOrder() {
  const navigate = useNavigate();
  const [filter, setfilter] = useState("");
  const [lengths, setlengths] = useState("");
  const [ready, setready] = useState(false);
  const [readybulk, setreadybulk] = useState(false);
  const [admins, setadmins] = useState([]);
  const [done, setdone] = useState(false);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [uniquesku, setuniquesku] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const GetAdmins = async () => {
    const response = await axios.post(`/customer-order-status-report`, {
      
      headers: {
        
        "x-auth-header": tokenauth && tokenauth
      },
  });

    setadmins(response.data);
    setdone(true);
    // console.log(response.data);
  };
  const [tokenauth, settokenauth] = useState("")

 useEffect(() => {
    axios.post("get-token", {
     id: sessionStorage.getItem("token_id")
    }).then((response) => {
     settokenauth(response.data.token) 
    }).catch(err => console.log(err))
   }, [])
  useEffect(() => {
    GetAdmins();
  }, []);
  return (
    <div className="tableX">
      <Box
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
        component="main"
      >
        <Toolbar />
        {!done ? (
          <CircularIndeterminate className="allagentsLoader" />
        ) : (
          <div className="position-relative">
            <div className="for__table m-4">
              <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer sx={{ maxHeight: 730 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {admins
                        .filter((admin) => {
                          return admin.OrderStatus === "1";
                        })
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )

                        
                        .map((admin) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={admin._id}
                            >
                              <TableCell>{admin.Customer}</TableCell>
                              <TableCell>{admin.OrderNumber}</TableCell>
                              <TableCell>
                                {admin.OrderStatus === "1" ? (
                                  <div style={{ color: "green" }}>Active </div>
                                ) : (
                                  <div style={{ color: "red" }}></div>
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  className="mx-5 mt-3 px-5"
                  rowsPerPageOptions={[20, 50, 300]}
                  component="div"
                  count={admins.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </div>
          </div>
        )}
      </Box>
    </div>
  );
}

export default ActiveCustomerOrder;
