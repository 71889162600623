import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import React, { useState, useEffect, useContext } from "react";

import { UserContext } from "../../App";


      
      import { useNavigate } from "react-router-dom";
import axios from "axios";
import CircularIndeterminate from "../Loader/Loader";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { BiSearch } from "react-icons/bi";

import { Form, Col, Row } from "react-bootstrap";

import Grid from "@mui/material/Grid";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function CustomerGrid() {
  const navigate = useNavigate();
  const [filter, setfilter] = useState("");
  const [customers, setcustomers] = useState([]);
  const [done, setdone] = useState(false);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [tokenauth, settokenauth] = useState("")

  const GetCustomers =  () => {
    axios.post("/get-token", {
      id: sessionStorage.getItem("token_id")
    }).then((res) => {
      settokenauth(res.data.token)
    axios.post(`/get-customers`,{} , {
      
      headers: {
        
        "x-auth-header": res.data.token
      },
  }).then((response) => {
      setcustomers(response.data);
      setdone(true);
    }).catch(err => console.log(err))
  }).catch((err) => console.log(err))
    // console.log(response.data);
    // setTimeout(() => {
    //   setNotify({
    //     isOpen: true,
    //     message: "Request Timeout",
    //     type: "error",
    //   });
    //   setdone(false);
    // }, 100);

    // setdone(true);
  };

  useEffect(() => {
    sessionStorage.getItem("payloadout") != "true"
      ? navigate("/")
      :
    GetCustomers();
    setdone(true);
  }, []);

  const handleDelete = (mail) => {
    setdone(true);
    // console.log(mail);
    // setConfirmDialog({
    //   ...confirmDialog,
    //   isOpen: false,
    // });
    axios
      .post("/delete-customer", {
        email: mail,
      }, {

        headers: {

          "x-auth-header": tokenauth
        },
      })
      .then((response) => {
        setdone(false);
        console.log(response.status);
        if (response.status === 200) {
          setdone(false);
          setNotify({
            isOpen: true,
            message: `${response.data.message}`,
            type: "success",
          });
        }
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        setdone(false);
        console.log(err.status);
        setNotify({
          isOpen: true,
          message: `${err.response}`,
          type: "error",
        });
        // console.log(err);
      });
  };
  return (
    <>
      <div className="d-flex justify-content-between mx-2 mt-5">
        <small className="ms-4 fw-bolder mt-1">Search&nbsp;By</small>
        <div className="position-relative w-75 me-3">
          <Form>
            <Form.Control
              placeholder="CustomerID, Email or Phone"
              type="search"
              className="input_field"
              onChange={(e) => setfilter(e.target.value)}
            />
            <BiSearch className="search_Agent" />
          </Form>
        </div>
      </div>
      {!done ? (
        <CircularIndeterminate className="resetLoading" />
      ) : (
        <div className="d-flex flex-row  mx-3 my-3 ">
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} columns={24}>
              {customers
                .filter((admin) => {
                  if (filter === "") {
                    return customers;
                  } else if (
                    admin.customerid.toString().includes(filter.toString()) ||
                    (admin.firstname &&
                      admin.firstname
                        .toString()
                        .toLowerCase()
                        .includes(filter.toString().toLowerCase())) ||
                    (admin.lastname &&
                      admin.lastname
                        .toString()
                        .toLowerCase()
                        .includes(filter.toString().toLowerCase())) ||
                    (admin.email &&
                      admin.email
                        .toString()
                        .toLowerCase()
                        .includes(filter.toString()))
                    // ||
                    // (admin.phone &&
                    //   admin.phone
                    //     .toString()
                    //     .includes(filter.toString())) ||
                    // (admin.shopname &&
                    //   admin.shopname
                    //     .toLowerCase()
                    //     .toString()
                    //     .includes(filter.toLowerCase().toString()))
                  ) {
                    return customers;
                  }
                })
                .map((customer) => (
                  <Grid item xs={24} lg={8} md={8} sm={24}>
                    <Card sx={{ minHeight: 200, overflow: "auto" }}>
                      <CardContent>
                        <Typography
                          sx={{ fontSize: 14 }}
                          color="text.secondary"
                          gutterBottom
                        >
                          <b style={{ color: "#4F6367", paddingRight: 15 }}>
                            ID:
                          </b>{" "}
                          <b>{customer.customerid}</b>
                        </Typography>
                        <Typography color="text.secondary" component="div">
                          <small
                            style={{
                              color: "#4F6367",
                              paddingRight: 15,
                              fontSize: 12,
                            }}
                          >
                            {" "}
                            Name:{" "}
                          </small>
                          <small>
                            {customer.firstname}&nbsp;
                            {customer.lastname}
                          </small>
                        </Typography>
                        <Typography color="text.secondary">
                          <small
                            style={{
                              color: "#4F6367",
                              paddingRight: 15,
                              fontSize: 12,
                            }}
                          >
                            {" "}
                            Email:
                          </small>{" "}
                          <small>{customer.email_1}</small>
                        </Typography>
                        <Typography color="text.secondary">
                          <small
                            style={{
                              color: "#4F6367",
                              paddingRight: 11,
                              fontSize: 12,
                            }}
                          >
                            {" "}
                            Phone:{" "}
                          </small>
                          <small>{customer.StorePhone}</small>
                        </Typography>
                        <Typography color="text.secondary">
                          <small
                            style={{
                              color: "#4F6367",
                              paddingRight: 21,
                              fontSize: 12,
                            }}
                          >
                            {" "}
                            Cell#{" "}
                          </small>
                          <small>{customer.cell_1}</small>
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button
                          size="small"
                          color="error"
                          onClick={() => {
                            navigate(`/UpdateCustomer/${customer.email_1}`);
                          }}
                        >
                          Edit
                        </Button>
                        <Button
                          size="small"
                          color="error"
                          onClick={() => {
                            handleDelete(customer.email_1);
                          }}
                        >
                          Delete
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
            </Grid>
          </Box>
        </div>
      )}
    </>
  );
}
