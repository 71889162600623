import React, { useState, useEffect, useContext } from "react";

import { UserContext } from "../../App";

import axios from "axios";
import { Form, Col, Row } from "react-bootstrap";
import ResponsiveDrawer from "../Drawer/drawer";
import { useNavigate, useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Notification from "../AlertNotification/Message";
import Avatar from "@mui/material/Avatar";

import CircularIndeterminate from "../Loader/Loader";

const drawerWidth = 240;

const Input = styled("input")({
  display: "none",
});

const ViewProduct = () => {
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState({
    file: [],
    filepreview: null,
  });
  const [upload, setupload] = useState(true);
  const [size, setsize] = useState(false);
  ////////
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [shopname, setShopname] = useState("");
  const [StyleFactory, setStyleFactory] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [joiningdate, setJoiningdate] = useState("");
  const [description, setDescription] = useState("");
  const [Last, setLast] = useState("");
  const [Location1, setLocation1] = useState("");
  const [Location2, setLocation2] = useState("");
  const [Location3, setLocation3] = useState("");
  const [Location4, setLocation4] = useState("");
  const [Location5, setLocation5] = useState("");
  const [Location6, setLocation6] = useState("");
  const [Material1, setMaterial1] = useState("");
  const [Material2, setMaterial2] = useState("");
  const [Material3, setMaterial3] = useState("");
  const [Material4, setMaterial4] = useState("");
  const [Material5, setMaterial5] = useState("");
  const [Material6, setMaterial6] = useState("");
  const [Notes, setNotes] = useState("");
  const [Bow, setBow] = useState("");
  const [Outsolecolor, setOutsolecolor] = useState("");
  const [Outsoletype, setOutsoletype] = useState("");
  const [Price1, setPrice1] = useState("");
  const [Price2, setPrice2] = useState("");
  const [Price3, setPrice3] = useState("");
  const [SEASON, setSEASON] = useState("");
  const [Size1, setSize1] = useState("");
  /////////////
  const [Size2, setSize2] = useState("");
  const [Size3, setSize3] = useState("");
  const [Stitch, setStitch] = useState("");
  const [StyleName, setStyleName] = useState("");
  /////////////
  const [confirmpassword, setconfirmpassword] = useState("");
  const [done, setdone] = useState(false);
  /////////////
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const handleChange = (event) => {
    setSelectedFile({
      ...selectedFile,
      file: event.target.files[0],
      filepreview: URL.createObjectURL(event.target.files[0]),
    });
    setupload(false);
  };

  const { _id } = useParams();

  // get-Employee API calling
  const GetProduct =  () => {
    axios.post("/get-token" ,{
      id: sessionStorage.getItem("token_id")
    }).then((res) => {
      
    axios.get(`/get-products`, {
      
      headers: {
        
        "x-auth-header": res.data.token
      },
  }).then((response) => {
      setdone(true);

      response.data
        .filter((employees) => {
          return _id == employees._id;
        })
        .map((employee) => {
          setFname(employee.sku);
          setLname(employee.ArticleName);
          setStyleFactory(employee.StyleFactory);
          setEmail(employee.Bow);
          setPhone(employee.Brand);
          setJoiningdate(employee.Description);
          setDescription(employee.Factory);
          setShopname(employee.ID);
          setSelectedFile(employee.Picture);
          setLast(employee.Last);
          setLocation1(employee.Location1);
          setLocation2(employee.Location2);
          setLocation3(employee.Location3);
          setLocation4(employee.Location4);
          setLocation5(employee.Location5);
          setLocation6(employee.Location6);
          setMaterial1(employee.Material1);
          setMaterial2(employee.Material2);
          setMaterial3(employee.Material3);
          setMaterial4(employee.Material4);
          setMaterial5(employee.Material5);
          setMaterial6(employee.Material6);
          setNotes(employee.Notes);
          setOutsolecolor(employee.Outsolecolor);
          setOutsoletype(employee.Outsoletype);
          setBow(employee.Bow);
          setPrice1(employee.Price1);
          setPrice2(employee.Price2);
          setPrice3(employee.Price3);
          setSEASON(employee.Season);
          setSize1(employee.Size1);
          setSize2(employee.Size2);
          setSize3(employee.Size3);
          setStitch(employee.Stitch);
          setStyleName(employee.Stylename);
        });
    }).catch(err => console.log(err))
  }).catch((err) => console.log(err))
    // console.log(response.data);
    // setEmployees(response.data);
    
  };
  useEffect(() => {
    
sessionStorage.getItem("payloadout") != "true"
? navigate("/")
:
    GetProduct();
  }, []);

  // Edit employee api calling
  const UpdateEmployee = () => {
    const formData = new FormData();
    formData.append("SKU", fname);

    // formData.append("lastname", lname);
    formData.append("BOW", email);
    formData.append("Brand", phone);
    formData.append("Description", joiningdate);
    formData.append("ID", shopname);

    formData.append("Factory", description);
    /////
    formData.append("LAST", Last);
    formData.append("Location1", Location1);
    formData.append("Location2", Location2);
    formData.append("Location3", Location3);

    formData.append("Location4", Location4);
    formData.append("Location5", Location5);
    formData.append("Location6", Location6);
    formData.append("Material1", Material1);
    formData.append("Material2", Material2);

    formData.append("Material3", Material3);
    formData.append("Material4", Material4);
    formData.append("Material5", Material5);
    formData.append("Material6", Material6);
    formData.append("Notes", Notes);

    formData.append("OutsoleColor", Outsolecolor);
    formData.append("Outsoletype", Outsoletype);
    formData.append("Price1", Price1);
    formData.append("Price2", Price2);
    formData.append("Price3", Price3);

    formData.append("SEASON", SEASON);
    formData.append("Bow", Bow);

    formData.append("Size1", Size1);
    formData.append("Size2", Size2);
    formData.append("Size3", Size3);
    formData.append("Stitch", Stitch);
    formData.append("StyleName", StyleName);

    formData.append("file", selectedFile.file);
    axios
      .post(`/update-product-data/${fname}`, formData)
      .then((response) => {
        if (response.status === 200) {
          setdone(false);
          console.log("success");
          setNotify({
            isOpen: true,
            message: `Updated Successfully`,
            type: "success",
          });
        }
        setTimeout(() => {
          navigate("/AllProducts");
        }, 1000);
      })
      .catch((err) => {
        setdone(false);
        console.log(err.response);
        setNotify({
          isOpen: true,
          message: `Update Failed`,
          type: "error",
        });
        // console.log(err.response);
      });
  };
  const HandleSubmit = (e) => {
    e.preventDefault();
    setdone(true);
    UpdateEmployee();
  };
  return (
    <div>
      <ResponsiveDrawer heading="Product Management" />
      <Box
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
        component="main"
      >
        <Toolbar />

        {!done ? (
          <CircularIndeterminate className="AddAgentloader" />
        ) : (
          <div className="for_form my-5 mx-4">
            <Form onSubmit={HandleSubmit}>
              <div className=" d-flex justify-content-between ">
                <h4 className="">Product&nbsp;View</h4>
                <div className="Accordian">
                  <div className="mx-2">
                    <div className="btncsv">
                      <button
                        className="btn1 mb-3 px-2 px-4"
                        onClick={() => navigate("/AllProducts")}
                      >
                        <small>Back</small>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* Form */}
              {/* <Row xs="1" sm="1" md="2" lg="3" xl="3" className="mt-3">
                <Col>
                  <label htmlFor="icon-button-file">
                    <Input
                      accept="image/*"
                      id="icon-button-file"
                      type="file"
                      onChange={handleChange}
                      readOnly
                    />
                    {!upload ? (
                      upload ? (
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                        >
                          <div className="addicon">
                            <AddIcon className="icon" />
                          </div>
                        </IconButton>
                      ) : (
                        <img
                          className="previewimg"
                          src={selectedFile.filepreview}
                          alt="Image"
                        />
                      )
                    ) : selectedFile == null ? (
                      <Avatar className="previewimg" />
                    ) : (
                      // <img src={Ellipse5} alt="Image" className={classes.xdiv} />
                      <img
                        src={selectedFile}
                        alt="Image"
                        className="previewimg"
                      />
                    )}
                  </label>

                  <div className={size ? "sizeshow" : "sizehide"}>
                    <small>File size excedded than 100KB</small>
                  </div>
                </Col>
              </Row> */}
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>ID</Form.Label>
                    <Form.Control
                      className="mb-5"
                      type="text"
                      placeholder="ID "
                      required
                      onChange={(e) => setShopname(e.target.value)}
                      value={shopname}
                      readOnly
                    />
                  </Form.Group>
                </Col>
                <Col></Col>
                <Col></Col>
              </Row>
              <Row xs={1} sm={1} md={2} lg={3} xl={3}>
                <Col>
                  <Form.Group className="mb-3" con>
                    <Form.Label>StyleName</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="style"
                      required
                      value={StyleName}
                      onChange={(e) => setStyleName(e.target.value)}
                      readOnly
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>SKU</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="SKU"
                      required
                      value={fname}
                      onChange={(e) => setFname(e.target.value)}
                      readOnly
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Article Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Article name"
                      required
                      value={lname}
                      onChange={(e) => setLname(e.target.value)}
                      readOnly
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row xs={1} sm={1} md={2} lg={3} xl={3}>
                <Col>
                  <Form.Group className="mb-3" con>
                    <Form.Label>StyleFactory</Form.Label>
                    <Form.Control
                      type="text"
                      required
                      value={StyleFactory}
                      onChange={(e) => setStyleFactory(e.target.value)}
                      readOnly
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Season</Form.Label>
                    <Form.Control
                      rows={3}
                      required
                      value={SEASON}
                      onChange={(e) => setSEASON(e.target.value)}
                      readOnly
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Factory</Form.Label>
                    <Form.Control
                      rows={3}
                      required
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      readOnly
                    />
                  </Form.Group>
                </Col>
                {/* <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Price1</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="price1"
                      required
                      value={Price1}
                      onChange={(e) => setPrice1(e.target.value)}
                      readOnly
                    />
                  </Form.Group>
                </Col> */}
              </Row>
              <Row xs={1} sm={1} md={2} lg={3} xl={3}>
                <Col>
                  <label htmlFor="exampleFormControlTextarea1">Color</label>
                  <textarea
                    value={Material1 + "/" + Material2 + "/" + Material3}
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    rows="3"
                    readOnly
                  />
                  {/* <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Color</Form.Label>
                    <Form.Control
                    type="text-area"
                      rows={3}
                      column={3}
                      required
                      value={Material1 +
                        "/" +
                        Material2 +
                        "/" +
                        Material3}
                      onChange={(e) => setOutsolecolor(e.target.value)}
                      readOnly
                    />
                  </Form.Group> */}
                </Col>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Brand</Form.Label>
                    <Form.Control
                      rows={3}
                      required
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      readOnly
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      rows={3}
                      required
                      value={joiningdate}
                      onChange={(e) => setJoiningdate(e.target.value)}
                      readOnly
                    />
                  </Form.Group>
                </Col>
                {/* <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Size2</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="size2"
                      required
                      value={Size2}
                      onChange={(e) => setSize2(e.target.value)}
                      readOnly
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Price2</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="price2"
                      required
                      value={Price2}
                      onChange={(e) => setPrice2(e.target.value)}
                      readOnly
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Size3</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="size3"
                      required
                      value={Size3}
                      onChange={(e) => setSize3(e.target.value)}
                      readOnly
                    />
                  </Form.Group>
                </Col> */}
              </Row>
              <Row className="mt-5">
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Material1</Form.Label>
                    <Form.Control
                      rows={3}
                      required
                      value={Material1}
                      onChange={(e) => setMaterial1(e.target.value)}
                      readOnly
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Location1</Form.Label>
                    <Form.Control
                      rows={3}
                      required
                      value={Location1}
                      onChange={(e) => setLocation1(e.target.value)}
                      readOnly
                    />
                  </Form.Group>
                </Col>
                <Col></Col>
                {/* <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Price3</Form.Label>
                    <Form.Control
                      rows={3}
                      required
                      value={Price3}
                      onChange={(e) => setPrice3(e.target.value)}
                      readOnly
                    />
                  </Form.Group>
                </Col> */}
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Material2</Form.Label>
                    <Form.Control
                      rows={3}
                      required
                      value={Material2}
                      onChange={(e) => setMaterial2(e.target.value)}
                      readOnly
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Loaction2</Form.Label>
                    <Form.Control
                      rows={3}
                      required
                      value={Location2}
                      onChange={(e) => setLocation2(e.target.value)}
                      readOnly
                    />
                  </Form.Group>
                </Col>
                <Col></Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Material3</Form.Label>
                    <Form.Control
                      rows={3}
                      required
                      value={Material3}
                      onChange={(e) => setMaterial3(e.target.value)}
                      readOnly
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Location3</Form.Label>
                    <Form.Control
                      rows={3}
                      required
                      value={Location3}
                      onChange={(e) => setLocation3(e.target.value)}
                      readOnly
                    />
                  </Form.Group>
                </Col>
                <Col></Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Material4</Form.Label>
                    <Form.Control
                      rows={3}
                      required
                      value={Material4}
                      onChange={(e) => setMaterial4(e.target.value)}
                      readOnly
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Location4</Form.Label>
                    <Form.Control
                      rows={3}
                      required
                      value={Location4}
                      onChange={(e) => setLocation4(e.target.value)}
                      readOnly
                    />
                  </Form.Group>
                </Col>
                <Col></Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Material5</Form.Label>
                    <Form.Control
                      rows={3}
                      required
                      value={Material5}
                      onChange={(e) => setMaterial5(e.target.value)}
                      readOnly
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Location5</Form.Label>
                    <Form.Control
                      rows={3}
                      required
                      value={Location5}
                      onChange={(e) => setLocation5(e.target.value)}
                      readOnly
                    />
                  </Form.Group>
                </Col>
                <Col></Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Material6</Form.Label>
                    <Form.Control
                      rows={3}
                      required
                      value={Material6}
                      onChange={(e) => setMaterial6(e.target.value)}
                      readOnly
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Location6</Form.Label>
                    <Form.Control
                      rows={3}
                      required
                      value={Location6}
                      onChange={(e) => setLocation6(e.target.value)}
                      readOnly
                    />
                  </Form.Group>
                </Col>
                <Col></Col>
              </Row>
              <Row xs={1} sm={1} md={2} lg={3} xl={3} className="mt-5">
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Stitch</Form.Label>
                    <Form.Control
                      rows={3}
                      required
                      value={Stitch}
                      onChange={(e) => setStitch(e.target.value)}
                      readOnly
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Bow</Form.Label>
                    <Form.Control
                      rows={3}
                      required
                      value={Bow}
                      onChange={(e) => setBow(e.target.value)}
                      readOnly
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Last</Form.Label>
                    <Form.Control
                      rows={3}
                      required
                      value={Last}
                      onChange={(e) => setLast(e.target.value)}
                      readOnly
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row xs={1} sm={1} md={2} lg={3} xl={3} className="mt-4">
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>OutsoleType</Form.Label>
                    <Form.Control
                      rows={3}
                      required
                      value={Outsoletype}
                      onChange={(e) => setOutsoletype(e.target.value)}
                      readOnly
                    />
                  </Form.Group>
                </Col>
                <Col></Col>
                <Col></Col>
              </Row>
              <Row xs={1} sm={1} md={2} lg={3} xl={3}>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Notes</Form.Label>
                    <Form.Control
                      rows={3}
                      required
                      value={Notes}
                      onChange={(e) => setNotes(e.target.value)}
                      readOnly
                    />
                  </Form.Group>
                </Col>
                <Col></Col>
                <Col></Col>
              </Row>
            </Form>
          </div>
        )}
      </Box>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};

export default ViewProduct;
