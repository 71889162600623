import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import CircularIndeterminate from "../Loader/Loader";
import Notification from "../AlertNotification/Message";
import usePasswordToggle from "../PasswordToggle/usePasswordToggle";
import usePasswordToggleX from "../PasswordToggle/usePasswordToggleX";
import axios from "axios";
import { usePasswordValidation } from "../hooks/usePasswordValidation";

const FormSignup = () => {
  const navigate = useNavigate();
  const [done, setdone] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  ////////
  const [password, setPassword] = useState({
    firstPassword: "",
    secondPassword: "",
  });

  const [validLength, hasNumber, upperCase, lowerCase, match, specialChar] =
    usePasswordValidation({
      firstPassword: password.firstPassword,
      secondPassword: password.secondPassword,
      requiredLength: 5,
      numberValidation: false,
    });

  const setFirst = (event) => {
    setPassword({ ...password, firstPassword: event.target.value });
  };
  const setSecond = (event) => {
    setPassword({ ...password, secondPassword: event.target.value });
  };
  /////
  const [PasswordInputType, ToggleIcon] = usePasswordToggle();
  const [PasswordInputTypex, ToggleIconx] = usePasswordToggleX();
  const [error, seterror] = useState("");

  const { email } = useParams();

  const handleSubmit = (e) => {
    e.preventDefault();
     {
      !match && !match ? alert("resolve the errors first") : PostApi();
    }
  };
  //
  // API calling using axios
  const PostApi = () => {
    setdone(true);

    console.log(sessionStorage.getItem("resetotp"));
    axios
      .post(
        "/reset-password",
        {
          email: email,
          password: password.firstPassword,
          repeatpassword: password.secondPassword,
        },
        {
          headers: {
            "x-auth-header": sessionStorage.getItem("resetotp"),
          },
        }
      )
      .then((response) => {
        console.log(response.status);
        if (response.status === 200) {
          setdone(false);
          setNotify({
            isOpen: true,
            message: "Password changed",
            type: "success",
          });
        }
        navigate("/");
        console.log(response.status);
      })
      .catch((err) => {
        setdone(false);
        console.log(err.response);
        setNotify({
          isOpen: true,
          message: "Password does not changed!",
          type: "error",
        });
        console.log(err);
      });
  };
  return (
    <div className="Main__section text-center">
      <div className="forBg__image">
        <div className="for__inputs">
          <div className="icon22 mb-3">
            <h4>New&nbsp;Password</h4>
          </div>
          <form onSubmit={handleSubmit} className="form">
            <Form.Control
              className="for__input mb-3 py-2"
              placeholder="New Password"
              type={PasswordInputType}
              name="password"
              // value={password}
              onChange={setFirst}
              required
            />
            <span className="password-toogle-iconx">{ToggleIcon}</span>
            {/* {errors.password && (
              <small style={{ color: "red" }}>{errors.password}</small>
            )} */}
            <Form.Control
              className="for__input my-3 py-2"
              placeholder="Confirm password"
              type={PasswordInputTypex}
              name="password2"
              // value={confirmpassword}
              onChange={setSecond}
            />
            <span className="password-toogle-iconx">{ToggleIconx}</span>

            <div>
              <ul>
                Match:{" "}
                {match ? (
                  <span style={{ color: "green" }}>True</span>
                ) : (
                  <span style={{ color: "red" }}>False</span>
                )}
              </ul>
            </div>
            <small style={{ color: "red" }}>{error}</small>
            {done ? (
              <CircularIndeterminate className="loginloader" />
            ) : (
              <button type="submit" className="Login__button2 py-2">
                Create
              </button>
            )}
          </form>
        </div>

        <Notification notify={notify} setNotify={setNotify} />
      </div>
    </div>
  );
};

export default FormSignup;
