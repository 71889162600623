import React from "react";

export default function DateTime() {
  const showdate = new Date();

  const dt = showdate.toDateString();
  const displaydate =
    showdate.getHours() +
    ":" +
    showdate.getMinutes() +
    ":" +
    showdate.getSeconds();
  return (
    <div>
      {dt}-{displaydate}
    </div>
  );
}
