import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Notification from "../AlertNotification/Message";
import CircularIndeterminate from "../Loader/Loader";

const OTPverification = (props) => {
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;
    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };
  const [done, setdone] = useState(false);
  const navigate = useNavigate();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const { email } = useParams();
  // console.log(email);
  // Handle submit
  const handleSubmit = (e) => {
    e.preventDefault();
    PostApi();
    setdone(true);
  };
  // Handle reset

  //Handling Reset
  const handleResend = () => {
    axios
      .post("/send-verification", {
        email: email,
      })
      .then((response) => {
        setcounter(59);
        console.log(response.status);
        if (response.status === 200) {
          setNotify({
            isOpen: true,
            message: "OTP send succesfully",
            type: "success",
          });
        }
        setTimeout(() => {}, 2000);
      })
      .catch((err) => {
        console.log(err.status);
        setNotify({
          isOpen: true,
          message: `Sending Fail ${err}`,
          type: "error",
        });
        // console.log(err);
      });
  };
  // console.log(otpvalue);

  // API calling using axios
  const PostApi = () => {
    const code = otp.join("");
    axios
      .post("/verify-otp", {
        email: email,
        otp: code,
      })
      .then((response) => {
        console.log(response);
        console.log(response.data);
        if (response.data.valid === true) {
          setdone(false);
          setNotify({
            isOpen: true,
            message: "verified Succcessfull",
            type: "success",
          });
          ConfirmPassword(email);
          sessionStorage.setItem("resetotp", response.headers["x-auth-header"]);
          console.log(response.status);
        }
        // history.push("/ConfirmPassword");
      })
      .catch((err) => {
        setdone(false);
        console.log(err.response);
        setNotify({
          isOpen: true,
          message: `${err.response.request.statusText}`,
          type: "error",
        });
        console.log(err);
      });
  };
  const ConfirmPassword = (email) => {
    // console.log(email);
    setTimeout(() => {
      navigate(`/CreateNewPassword/${email}`);
    }, 1000);
  };
  const [counter, setcounter] = useState(59);

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setcounter(counter - 1), 1000);
    return () => {
      clearInterval(timer);
    };
  }, [counter]);
  return (
    <div className="Main__section text-center">
      <div className="forBg__image">
        <div className="for__inputs">
          <div>
            <h4 className="icon22">OTP&nbsp;Verification</h4>
            <p className="">Enter the code to verify your account.</p>
            {/* {console.log(email)} */}
          </div>
          <div className="row">
            <div className="col">
              <div className="d-flex justify-content-center">
                <div className="row">
                  <div className="col text-center">
                    {otp.map((data, index) => {
                      return (
                        <input
                          className="otp-field"
                          type="text"
                          name="otp"
                          maxLength="1"
                          key={index}
                          value={data}
                          onChange={(e) => handleChange(e.target, index)}
                          onFocus={(e) => e.target.select()}
                        />
                      );
                    })}
                    <p className="text-white my-3">
                      OTP Entered - {otp.join("")}
                    </p>
                    {done ? (
                      <CircularIndeterminate className="verifyloader" />
                    ) : (
                      <p>
                        <button
                          className="btn btn-secondary me-2"
                          onClick={(e) => setOtp([...otp.map((v) => "")])}
                        >
                          Clear
                        </button>
                        <button
                          className="btn btn-danger"
                          onClick={handleSubmit}
                        >
                          Verify OTP
                        </button>
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <small>Didn’t receive a code?&nbsp;</small>
                {counter === 0 ? (
                  <p className="for__link" onClick={handleResend}>
                    Resend
                  </p>
                ) : (
                  <div style={{ color: "#fff" }}>00:{counter}</div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Notification notify={notify} setNotify={setNotify} />
      </div>
    </div>
  );
};

export default OTPverification;
