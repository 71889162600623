
import ActionButton from './actionbutton'
import Button from "./Buttons";

const Controls = {
    
    ActionButton,
    Button
}

export default Controls;