/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import ResponsiveDrawer from "../Drawer/drawer";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { Form, Row, Col } from "react-bootstrap";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import { BiSearch } from "react-icons/bi";
import Notification from "../AlertNotification/Message";
import ConfirmDialog from "../AlertNotification/confirmdialog";
import CircularIndeterminate from "../Loader/Loader";
////////////
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
////////////
const drawerWidth = 240;
const columns = [
  // {
  //   id: "img",
  //   label: "Avatar",
  //   minWidth: 100,
  //   align: "left",
  // },
  {
    id: "density",
    label: "Store Name",
    minWidth: 100,
    align: "left",
    format: (value) => value.toFixed(2),
  },
  {
    id: "name",
    label: "Total Pairs Sold",
    minWidth: 100,
    align: "left",
  },
  {
    id: "population",
    label: "Total Sold Amount",
    minWidth: 100,
    align: "left",
  },
];
//
const columnsTwo = [
  {
    id: "img",
    label: "",
    minWidth: 130,
    align: "left",
  },

  {
    id: "name",
    label: "SubTotal of Pairs Sold",
    minWidth: 100,
    align: "left",
  },

  {
    id: "population",
    label: "SubTotal of Sold Amount",
    minWidth: 100,
    align: "left",
  },
];
//
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

function SalesByStore() {
  const navigate = useNavigate();
  //
  //
  const [show, setShow] = useState(true);
  const SortBy = () => {
    setShow(!show);
  };
  // -------//
  const HightTOLow = () => {
    customers.sort((c, d) => d.PairsSold - c.PairsSold);
    setShow(true);
  };
  // -------//
  const LowToHigh = () => {
    customers.sort((f, g) => f.PairsSold - g.PairsSold);
    setShow(true);
  };
  const storeName = () => {
    customers.sort((a, b) => a.storeName.localeCompare(b.storeName));
    setShow(true);

    // customers.sort((a, b) => a.sku.localeCompare(b.sku));
  };
  // -------//
  const SortDescending = () => {
    customers.sort((c, d) => c.PairsSold - d.PairsSold);
    setShow(true);
  };
  //
  //
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  //
  const [One, setOne] = React.useState(null);
  //
  //   const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [filter, setfilter] = useState("");
  const [customers, setcustomers] = useState([]);
  const [done, setdone] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(2000);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [tokenauth, settokenauth] = useState("");

  useEffect(() => {
    axios
      .post("get-token", {
        id: sessionStorage.getItem("token_id"),
      })
      .then((response) => {
        settokenauth(response.data.token);
      })
      .catch((err) => console.log(err));
  }, []); 

  useEffect(() => {
    GetCustomers();
    setdone(true);

    // sessionStorage.setItem("heading", "Reports")
  }, []);

  const commafunc = (amount) => {
    const optionsa = { style: "currency", currency: "USD" };
    return amount.toLocaleString("en-US", optionsa);
  };
  const [totalamoount, settotalamoount] = useState(0);
  const GetCustomers = () => {
    axios
      .post("get-token", {
        id: sessionStorage.getItem("token_id"),
      })
      .then((response) => {
        settokenauth();
        axios
          .post(
            `/sales-by-storename-report`,
            {},
            {
              headers: {
                "x-auth-header": response.data.token,
              },
            }
          )
          .then((response) => {
            // console.log(response);

            setcustomers(response.data);
            const sum = response.data.reduce(
              (accumulator, currentValue) =>
                accumulator + currentValue.Totalsales,
              0
            );
            const options = { style: "currency", currency: "USD" };

            settotalamoount(sum?.toLocaleString("en-US", options));
            setdone(false);
          })
          .catch((err) => {
            console.log(err.response);
            setdone(false);
          });
      })
      .catch((err) => console.log(err));
  };

  //
  //
  //
  return (
    <div className="tableX">
      <ResponsiveDrawer heading="Reports" />
      <Box
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
        component="main"
      >
        <Toolbar />

        <div className="position-relative mt-5">
          <div className="d-flex justify-content-between mx-4">
            <div>
              <Button
                id="demo-customized-button"
                aria-controls="demo-customized-menu"
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                // variant="contained"
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon style={{ color: "#f00e0e" }} />}
              >
                <span className="fw-bolder text-dark">
                  Total&nbsp;Sales&nbsp;by&nbsp;Store&nbsp;Name
                </span>
              </Button>
              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                  "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <MenuItem
                  disableRipple
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  onClick={() => {
                    navigate("/AllStyleSold");
                  }}
                >
                  All&nbsp;Styles&nbsp;Sold
                </MenuItem>
                <MenuItem
                  disableRipple
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  onClick={() => {
                    navigate("/TotalSales");
                  }}
                >
                  Total&nbsp;Sales&nbsp;by&nbsp;Date
                </MenuItem>
                <MenuItem
                  disableRipple
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  onClick={() => {
                    navigate("/SalesByStyleFactory");
                  }}
                >
                  Total&nbsp;Sales&nbsp;by&nbsp;StyleFactory
                </MenuItem>
                <MenuItem
                  disableRipple
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  onClick={() => {
                    navigate("/StoreOrderStatus");
                  }}
                >
                  Store&nbsp;Order&nbsp;Status
                </MenuItem>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem
                  disableRipple
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  onClick={() => {
                    navigate("/SalesByStore");
                  }}
                >
                  Total&nbsp;Sales&nbsp;by&nbsp;Store&nbsp;Name
                </MenuItem>
                <MenuItem
                  disableRipple
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  onClick={() => {
                    navigate("/FactoryStyleByStoreName");
                  }}
                >
                  Factory&nbsp;Style&nbsp;by&nbsp;Store&nbsp;Name
                </MenuItem>
                <MenuItem
                  disableRipple
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  onClick={() => {
                    navigate("/TotalPairsByStyle");
                  }}
                >
                  Total&nbsp;Pairs&nbsp;by&nbsp;Style&nbsp;Name
                </MenuItem>
              </StyledMenu>
            </div>

            <div className="d-flex">
              <div className="position-relative ms-3">
                <Form>
                  <Form.Control
                    placeholder="Search by Store Name"
                    type="search"
                    className="input_field"
                    onChange={(e) => setfilter(e.target.value)}
                  />
                  <BiSearch className="search_Agent1" />
                </Form>
              </div>
              <div className="ms-3">
                <p className="forDropDown" onClick={() => SortBy()}>
                  Sort&nbsp;by
                  <KeyboardArrowDownIcon style={{ color: "#f00e0e" }} />
                </p>
                <div className={show ? "sort" : "sort-by"}>
                  <ul>
                    <li onClick={() => HightTOLow()}>
                      Pair&nbsp;Sold&nbsp;
                      <ArrowUpwardIcon className="UpArrow" />
                    </li>
                    <li onClick={() => LowToHigh()}>
                      Pair&nbsp;Sold&nbsp;
                      <ArrowDownwardIcon className="UpArrow" />
                    </li>
                    <li onClick={() => storeName()}>
                      <small>Store Name</small>
                    </li>
                    <li onClick={() => HightTOLow()}>
                      Amount
                      <ArrowUpwardIcon className="UpArrow" />
                    </li>
                    <li onClick={() => LowToHigh()}>
                      Amount
                      <ArrowDownwardIcon className="UpArrow" />
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                <ReactHTMLTableToExcel
                  id="test-table-xls-button"
                  className="ms-3 py-2 px-3 ExcelDownlad download-table-xls-button"
                  table="table-to-xls"
                  filename="tablexls"
                  sheet="tablexls"
                  buttonText="Export&nbsp;CSV"
                  size="small"
                />
              </div>
            </div>
          </div>
          {done ? (
            <CircularIndeterminate className="reportsales" />
          ) : (
            <div className="for__table m-4">
              <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer sx={{ maxHeight: 730 }}>
                  <Table
                    stickyHeader
                    aria-label="sticky table"
                    id="table-to-xls"
                  >
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {customers.length > 0 ? (
                        customers
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          // eslint-disable-next-line array-callback-return
                          .filter((admin) => {
                            if (filter === "") {
                              return customers;
                            } else if (
                              admin.storeName &&
                              admin.storeName
                                .toString()
                                .toLowerCase()
                                .includes(filter.toString().toLowerCase())
                              //      ||
                              // (admin.lastname &&
                              //   admin.lastname
                              //     .toString()
                              //     .toLowerCase()
                              //     .includes(filter.toString().toLowerCase()))
                            ) {
                              return customers;
                            }
                          })
                          .map((admin) => {
                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={admin._id}
                              >
                                <TableCell>{admin.storeName}</TableCell>
                                <TableCell>{admin.PairsSold}</TableCell>
                                <TableCell>
                                  {commafunc(admin.Totalsales)}
                                </TableCell>
                              </TableRow>
                            );
                          })
                      ) : (
                        <h5 className="text-center mt-5 fw-bolder">No Data</h5>
                      )}
                    </TableBody>
                  </Table>
                  <Table
                    stickyHeader
                    aria-label="sticky table"
                    id="table-to-xls"
                  >
                    <TableHead>
                      <TableRow>
                        {columnsTwo.map((column) => (
                          <TableCell
                            className="fw-bolder"
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow hover role="checkbox" tabIndex={-1}>
                        <TableCell className="text-light"></TableCell>
                        <TableCell>
                          {" "}
                          {customers.reduce((total, item) => {
                            return +total + +item?.PairsSold?.toFixed(2);
                          }, 0)}
                        </TableCell>
                        <TableCell>
                          {/* {console.log(customers)} */}
                          {totalamoount}
                          {/* {customers.reduce(
                            (accumulator, currentValue) =>
                              accumulator + currentValue.Totalsales,
                            0
                          )} */}
                          {/* {customers.reduce((total, item) => {
                            return (
                              +total +
                              +item?.Totalsales?.toFixed(2).toLocaleString(
                                "en-US",
                                { style: "currency", currency: "USD" }
                              )
                            );
                          }, 0)} */}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  className="mx-5 mt-3 px-5"
                  rowsPerPageOptions={[2000, 5000, 8000]}
                  component="div"
                  count={customers.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </div>
          )}
        </div>
      </Box>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </div>
  );
}

export default SalesByStore;
