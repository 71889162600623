import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import "../Dashboard/dashboard.css";
const useStyles = makeStyles({
  carad: {
    color: "#4F6367",
    "&:hover": {
      backgroundColor: "#4F6367",
      color: "#fff",

      transition: "0.5s ease",
      cursor: "pointer",
    },
  },
});

export default function SecondCard() {
  const Data = [
    {
      text: "Orders",
      quantity: 52,
    },
    {
      text: "Sales",
      quantity: "$32",
    },
  ];

  const classes = useStyles();
  return (
    <div className="cards">
      {Data.map((item, index) => {
        return (
          <div key={index} className="caradx">
            <Card className="carad">
              <CardContent>
                <Typography sx={{ mb: 1.5 }} className="txt">
                  {item.text}
                </Typography>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{ fontWeight: "bold" }}
                  className="txt"
                >
                  {item.quantity}K
                </Typography>
              </CardContent>
            </Card>
          </div>
        );
      })}
    </div>
  );
}
