import React, { useState, useEffect, useContext } from "react";

import { UserContext } from "../../App";

import axios from "axios";
import { Form, Col, Row } from "react-bootstrap";
import ResponsiveDrawer from "../Drawer/drawer";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Notification from "../AlertNotification/Message";
import CircularIndeterminate from "../Loader/Loader";

const drawerWidth = 240;
const AddCustomer = () => {
  const navigate = useNavigate();
  // const [selectedFile, setSelectedFile] = useState({
  //   file: [],
  //   filepreview: null,
  // });
  const [upload, setupload] = useState(false);
  const [size, setsize] = useState(false);
  ////////
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [emailsecond, setEmailsecond] = useState("");
  const [cell, setCell] = useState("");
  const [celltwo, setCelltwo] = useState("");

  const [storename, setStorename] = useState("");
  const [phone, setPhone] = useState("");
  const [address1, setaddress1] = useState("");
  const [address2, setaddress2] = useState("");
  const [city, setcity] = useState("");
  const [state, setstate] = useState("");
  const [zipcode, setzipcode] = useState("");
  const [cid, setcid] = useState("");
  const [street, setstreet] = useState("");
  const [country, setCountry] = useState("");
  /////////
  const [pfname, setPfname] = useState("");
  const [plname, setPlname] = useState("");
  const [pemail, setPemail] = useState("");
  const [pemailsecond, setPemailsecond] = useState("");
  const [pphone, setPphone] = useState("");

  const [pcell, setPcell] = useState("");
  ////
  const [ocfname, setOcfname] = useState("");
  const [oclname, setOclname] = useState("");
  const [ocemail, setOcemail] = useState("");
  const [ocemailsecond, setOcemailsecond] = useState("");
  const [ocphone, setOcphone] = useState("");

  const [occell, setOccell] = useState("");
  const [baddress1, setBaddress1] = useState("");
  const [baddress2, setBaddress2] = useState("");
  const [bcity, setBcity] = useState("");
  const [bstate, setBstate] = useState("");
  const [bzipcode, setBzipcode] = useState("");
  const [bstreet, setBstreet] = useState("");
  const [bcountry, setBcountry] = useState("");
  const [done, setdone] = useState(false);
  const [showpartner, setshowpartner] = useState(false);
  const [showcontact, setshowcontact] = useState(false);

  const ShowPartner = () => {
    setshowpartner(!showpartner);
  };
  const ShowOtherContact = () => {
    setshowcontact(!showcontact);
  };
  // ///////
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  // const HandleChange = (event) => {
  //   if (event.target.files[0].size > 100 * 1024) {
  //     setsize(true);
  //   } else if (event.target.files[0].size <= 100 * 1024) {
  //     setSelectedFile({
  //       ...selectedFile,
  //       file: event.target.files[0],
  //       filepreview: URL.createObjectURL(event.target.files[0]),
  //     });
  //     setsize(false);
  //     setupload(true);
  //   }
  // };
  // API calling
  const AddCustomer = () => {
    const formData = new FormData();
    formData.append("customerid", cid);
    formData.append("firstname", fname);
    formData.append("lastname", lname);
    formData.append("email_1", email);
    formData.append("email_2", emailsecond);
    formData.append("StoreName", storename);
    formData.append("cell_1", cell);
    formData.append("cell_2", celltwo);
    formData.append("StorePhone", phone);

    ///formData.append("firstname", fname);

    formData.append("sc_firstname", pfname);
    formData.append("sc_lastname", plname);
    formData.append("sc_cell_2", pcell);
    formData.append("sc_email", pemail);
    formData.append("sc_OfficePhone", pemailsecond);
    formData.append("sc_cell_1", pphone);
    formData.append("tc_firstname", ocfname);
    formData.append("tc_lastname", oclname);
    formData.append("tc_email", ocemail);
    formData.append("tc_OfficePhone", ocemailsecond);
    formData.append("tc_cell_1", ocphone);
    formData.append("tc_cell_2", occell);
    ////
    formData.append("s_address1", address1);
    formData.append("s_address2", address2);
    formData.append("s_street", street);
    formData.append("s_postcode", zipcode);
    formData.append("s_city", city);
    formData.append("s_state", state);
    formData.append("s_country", country);
    ///////
    formData.append("b_address1", baddress1);
    formData.append("b_address2", baddress2);
    formData.append("b_street", bstreet);
    formData.append("b_postcode", bzipcode);
    formData.append("b_city", bcity);
    formData.append("b_state", bstate);
    formData.append("b_country", bcountry);
    axios
      .post("/add-customer", formData, {

        headers: {

          "x-auth-header": tokenauth && tokenauth
        },
      })
      .then((response) => {
        console.log(response.status);
        if (response.status === 200) {
          setdone(false);
          setNotify({
            isOpen: true,
            message: "Added Succcessfully",
            type: "success",
          });
        }
        setTimeout(() => {
          navigate("/AllCustomers");
        }, 1000);
      })
      .catch((err) => {
        setdone(false);
        console.log(err.response);
        setNotify({
          isOpen: true,
          message: `${err.response.data.message}`,
          type: "error",
        });
        console.log(err);
      });
  };
  const HandleSubmit = (e) => {
    e.preventDefault();
    setdone(true);
    AddCustomer();
  };
  useEffect(() => {
    sessionStorage.getItem("payloadout") != "true"
      ? navigate("/")
      : console.log("Logged In")
  }, [])
  const [tokenauth, settokenauth] = useState("")

  useEffect(() => {

    axios.post("/get-token", {
         id: sessionStorage.getItem("token_id")
       }).then((res) => {
         settokenauth(res.data.token)
       }).catch(err => console.log(err.response))
     }, []);
  return (
    <div>
      <ResponsiveDrawer heading="Customer Management" />
      <Box
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
        component="main"
      >
        <Toolbar />
        {done ? (
          <CircularIndeterminate className="AddAgentloader" />
        ) : (
          <div className="for_form my-5 mx-4">
            <Form onSubmit={HandleSubmit}>
              <div className=" d-flex  justify-content-between mt-5  pt-3 pr-3 pb-3 btnz">
                <h5 className="">Add&nbsp;New&nbsp;Customer</h5>
                <div className="btnp">
                  <button
                    className="btn1 mx-2"
                    onClick={() => navigate("/AllCustomers")}
                  >
                    Cancel
                  </button>
                  <button className="btn2" type="submit">
                    Add
                  </button>
                </div>
              </div>
              {/* Form */}
              {/* <Row xs="1" sm="1" md="2" lg="3" xl="3" className="mt-3">
                <Col>
                  <label htmlFor="icon-button-file">
                    <Input
                      accept="image/*"
                      id="icon-button-file"
                      type="file"
                      // value={selectedFile}
                      onChange={HandleChange}
                      
                    />

                    {!upload ? (
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                      >
                        <div className="addicon">
                          <AddIcon className="icon" />
                        </div>
                      </IconButton>
                    ) : (
                      <img
                        className="previewimg mb-3"
                        src={selectedFile.filepreview}
                        alt="Image"
                      />
                    )}

                    <br />
                  </label>

                  <div className={size ? "sizeshow" : "sizehide"}>
                    <small>File size excedded than 100KB</small>
                  </div>
                </Col>
              </Row> */}
              <Row xs={1} sm={1} md={2} lg={3} xl={3} className="mt-3">
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Customer ID</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Customer ID"
                      onChange={(e) => setcid(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="First Name"
                      onChange={(e) => setFname(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Last Name"
                      onChange={(e) => setLname(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row xs={1} sm={1} md={2} lg={3} xl={3}>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Store Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Store Name"
                      onChange={(e) => setStorename(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Email </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Email"
                      required
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Email </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Other Email"
                      //
                      onChange={(e) => setEmailsecond(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row xs={1} sm={1} md={2} lg={3} xl={3}>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Store Phone</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Store Phone"
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Cell 1</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Cell#"
                      onChange={(e) => setCell(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Cell 2</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Cell#"
                      onChange={(e) => setCelltwo(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>

              {/* /////////// ADD PARTNER  //////////////// */}
              <Row xs="1" sm="1" md="2" lg="3"></Row>
              <div className=" d-flex  justify-content-between mt-3">
                <div className="d-flex flex-row  mb-4">
                  <Form.Check
                    aria-label="option 1"
                    className="checkmargin"
                    onChange={() => ShowPartner()}
                  />
                  <small className="ms-3 mt-1">2nd Contact</small>
                </div>
              </div>
              <div className={!showpartner ? "showpartnercontact" : null}>
                <Row className="mt-3">
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="First Name"
                        onChange={(e) => setPfname(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Last Name"
                        onChange={(e) => setPlname(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Email"
                        onChange={(e) => setPemail(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Office</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Office#"
                        //
                        onChange={(e) => setPemailsecond(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Cell 1</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Cell#"
                        onChange={(e) => setPphone(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Cell 2</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Cell#"
                        onChange={(e) => setPcell(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>
              {/* ///////////////  ADD OTHER CONTACT ///////////////////// */}

              <div className=" d-flex  justify-content-between mt-3">
                <div className="d-flex flex-row  mb-4">
                  <Form.Check
                    aria-label="option 1"
                    className="checkmargin"
                    onChange={() => ShowOtherContact()}
                  />
                  <small className="ms-3 mt-1">3rd Contact</small>
                </div>
                <div></div>
              </div>
              <div className={!showcontact ? "showpartnercontact" : null}>
                <Row className="">
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="First Name"
                        onChange={(e) => setOcfname(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Last Name"
                        onChange={(e) => setOclname(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Email"
                        onChange={(e) => setOcemail(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Office</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Office#"
                        //
                        onChange={(e) => setOcemailsecond(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Cell 1</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Cell#"
                        onChange={(e) => setOcphone(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Cell 2</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Cell#"
                        onChange={(e) => setOccell(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>
              {/* ///
              ///
              // */}

              {/* //////////// SHIPING ADRESS ////////////// */}
              <Row xs="1" sm="1" md="2" lg="3"></Row>
              <div className=" d-flex  justify-content-between mb-4 mt-4">
                <h5 className="">Shipping&nbsp;Address</h5>
              </div>
              <Row xs={1} sm={1} md={2} lg={3} xl={3}>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Adress 1</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Adress 1"
                      onChange={(e) => setaddress1(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Adress 2</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Address 2"
                      onChange={(e) => setaddress2(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Street</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Street"
                      onChange={(e) => setstreet(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row xs={1} sm={1} md={2} lg={3} xl={3}>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Zip Code</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Zip Code"
                      onChange={(e) => setzipcode(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="City"
                      onChange={(e) => setcity(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>State</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="State"
                      onChange={(e) => setstate(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row xs={1} sm={1} md={2} lg={3} xl={3}>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Country</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Country"
                      onChange={(e) => setCountry(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              {/* //////////// Billing ADRESS ////////////// */}
              <Row xs="1" sm="1" md="2" lg="3"></Row>

              <div className=" d-flex  justify-content-between mb-4  mt-4">
                <h5 className="">Billing&nbsp;Address</h5>
              </div>
              <Row xs={1} sm={1} md={2} lg={3} xl={3}>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Adress 1</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Adress 1"
                      onChange={(e) => setBaddress1(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Adress 2</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Address 2"
                      onChange={(e) => setBaddress2(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Street</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Street"
                      onChange={(e) => setBstreet(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row xs={1} sm={1} md={2} lg={3} xl={3}>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Zip Code</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Zip Code"
                      onChange={(e) => setBzipcode(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="City"
                      onChange={(e) => setBcity(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>State</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="State"
                      onChange={(e) => setBstate(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row xs={1} sm={1} md={2} lg={3} xl={3}>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Country</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Country"
                      onChange={(e) => setBcountry(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </div>
        )}
      </Box>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};

export default AddCustomer;
