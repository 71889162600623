/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import ResponsiveDrawer from "../Drawer/drawer";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { Form, Row, Col } from "react-bootstrap";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import CircularIndeterminate from "../Loader/Loader";
////////////
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { BiSearch } from "react-icons/bi";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
//
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
// import AllStyleSold from "./AllStyleSold";
////////////
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
const drawerWidth = 240;
const columns = [
  {
    id: "img",
    label: "Store Name",
    minWidth: 100,
    align: "left",
  },

  {
    id: "",
    label: "Total Pairs Sold",
    minWidth: 100,
    align: "left",
  },

  {
    id: "population",
    label: "Total Amount Sold",
    minWidth: 100,
    align: "left",
  },
];
const columnsTwo = [
  {
    id: "img",
    label: "",
    minWidth: 135,
    align: "left",
  },

  {
    id: "name",
    label: "SubTotal of Pairs Sold",
    minWidth: 100,
    align: "left",
  },

  {
    id: "population",
    label: "SubTotal of Amount Sold",
    minWidth: 100,
    align: "left",
  },
];
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

function TotalSales() {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElx, setAnchorElx] = React.useState(null);
  const open = Boolean(anchorEl);
  const openx = Boolean(anchorElx);
   const commafunc = (amount) => {
     const optionsa = { style: "currency", currency: "USD" };
     return amount.toLocaleString("en-US", optionsa);
   };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClickx = (event) => {
    setAnchorElx(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClosex = () => {
    setAnchorElx(null);
  };
  const [filter, setfilter] = useState("");
  const [customers, setcustomers] = useState([]);
  const [Mesage, setMesage] = useState("");
  const [done, setdone] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(2000);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [show, setShow] = useState(true);
  const SortBy = () => {
    setShow(!show);
  };

  // -------//
  const storeName = () => {
    customers.sort((a, b) => a.storeName.localeCompare(b.storeName));
    setShow(true);

    // customers.sort((a, b) => a.sku.localeCompare(b.sku));
  };

  // -------//
  const HightTOLow = () => {
    customers.sort((c, d) => d.PairsSold - c.PairsSold);
    setShow(true);
  };
  // -------//
  const LowToHigh = () => {
    customers.sort((f, g) => f.PairsSold - g.PairsSold);
    setShow(true);
  };
  // -------//
  const AmountUp = () => {
    customers.sort((x, z) => z.Totalsales - x.Totalsales);
    setShow(true);
  };
  // -------//
  const AmountDown = () => {
    customers.sort((i, j) => i.Totalsales - j.Totalsales);
    setShow(true);
  };
  //////////////////////////
  //////////////////////////
  //////////////////////////
  const [StartDate, setStartDate] = useState("");
  const [EndDate, setEndDate] = useState("");
  let arr = StartDate.split("/");
  let sd = arr[1] + "/" + arr[0] + "/" + [2];

  const GetDate = async () => {
    console.log(StartDate);
    console.log(EndDate);
    const response = await axios.post(
      `/sales-by-date-report`,
      {
        StartDate: StartDate,
        EndDate: EndDate,
      },
      {
        headers: {
          "x-auth-header": tokenauth && tokenauth,
        },
      }
    );
    setcustomers(response.data);
    setdone(false);
    // console.log(response.data);
    setMesage(response.data.message);
  };
  const [tokenauth, settokenauth] = useState("");

  useEffect(() => {
    axios
      .post("get-token", {
        id: sessionStorage.getItem("token_id"),
      })
      .then((response) => {
        settokenauth(response.data.token);
        axios
          .post(
            "/sales-report",
            {},
            {
              headers: {
                "x-auth-header": response.data.token,
              },
            }
          )
          .then((response) => {
            // console.log(response.data);

          })
          .catch((err) => {
            console.log(err.response);
          });
      })
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    sessionStorage.setItem("id", "7");
  }, []);
  // const handlesubmit = (e) => {
  //    value == "" ? alert("select start date") : GetDate();
  // };
  const handlesubmit = (e) => {
    e.preventDefault();
    setdone(true);

    GetDate();
  };

  return (
    <div className="tableX">
      <ResponsiveDrawer heading="Reports" />
      <Box
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
        component="main"
      >
        <Toolbar />

        <div className="position-relative">
          <div className="d-flex justify-content-between mx-4 mt-5 mb-4">
            <div>
              <Button
                id="demo-customized-button"
                aria-controls="demo-customized-menu"
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                // variant="contained"
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon style={{ color: "#f00e0e" }} />}
              >
                <span className="fw-bolder text-dark">
                  Total&nbsp;Sales&nbsp;by&nbsp;Date
                </span>
              </Button>
              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                  "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <MenuItem
                  disableRipple
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  onClick={() => {
                    navigate("/AllStyleSold");
                  }}
                >
                  All&nbsp;Styles&nbsp;Sold
                </MenuItem>
                <MenuItem
                  disableRipple
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  onClick={() => {
                    navigate("/TotalSales");
                  }}
                >
                  Total&nbsp;Sales&nbsp;by&nbsp;Date
                </MenuItem>
                <MenuItem
                  disableRipple
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  onClick={() => {
                    navigate("/SalesByStyleFactory");
                  }}
                >
                  Total&nbsp;Sales&nbsp;by&nbsp;StyleFactory
                </MenuItem>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem
                  disableRipple
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  onClick={() => {
                    navigate("/StoreOrderStatus");
                  }}
                >
                  Store&nbsp;Order&nbsp;Status
                </MenuItem>
                <MenuItem
                  disableRipple
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  onClick={() => {
                    navigate("/SalesByStore");
                  }}
                >
                  Total&nbsp;Sales&nbsp;by&nbsp;Store&nbsp;Name
                </MenuItem>
                <MenuItem
                  disableRipple
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  onClick={() => {
                    navigate("/FactoryStyleByStoreName");
                  }}
                >
                  Factory&nbsp;Style&nbsp;by&nbsp;Store&nbsp;Name
                </MenuItem>
                <MenuItem
                  disableRipple
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  onClick={() => {
                    navigate("/TotalPairsByStyle");
                  }}
                >
                  Total&nbsp;Pairs&nbsp;by&nbsp;Style&nbsp;Name
                </MenuItem>
              </StyledMenu>
            </div>
            <div className="w-50">
              <div className="position-relative">
                <Form>
                  <Form.Control
                    placeholder="Search by Store Name"
                    type="search"
                    className="input_field"
                    onChange={(e) => setfilter(e.target.value)}
                  />
                  <BiSearch className="search_Agent1" />
                </Form>
              </div>
            </div>
          </div>
          {/*  */}
          <div className="d-flex justify-content-between resclass">
            <div className="EndDateWidth for_DateTime ms-4">
              <Form onSubmit={handlesubmit}>
                <div className="d-flex">
                  <div>
                    <Form.Control
                      placeholder="date/month/year"
                      className=""
                      type="date"
                      label="Start Date"
                      name="startdate"
                      required
                      onChange={(e) => {
                        let arr = e.target.value.split("-");
                        setStartDate(arr[2] + "/" + arr[1] + "/" + arr[0]);

                        // var day = arr[2].split("");
                        // var month = arr[1].split("");
                        // var dayx = parseInt(arr[2], 10) < 10 ? day[1] : arr[2];
                        // var monthx =
                        //   parseInt(arr[1], 10) < 10 ? month[1] : arr[1];
                        // setStartDate(
                        //   dayx.toString() +
                        //     "/" +
                        //     monthx.toString() +
                        //     "/" +
                        //     arr[0]
                        // );
                      }}
                    />
                    {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Stack spacing={3}>
                          <DesktopDatePicker
                            label="Start Date"
                            inputFormat="MM/dd/yyyy"
                            value={value}
                            requied
                            onChange={handleChange}
                            renderInput={(params) => (
                              <TextField {...params} size="small" />
                            )}
                          />
                        </Stack>
                      </LocalizationProvider> */}
                    {/* {console.log(value)} */}
                  </div>
                  <div>
                    <Form.Control
                      placeholder="date/month/year"
                      type="date"
                      className="mx-2"
                      label="End Date"
                      name="enddate"
                      required
                      onChange={(e) => {
                        let arr = e.target.value.split("-");
                        setEndDate(arr[2] + "/" + arr[1] + "/" + arr[0]);
                        // let arr = e.target.value.split("-");

                        // var day = arr[2].split("");
                        // var month = arr[1].split("");
                        // var dayx = parseInt(arr[2], 10) < 10 ? day[1] : arr[2];
                        // var monthx =
                        //   parseInt(arr[1], 10) < 10 ? month[1] : arr[1];
                        // setEndDate(
                        //   dayx.toString() +
                        //     "/" +
                        //     monthx.toString() +
                        //     "/" +
                        //     arr[0]
                        // );
                      }}
                    />
                  </div>
                  <div>
                    <button type="submit" className="CSV mx-3 py-2">
                      Search
                    </button>
                  </div>
                </div>
              </Form>
            </div>
            <div className="d-flex me-2">
              <div className="ms-3">
                <p
                  className="forDropDown position-relative"
                  onClick={() => SortBy()}
                >
                  Sort&nbsp;by
                  <KeyboardArrowDownIcon style={{ color: "#f00e0e" }} />
                </p>
                <div className={show ? "sort" : "sort-by"}>
                  <ul>
                    <li onClick={() => HightTOLow()}>
                      Pair&nbsp;Sold&nbsp;
                      <ArrowUpwardIcon className="UpArrow" />
                    </li>
                    <li onClick={() => LowToHigh()}>
                      Pair&nbsp;Sold&nbsp;
                      <ArrowDownwardIcon className="UpArrow" />
                    </li>
                    <li onClick={() => storeName()}>Store Name</li>
                    <li onClick={() => AmountUp()}>
                      Amount&nbsp;
                      <ArrowUpwardIcon className="UpArrow" />
                    </li>
                    <li onClick={() => AmountDown()}>
                      Amount&nbsp;
                      <ArrowDownwardIcon className="UpArrow" />
                    </li>
                  </ul>
                </div>
              </div>
              <div className="mx-3">
                <ReactHTMLTableToExcel
                  id="test-table-xls-button"
                  className="py-2 px-3 ExcelDownlad download-table-xls-button"
                  table="table-to-xls"
                  filename="Reports_By_Date_"
                  sheet="tablexls"
                  buttonText="Export&nbsp;CSV"
                  size="small"
                />
              </div>
            </div>
          </div>
          {/* ///////LOADER/ */}

          {done ? (
            <CircularIndeterminate className="reportsales" />
          ) : (
            <div className="">
              <div className="for__table m-4">
                <Paper sx={{ width: "100%", overflow: "hidden" }}>
                  <TableContainer sx={{ maxHeight: 730 }}>
                    <Table
                      stickyHeader
                      aria-label="sticky table"
                      id="table-to-xls"
                    >
                      <TableHead>
                        <TableRow>
                          {columns.map((column) => (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{ minWidth: column.minWidth }}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {customers.length > 0 ? (
                          customers
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            // eslint-disable-next-line array-callback-return
                            .filter((admin) => {
                              if (filter === "") {
                                return customers;
                              } else if (
                                admin.storeName &&
                                admin.storeName
                                  .toString()
                                  .toLowerCase()
                                  .includes(filter.toString().toLowerCase())
                              ) {
                                return customers;
                              }
                            })
                            .map((customerstwo) => (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={customerstwo._id}
                              >
                                <TableCell>{customerstwo.storeName}</TableCell>
                                <TableCell>{customerstwo.PairsSold}</TableCell>
                                <TableCell>
                                  {commafunc(customerstwo.Totalsales)}
                                </TableCell>
                              </TableRow>
                            ))
                        ) : (
                          <h5 className="text-center mt-5 fw-bolder">
                            No Data
                          </h5>
                        )}
                      </TableBody>
                    </Table>
                    {/*  */}
                    {/*  */}
                    {/*  */}
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {columnsTwo.map((column) => (
                            <TableCell
                              className="fw-bolder"
                              key={column.id}
                              align={column.align}
                              style={{ minWidth: column.minWidth }}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow hover role="checkbox" tabIndex={-1}>
                          <TableCell className="text-white"></TableCell>
                          <TableCell>
                            {" "}
                            {customers.reduce((total, item) => {
                              return +total + +item.PairsSold;
                            }, 0)}
                          </TableCell>
                          <TableCell>
                            {" "}
                           
                            {commafunc(customers?.reduce((total, item) => {
                              return +total + +item.Totalsales;
                            }, 0))}
                          </TableCell>
                          {/* <TableCell>{customers.PairsSold}</TableCell> */}
                          {/* <TableCell>{customers.Totalsales}</TableCell> */}
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    className="mx-5 mt-3 px-5"
                    rowsPerPageOptions={[2000, 5000, 8000]}
                    component="div"
                    count={customers.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>
              </div>
            </div>
          )}
          {/* <AllStyleSold /> */}
        </div>
      </Box>
    </div>
  );
}

export default TotalSales;
