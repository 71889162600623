import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../App";
import axios from "axios";
import Notification from "../AlertNotification/Message";
import CircularIndeterminate from "../Loader/Loader";
import { Form, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ResponsiveDrawer from "../Drawer/drawer";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import Toolbar from "@mui/material/Toolbar";
import Avatar from "@mui/material/Avatar";
import usePasswordToggle from "../PasswordToggle/usePasswordToggle";
import usePasswordToggleX from "../PasswordToggle/usePasswordToggleX";
const drawerWidth = 240;
const Input = styled("input")({
  display: "none",
});
const AddAdmin = () => {
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState({
    file: "https://kinderland-apis.s3.us-east-2.amazonaws.com/S22-S0003.png",
    filepreview: null,
  });
  const [tokenauth, settokenauth] = useState("")

  const [upload, setupload] = useState(false);
  const [size, setsize] = useState(false);
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [PasswordInputType, ToggleIcon] = usePasswordToggle();
  const [PasswordInputTypex, ToggleIconx] = usePasswordToggleX();
  const [done, setdone] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const HandleChange = (event) => {
    if (event.target.files[0].size > 100 * 1024) {
      setsize(true);
    } else if (event.target.files[0].size <= 100 * 1024) {
      setSelectedFile({
        ...selectedFile,
        file: event.target.files[0],
        filepreview: URL.createObjectURL(event.target.files[0]),
      });
      setsize(false);
      setupload(true);
    }
  };
  // API calling
  const AddCustomer = () => {
    const formData = new FormData();
    formData.append("firstname", fname);
    formData.append("lastname", lname);
    formData.append("email", email);
    formData.append("password", password);
    formData.append("phone", phone);
    formData.append("file", selectedFile.file);
    axios
      .post("/add-admin", formData, {
      
        headers: {
          
          "x-auth-header": tokenauth && tokenauth
        },
    })
      .then((response) => {
        console.log(response.status);
        if (response.status === 200) {
          setdone(false);
          setNotify({
            isOpen: true,
            message: `${response.data.message}`,
            type: "success",
          });
        }
        setTimeout(() => {
          navigate("/AllAdmin");
        }, 1000);
      })
      .catch((err) => {
        setdone(false);
        console.log(err.response);
        setNotify({
          isOpen: true,
          message: `${err.response.data.message}`,
          type: "error",
        });
        console.log(err);
      });
  };
  const HandleSubmit = (e) => {
    e.preventDefault();
    setdone(true);
    AddCustomer();
  };
  useEffect(() => {
sessionStorage.getItem("payloadout") != "true"
? navigate("/")
: console.log("logged in")
  }, [])
  useEffect(() => {
    axios.post("get-token", {
     id: sessionStorage.getItem("token_id")
    }).then((response) => {
     settokenauth(response.data.token) 
    }).catch(err => console.log(err))
   }, [])
   
  return (
    <div>
AddAdmin      <ResponsiveDrawer heading="Admins Management" />
      <Box
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
        component="main"
      >
        <Toolbar />
        {done ? (
          <CircularIndeterminate className="AddAgentloader" />
        ) : (
          <div className="for_form my-5 mx-4">
            <Form onSubmit={HandleSubmit}>
              <div className=" d-flex  justify-content-between mt-5  pt-3 pr-3 pb-3 btnz">
                <h5 className="">Add&nbsp;New&nbsp;Admin</h5>
                <div className="btnp">
                  <button
                    className="btn1 mx-2"
                    onClick={() => navigate("/AllAdmin")}
                  >
                    Cancel
                  </button>
                  <button className="btn2" type="submit">
                    Add
                  </button>
                </div>
              </div>
              {/* Form */}
              <Row xs="1" sm="1" md="2" lg="3" xl="3" className="mt-3">
                <Col>
                  <label htmlFor="icon-button-file">
                    <Input
                      accept="image/*"
                      id="icon-button-file"
                      type="file"
                      // value={selectedFile}
                      onChange={HandleChange}
                      // required
                    />

                    {!upload ? (
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                      >
                        <div className="addicon">
                          <AddIcon className="icon" />
                        </div>
                      </IconButton>
                    ) : (
                      <img
                        className="previewimg mb-3"
                        src={selectedFile.filepreview}
                        alt=""
                      />
                    )}
                    <br />
                  </label>
                  <div className={size ? "sizeshow" : "sizehide"}>
                    <small>File size excedded than 100KB</small>
                  </div>
                </Col>
              </Row>
              <Row xs={1} sm={1} md={2} lg={3} xl={3}>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="First name"
                      required
                      onChange={(e) => setFname(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Last name"
                      required
                      onChange={(e) => setLname(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter email"
                      required
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              {/*  */}
              <Row xs={1} sm={1} md={2} lg={3} xl={3}>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type={PasswordInputType}
                      placeholder="password"
                      required
                      onChange={(e) => setPhone(e.target.value)}
                    />
                    <span className="password-toogle-icon">{ToggleIcon}</span>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Confirm&nbsp;Password </Form.Label>
                    <Form.Control
                      type={PasswordInputTypex}
                      placeholder="confirm password"
                      required
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <span className="password-toogle-icon">{ToggleIconx}</span>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="phone"
                      required
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </div>
        )}
      </Box>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};

export default AddAdmin;
