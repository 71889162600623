import React from "react";
import { Bar } from "react-chartjs-2";
import DateTime from "../datetime";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Chart from "chart.js/auto";

// import { CSVLink } from 'react-csv'

export default function ChartTwo() {
  // DropDownMenu
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  //
  const data = {
    labels: ["Jan", "Feb", "Mar", "April", "May"],
    datasets: [
      {
        label: "Orders 2021 (M)",
        data: [3, 4, 2, 1, 4],
        // fill: true,
        tension: 0.5,
        borderColor: ["rgba(171, 116, 45, 0.8)"],
        backgroundColor: ["rgba(171, 116, 45, 0.8)"],
        pointBackgroundColor: "rgba(171, 116, 45, 0.8)",
        pointBorderColor: "rgba(171, 116, 45, 0.8)",
      },
      {
        label: "Revenue",
        data: [9, 5, 2, 2, 5],
        // fill: true,
        tension: 0.5,

        borderColor: ["rgba(123, 193, 193, 1)"],
        backgroundColor: ["rgba(123, 193, 193, 1)"],
        pointBackgroundColor: "rgba(11, 60, 60, 1)",
        pointBorderColor: "rgba(11, 60, 60, 1)",
      },
    ],
  };
  const options = {
    title: {
      display: true,
      text: "Line Chart",
    },
    scales: {
      yAxes: [
        {
          ticks: {
            min: 0,
            max: 6,
            stepSize: 1,
          },
        },
      ],
    },
  };

  return (
    <div className="chartcontainer">
      <div className="exp">
        <h5 className="chart-heading"> Today's Trends</h5>
        <div className="DropDownMenu">
          <div>
            <Button
              id="demo-positioned-button"
              aria-controls="demo-positioned-menu"
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              className="bg-danger text-white me-3"
            >
              Export&nbsp;By
            </Button>
            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MenuItem onClick={handleClose}>Profile</MenuItem>
              <MenuItem onClick={handleClose}>My account</MenuItem>
              <MenuItem onClick={handleClose}>Logout</MenuItem>
            </Menu>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-left">
        <DateTime />
      </div>
      <Bar data={data} options={options} />
    </div>
  );
}
