/* eslint-disable no-unreachable */
import React, { useState, useEffect, useContext, useRef } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useReactToPrint } from "react-to-print";
import { Container, Row } from "react-bootstrap";
import ResponsiveDrawer from "../Drawer/drawer";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import CircularIndeterminate from "../Loader/Loader";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Notification from "../AlertNotification/Message";
import "../Orders/orderinfo.css";
import Logoutloader from "../Loader/loaderlogout";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { Divider } from "semantic-ui-react";
// import { DownloadTableExcel } from "react-export-table-to-excel";

//  {
//         headers: {
//           "x-auth-header": sessionStorage.getItem("tokan"),
//         },
//       }
//
const drawerWidth = 240;
//
//
const PurchaseOrder = () => {
  const tableRef = useRef(null);

  let total = "";
  let newlist = [];
  let subtotal = [];

  const navigate = useNavigate();
  //

  //////

  //
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  ///////////////////////////
  const [donex, setdonex] = useState(false);
  const [totalobj, settotalobj] = useState("");
  const [email_1, setemail_1] = useState("");
  const [idcustomer, setidcustomer] = useState("");
  const [qty, setqty] = useState({});
  ////////////////////////////
  ////////////////////////////

  ////////////////////////////
  ///////////////////////////////shipTo

  const [Size1, setSize1] = useState("");
  const [Price1, setPrice1] = useState("");
  const [Size2, setSize2] = useState("");
  const [Price2, setPrice2] = useState("");
  const [Size3, setSize3] = useState("");
  const [Price3, setPrice3] = useState("");

  //
  // const [Date, setDate] = useState("");

  const [done, setdone] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [ProductDetails, setProductDetails] = useState([]);
  const [orderslist, setOrderslist] = useState([]);
  const [vieworders, setvieworders] = useState([]);
  const { order_number } = useParams();
  ///////

  //
  useEffect(() => {
    sessionStorage.getItem("payloadout") != "true"
      ? navigate("/")
      : setdone(true);
    // OrderInfo();
    // GetEmails();
    ShowOtherContact();
    getorderdetails();
    GetPdf();
    OrderX();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //
  /////>>>>>>>

  //
  const OrderX = () => {
    axios
      .post("/get-token", {
        id: sessionStorage.getItem("token_id"),
      })
      .then((res) => {
        axios
          .post(
            `get-order-details-admin`,
            {
              order_number: order_number,
            },
            {
              headers: {
                "x-auth-header": res.data.token,
              },
            }
          )
          .then((response) => {
            setvieworders(response.data.orders);
            setProductDetails(response.data.products);
            response.data.products.map((orders) => {
              setSize1(orders.Size1);
              setPrice1(orders.Price1);
              setSize2(orders.Size2);
              setPrice2(orders.Price2);
              setSize3(orders.Size3);
              setPrice3(orders.Price3);
            });
            // }))
            const skx = response.data.products.map((sk) => {
              return sk.sku;
            });
            // setskuzx(skx)
            // console.log(skx);
            newlist.push(
              skx.forEach((value) =>
                response.data.orders.filter((obj) => {
                  return obj.sku == value;
                })
              )
            );

            setOrderslist(newlist && newlist);
            // console.log(orderslist);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };
  let dollarUSLocale = Intl.NumberFormat("en-US");
  const [customermail, setcustomermail] = useState("");
  const [customermaila, setcustomermaila] = useState("");
  const [customermailb, setcustomermailb] = useState("");
  const [customermailc, setcustomermailc] = useState("");

  const [emails, setEmails] = useState([]);
  const [pdf, setpdf] = useState([]);
  const [pdfmail, setpdfmail] = useState("");

  const GetPdf = () => {
    axios
      .post("/get-token", {
        id: sessionStorage.getItem("token_id"),
      })
      .then((res) => {
        axios
          .post(
            `/get-pdf`,
            {
              order_number: order_number,
            },
            {
              headers: {
                "x-auth-header": res.data.token,
              },
            }
          )
          .then((response) => {
            response.data.length > 0
              ? setpdfmail(response.data[0].file)
              : setpdfmail("#");
            setpdf(response.data);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
    // console.log(response.data);
  };
  const [showemail, setshowemail] = useState(false);
  const ShowOtherContact = () => {
    axios
      .post("/get-token", {
        id: sessionStorage.getItem("token_id"),
      })
      .then((res) => {
        setdonex(true);
        axios
          .post(
            `/get-customers`,
            {},
            {
              headers: {
                "x-auth-header": res.data.token,
              },
            }
          )
          .then((response) => {
            // console.log(response.data);
            response.data
              .filter((id) => {
                return (id.customerid = idcustomer && idcustomer);
              })
              .map((mail) => {
                setcustomermail(mail.email_1);
                setcustomermaila(mail.email_2);
                setcustomermailb(mail.sc_email);
                setcustomermailc(mail.tc_email);
              });

            // setdonex(false);
            // console.log(response.data);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => console.log(err));
    setshowemail(!showemail);
  };
  //////////////
  const [comoninfo, setcomoninfo] = useState({});
  const [orderdetails, setorderdetails] = useState([]);
  const [orderdate, setorderdate] = useState("");
  const [pagenum, setpagenum] = useState(1);
  const [token, settoken] = useState("");
  const [totalpairs, settotalpairs] = useState("");
  const getorderdetails = () => {
    axios
      .post("/get-token", {
        id: sessionStorage.getItem("token_id"),
      })
      .then((res) => {
        settoken(res.data.token);
        // console.log(res.data.token);
        setdonex(true);
        axios
          .post(
            `/order-details-admin/${pagenum}`,
            {
              order_number: order_number,
            },
            {
              headers: {
                "x-auth-header": res.data.token,
              },
            }
          )
          .then((response) => {
            // console.log(response);
            const sum = response.data.products.reduce((accumulator, object) => {
              return accumulator + object.totalPairs;
            }, 0);
            console.log("response", response);
            settotalpairs(sum);
            setpagenum(pagenum + 1);
            setcomoninfo(response.data.commonInfo);
            setorderdetails(response.data.products);
            settotalobj(response.data.commonInfo.totalObjs);
            let arr = response.data.commonInfo.date.split("-");
            setorderdate(arr[1] + "-" + arr[2] + "-" + arr[0]);

            // setdonex(false);
            setdone(false);

            // console.log(response.data);
          })
          .catch((err) => {
            setdone(false);
            console.log(err.response);
          });
      })
      .catch((err) => console.log(err));
    setshowemail(!showemail);
  };
  //////////
  const fetchMoreData = () => {
    axios
      .post(
        `/order-details-admin/${pagenum}`,
        {
          order_number: order_number,
        },
        {
          headers: {
            "x-auth-header": token && token,
          },
        }
      )
      .then((response) => {
        // console.log(response);
        const sum = response.data.products.reduce((accumulator, object) => {
          return accumulator + object.totalPairs;
        }, 0);
        console.log(sum);
        settotalpairs(totalpairs + sum);
        setorderdetails((prevState) => [
          ...prevState,
          ...response.data.products,
        ]);

        setpagenum(pagenum + 1);

        setdone(false);
      })
      .catch((err) => {
        setdone(false);
        console.log(err.response);
      });
  };
  //////
  const [pdfsend, setPdfsend] = useState("");
  const [file, setfile] = useState("");
  const HandleSubmit = (e) => {
    e.preventDefault();
    SendPDF();
  };
  // API calling
  const SendPDF = () => {
    axios
      .post("/get-token", {
        id: sessionStorage.getItem("token_id"),
      })
      .then((res) => {
        axios
          .post(
            "/send-pdf-email",
            {
              email: email_1,
              file: file,
            },
            {
              headers: {
                "x-auth-header": res.data.token,
              },
            }
          )
          .then((response) => {
            // console.log(response.status);
            if (response.status === 200) {
              // setdone(false);
              setNotify({
                isOpen: true,
                message: `${response.data.message}`,
                type: "success",
              });
            }
          })
          .catch((err) => {
            setdone(false);
            console.log(err.response);
            setNotify({
              isOpen: true,
              message: `${err.response.data.message}`,
              type: "error",
            });
            // console.log(err);
          });
      })
      .catch((err) => {
        setdone(false);
        console.log(err.response);

        // console.log(err);
      });
  };

  const Showmessage = () => {
    setNotify({
      isOpen: true,
      message: `No Pdf Found!`,
      type: "error",
    });
  };

  const runCallback = (cb) => {
    return cb();
  };
  //////
  const [ef, setef] = useState(false);
  const [esec, setesec] = useState(false);
  const [ethird, setethird] = useState(false);
  const [efour, setefour] = useState(false);
  const [eadmin, seteadmin] = useState(false);
  const commafunc = (amount) => {
    const optionsa = { style: "currency", currency: "USD" };
    return amount.toLocaleString("en-US", optionsa);
  };
  return (
    <div className="overflow-hidden">
      <ResponsiveDrawer heading="Kinderland Purchase Order" />
      <Box
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
        component="main"
      >
        <Toolbar />
        <div className="me-3 d-flex justify-content-end p-4">
          <div>
            {pdf.length > 0 ? (
              <a href={pdf.length > 0 ? pdf[0].file : "#"} alt="">
                <button className="CSV me-2">
                  <small>Download&nbsp;PDF</small>
                </button>
              </a>
            ) : (
              <button className="CSVd me-2" onClick={Showmessage}>
                <small>Download&nbsp;PDF</small>
              </button>
            )}

            <button
              className="CSV px-4"
              onClick={() => {
                navigate("/OrderHistory");
              }}
            >
              <small>Home</small>
            </button>
          </div>

          {/* <DownloadTableExcel
            filename="users table"
            sheet="users"
            currentTableRef={tableRef.current}
          >
            <button> Export excel </button>
          </DownloadTableExcel> */}
        </div>
        {done ? (
          <CircularIndeterminate className="AddAgentloader" />
        ) : (
          <div>
            <table id="table-to-xlsx">
              {/* <table ref={tableRef}> */}
              <Container
                className="mt-3 w-auto overflow-auto"
                ref={componentRef}
              >
                <Row className="mx-4">
                  <div className="  top__section py-4">
                    <div className="d-flex flex-row">
                      <table>
                        <tr>
                          <td>
                            <div>
                              <div className="d-flex flex-column">
                                <small>
                                  <span className="ms-2 me-4 fw-bolder">
                                    STORE&nbsp;NAME:{" "}
                                  </span>
                                </small>
                              </div>
                              <div className="d-flex flex-column">
                                <small>
                                  <span className="ms-2 me-4 fw-bolder">
                                    PO#:{" "}
                                  </span>
                                </small>
                              </div>
                              {/*  */}
                              {/*  */}
                              <div className="d-flex">
                                <small>
                                  <span className="ms-2 me-4 mt-4">
                                    CUSTOMER ID:{" "}
                                  </span>
                                  {/* {console.log()} */}
                                  {/* <small className=" ms-5">&nbsp;{customerId}</small> */}
                                </small>
                              </div>
                              <div className="d-flex">
                                <small>
                                  <span className="ms-2 me-4 mt-4">
                                    BUYER NAME:{" "}
                                  </span>
                                  {/* {console.log()} */}
                                  {/* <small className=" ms-5">&nbsp;{customerName}</small> */}
                                </small>
                              </div>
                              <div className="d-flex">
                                <small>
                                  <span className="ms-2 me-4 mt-4">
                                    CUSTOMER EMAIL:{" "}
                                  </span>
                                  {/* {console.log()} */}
                                  {/* <small className=" ms-4">&nbsp;{customeremail}</small> */}
                                </small>
                              </div>
                              <div className="d-flex">
                                <small>
                                  <span className="ms-2 me-4 mt-4">
                                    CUSTOMER&nbsp;PHONE:{" "}
                                  </span>
                                  {/* {console.log()} */}
                                  {/* <small className=" ms-3">&nbsp;{phone}</small> */}
                                </small>
                              </div>

                              {/*  */}
                              {/*  */}
                              <div className="d-flex">
                                <small>
                                  <span className="ms-2 me-4 mt-4">
                                    ORDER&nbsp;DATE:{" "}
                                  </span>
                                  {/* <small className="ms-5">&nbsp;&nbsp; {orderdate}</small> */}
                                </small>
                              </div>
                              <div className="d-flex">
                                <small>
                                  <span className="ms-2 me-4 mt-4">
                                    START&nbsp;DATE:{" "}
                                  </span>
                                </small>
                              </div>
                              <div className="d-flex">
                                <small>
                                  <span className="ms-2 me-4 mt-4">
                                    CANCEL&nbsp;DATE:
                                  </span>
                                  {/* <small className="ms-5 ">&nbsp;{cancelDate}</small> */}
                                </small>
                              </div>

                              {/*  */}
                              {/*  */}
                              <div className="d-flex">
                                <small>
                                  <span className="ms-2 me-4 mt-4">
                                    PAYMENT&nbsp;TERM:
                                  </span>
                                </small>
                              </div>
                            </div>
                          </td>

                          <td>
                            <div>
                              <div className="d-flex flex-column">
                                <small>
                                  <span className="ms-2 me-4 fw-bolder">
                                    {comoninfo.storeName}
                                  </span>
                                </small>
                              </div>
                              <div className="d-flex flex-column">
                                <small>
                                  <span className="ms-2 me-4 fw-bolder">
                                    {comoninfo.order_number}
                                  </span>
                                </small>
                              </div>

                              {/*  */}
                              {/*  */}
                              <div className="d-flex">
                                <small>
                                  <span className="ms-2 me-4 mt-4">
                                    {comoninfo.customerId}{" "}
                                  </span>
                                </small>
                              </div>
                              <div className="d-flex">
                                <small>
                                  <span className="ms-2 me-4 mt-4">
                                    {comoninfo.customerName}{" "}
                                  </span>
                                </small>
                              </div>
                              <div className="d-flex">
                                <small>
                                  <span className="ms-2 me-4 mt-4">
                                    {comoninfo.customerEmail1}{" "}
                                  </span>
                                </small>
                              </div>
                              <div className="d-flex">
                                <small>
                                  <span className="ms-2 me-4 mt-4">
                                    {comoninfo.phoneNumber}{" "}
                                  </span>
                                </small>
                              </div>

                              {/*  */}
                              {/*  */}
                              <div className="d-flex">
                                <small>
                                  <span className="ms-2 me-4 mt-4">
                                    {orderdate}
                                  </span>
                                </small>
                              </div>
                              <div className="d-flex">
                                <small>
                                  <span className="ms-2 me-4 mt-4">
                                    {comoninfo?.startDate?.split("-")[1] +
                                      "-" +
                                      comoninfo?.startDate?.split("-")[0] +
                                      "-" +
                                      comoninfo?.startDate?.split("-")[2]}
                                   </span>
                                </small>
                              </div>
                              <div className="d-flex">
                                <small>
                                    <span className="ms-2 me-4 mt-4">
                                      
                                        {comoninfo?.cancelDate?.split("-")[1] +
                                      "-" +
                                      comoninfo?.cancelDate?.split("-")[0] +
                                      "-" +
                                      comoninfo?.cancelDate?.split("-")[2]}
                                      
                                   </span>
                                </small>
                              </div>

                              {/*  */}
                              {/*  */}
                              <div className="d-flex">
                                <small>
                                  <span className="ms-2 me-4 mt-4">
                                    {comoninfo.paymentTerm}
                                  </span>
                                </small>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </table>
                    </div>
                    {/*  */}
                    {/*  */}
                    <table>
                      <tr>
                        <div className="d-flex flex-column">
                          <div className=" me-5 d-flex flex-column">
                            <td>
                              <div className=" text-dark d-flex mt-2">
                                <h6>
                                  <span className="fw-bolder">
                                    &ensp;Shipping&nbsp;Address:{" "}
                                  </span>
                                </h6>
                              </div>
                            </td>
                            {/*  */}
                            {/*  */}
                            <td>
                              <div className="d-flex">
                                <small>
                                  <span className="">
                                    &nbsp;&nbsp;&nbsp;{comoninfo.storeName}
                                  </span>
                                </small>
                              </div>
                              {/*  */}
                              {/*  */}
                              <div className="d-flex">
                                <small>
                                  <span className="ms-2">
                                    &nbsp;{comoninfo.shipTo}
                                  </span>
                                </small>
                              </div>
                              {/*  */}
                              {/*  */}
                              <div className="d-flex">
                                <small>
                                  <span className="ms-2">
                                    &nbsp;{comoninfo.shipToCity}&nbsp;
                                    {comoninfo.shipToState},&nbsp;
                                    {comoninfo.shipToPostcode}
                                  </span>
                                </small>
                              </div>
                            </td>
                          </div>

                          {/* /////BILLING Adress */}
                          <table>
                            <tr>
                              <div className="me-5 d-flex flex-column ">
                                <td>
                                  <div className=" text-dark d-flex mt-2">
                                    <h6>
                                      <span className="fw-bolder">
                                        &ensp;Billing&nbsp;Address:{" "}
                                      </span>
                                    </h6>
                                  </div>
                                </td>
                                {/*  */}
                                {/*  */}
                                <td>
                                  <div className="d-flex">
                                    <small>
                                      {/* <span className="ms-2 me-4">Ship&nbsp;To: </span> */}
                                      <span className="">
                                        &nbsp;&nbsp;&nbsp;
                                        {comoninfo.billingShipToStreet}
                                      </span>
                                    </small>
                                  </div>
                                  {/*  */}
                                  {/*  */}
                                  <div className="d-flex">
                                    <small>
                                      <span className="ms-2">
                                        &nbsp;{comoninfo.billingShipToCity}
                                        &nbsp;
                                        {comoninfo.billingShipToState},&nbsp;
                                        {comoninfo.billingShipToPostcode}
                                      </span>
                                    </small>
                                  </div>
                                </td>
                              </div>
                            </tr>
                          </table>
                        </div>
                      </tr>
                    </table>
                  </div>
                </Row>
                <hr className="fw-bolder mx-4" />
                <InfiniteScroll
                  dataLength={orderdetails.length}
                  next={fetchMoreData}
                  hasMore={orderdetails.length !== totalobj}
                  loader={<h4>Loading...</h4>}
                >
                  <Row className="mx-0">
                    {orderdetails.map((detail, index, i) => (
                      <>
                        <div className="px-4 mt-4" key={index}>
                          <div className="py-2 for_top-Content w-100 px-3">
                            <small className="text-dark">
                              <b>
                                {detail.styleFactory}&nbsp;&nbsp;{detail.sku}
                              </b>
                              <br />
                              {detail.Material1 == "" ||
                              detail.Material1 == null
                                ? ""
                                : detail.Material1}
                              {detail.Material2 == "" ||
                              detail.Material2 == null
                                ? ""
                                : "/" + detail.Material2}
                              {detail.Material3 == "" ||
                              detail.Material3 == null
                                ? ""
                                : "/" + detail.Material3}
                            </small>
                          </div>
                          <table className="forTableCollapse w-auto overflow-auto">
                            <tbody>
                              <tr
                                id="tableRow"
                                className=" w-auto overflow-auto"
                              >
                                <td rowSpan="2" id="tabledata" className="">
                                  {" "}
                                  <img
                                    src={detail.image}
                                    alt=""
                                    className=" orderimage"
                                    width={40}
                                    height={30}
                                  />
                                </td>
                                {detail.sizes.map((data) => (
                                  <td
                                    id="tabledata"
                                    className="ps-2 text-center"
                                  >
                                    <small className="fw-bolder pe-1">
                                      {data.size}
                                    </small>
                                  </td>
                                ))}
                              </tr>
                              <tr id="tableRow">
                                {detail.sizes.map((data) => (
                                  <td
                                    id="tabledata"
                                    className="px-2 text-center"
                                  >
                                    <small>{data.pairs}</small>
                                  </td>
                                ))}
                              </tr>
                            </tbody>
                          </table>
                          <div className="for_botton-Content d-flex justify-content-between ps-3">
                            <div>
                              <small className="text-dark mt-3">
                                <span>
                                  {detail.Size1 == "" || detail.Size1 == null
                                    ? ""
                                    : `${detail.Size1} =`}
                                </span>
                                <span className="">
                                  {detail.Price1 == "" || detail.Price1 == null
                                    ? ""
                                    : `$${detail.Price1}`}
                                </span>
                              </small>
                            </div>
                            <div>
                              <small className="text-dark mt-3">
                                <span>
                                  {detail.Size2 == "" || detail.Size2 == null
                                    ? ""
                                    : `${detail.Size2} =`}
                                </span>
                                <span className="">
                                  {detail.Price2 == "" || detail.Price2 == null
                                    ? ""
                                    : `$${detail.Price2}`}
                                </span>
                              </small>
                            </div>
                            <div>
                              <small className="text-dark mt-3">
                                <span>
                                  {detail.Size3 == "" || detail.Size3 == null
                                    ? ""
                                    : `${detail.Size3} =`}
                                </span>
                                <span className="">
                                  {detail.Price3 == "" || detail.Price3 == null
                                    ? ""
                                    : `$${detail.Price3}`}
                                </span>
                              </small>
                            </div>
                            <div className="d-flex flex-column justify-content-end forQTP my-1 px-4">
                              <div className="d-flex justify-content-end">
                                <small className=" text-dark fw-bold">
                                  {detail.totalPairs}
                                  &nbsp;Pairs
                                </small>
                              </div>
                              <div>
                                <small className="text-dark fw-bolder">
                                  <span className="">Total= </span>
                                  <span className="">
                                    ${commafunc(detail.totalSales)}
                                  </span>
                                </small>
                              </div>
                            </div>
                          </div>
                        </div>
                        <table>
                          <tr>
                            <td></td>
                          </tr>
                        </table>
                      </>
                    ))}
                  </Row>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </InfiniteScroll>
                {/* /////////////////////// HELLO */}

                {/*  */}
                {/* );
              })} */}
                {/* NEXT-CONTENT */}
                <div className="d-flex justify-content-between mx-4 my-5">
                  <div className="signandImg">
                    <div className="forSignature fw-bolder my-3">
                      <img
                        src={comoninfo.SignImg}
                        alt="SignImg.png"
                        className="SignImage"
                        width={40}
                        height={30}
                      />
                    </div>
                    <div className="fw-bolder text-danger">
                      <FormGroup className="d-flex">
                        <FormControlLabel
                          control={<Checkbox />}
                          label="Email To Customer"
                          onChange={() => ShowOtherContact()}
                        />
                      </FormGroup>
                    </div>
                    <div className={showemail ? "showpartnercontact" : null}>
                      <div className="ShowHide">
                        <div>
                          {/* <p className="ms-1"> */}
                          <small>
                            {donex ? (
                              <Logoutloader className="lk" />
                            ) : (
                              <>
                                {customermail == null || customermail == "" ? (
                                  <>
                                    <small>NO Email Found!</small>
                                    <br />{" "}
                                  </>
                                ) : ef ? (
                                  <FormGroup className="d-flex">
                                    <FormControlLabel
                                      control={<Checkbox />}
                                      label={customermail && customermail}
                                      value={customermail && customermail}
                                      defaultChecked
                                      disabled
                                    />
                                  </FormGroup>
                                ) : (
                                  <FormGroup className="d-flex">
                                    <FormControlLabel
                                      control={<Checkbox />}
                                      label={customermail && customermail}
                                      value={customermail && customermail}
                                      onChange={(e) => {
                                        // console.log(e.target.value);
                                        pdfmail != ""
                                          ? axios
                                              .post(
                                                `/send-pdf-email`,
                                                {
                                                  email:
                                                    customermail &&
                                                    customermail,
                                                  pdf: pdfmail,
                                                },
                                                {
                                                  headers: {
                                                    "x-auth-header":
                                                      (token && token) ||
                                                      sessionStorage.getItem(
                                                        "tokan"
                                                      ),
                                                  },
                                                }
                                              )
                                              .then((response) => {
                                                if (response.status === 200) {
                                                  console.log("success");
                                                  setNotify({
                                                    isOpen: true,
                                                    message: `Sent Successfully`,
                                                    type: "success",
                                                  });
                                                }
                                                setef(true);
                                                // setTimeout(() => {
                                                //   navigate("/AllAdmin");
                                                // }, 1000);
                                              })
                                              .catch((err) => {
                                                setdone(false);
                                                console.log(err.response);
                                                setNotify({
                                                  isOpen: true,
                                                  message: `Sending Failed`,
                                                  type: "error",
                                                });
                                                // console.log(err.response);
                                              })
                                          : alert("no pdf available");
                                      }}
                                    />
                                  </FormGroup>
                                )}
                                {customermaila == null ||
                                customermaila == "" ? (
                                  <>
                                    <small>NO Email Found!</small>
                                    <br />{" "}
                                  </>
                                ) : esec ? (
                                  <FormGroup className="d-flex">
                                    <FormControlLabel
                                      control={<Checkbox />}
                                      label={customermaila && customermaila}
                                      value={customermaila && customermaila}
                                      disabled
                                      defaultChecked
                                    />
                                  </FormGroup>
                                ) : (
                                  <FormGroup className="d-flex">
                                    <FormControlLabel
                                      control={<Checkbox />}
                                      label={customermaila && customermaila}
                                      value={customermaila && customermaila}
                                      onChange={(e) => {
                                        // console.log(e.target.value);
                                        pdfmail != ""
                                          ? axios
                                              .post(
                                                `/send-pdf-email`,
                                                {
                                                  email:
                                                    customermaila &&
                                                    customermaila,
                                                  pdf: pdfmail,
                                                },
                                                {
                                                  headers: {
                                                    "x-auth-header":
                                                      (token && token) ||
                                                      sessionStorage.getItem(
                                                        "tokan"
                                                      ),
                                                  },
                                                }
                                              )
                                              .then((response) => {
                                                if (response.status === 200) {
                                                  setdone(false);
                                                  console.log("success");
                                                  setNotify({
                                                    isOpen: true,
                                                    message: `Sent Successfully`,
                                                    type: "success",
                                                  });
                                                }
                                                setesec(true);

                                                // setTimeout(() => {
                                                //   navigate("/AllAdmin");
                                                // }, 1000);
                                              })
                                              .catch((err) => {
                                                setdone(false);
                                                console.log(err.response);
                                                setNotify({
                                                  isOpen: true,
                                                  message: `Sending Failed`,
                                                  type: "error",
                                                });
                                                // console.log(err.response);
                                              })
                                          : alert("no pdf available");
                                      }}
                                    />
                                  </FormGroup>
                                )}

                                {customermailb == null ||
                                customermailb == "" ? (
                                  <>
                                    <small>NO Email Found!</small>
                                    <br />{" "}
                                  </>
                                ) : ethird ? (
                                  <FormGroup className="d-flex">
                                    <FormControlLabel
                                      control={<Checkbox />}
                                      label={customermailb && customermailb}
                                      value={customermailb && customermailb}
                                      disabled
                                    />
                                  </FormGroup>
                                ) : (
                                  <FormGroup className="d-flex">
                                    <FormControlLabel
                                      control={<Checkbox />}
                                      label={customermailb && customermailb}
                                      value={customermailb && customermailb}
                                      onChange={(e) => {
                                        // console.log(e.target.value);
                                        pdfmail != ""
                                          ? axios
                                              .post(
                                                `/send-pdf-email`,
                                                {
                                                  email:
                                                    customermailb &&
                                                    customermailb,
                                                  pdf: pdfmail,
                                                },
                                                {
                                                  headers: {
                                                    "x-auth-header":
                                                      (token && token) ||
                                                      sessionStorage.getItem(
                                                        "tokan"
                                                      ),
                                                  },
                                                }
                                              )
                                              .then((response) => {
                                                if (response.status === 200) {
                                                  setdone(false);
                                                  console.log("success");
                                                  setNotify({
                                                    isOpen: true,
                                                    message: `Sent Successfully`,
                                                    type: "success",
                                                  });
                                                }
                                                setethird(true);

                                                // setTimeout(() => {
                                                //   navigate("/AllAdmin");
                                                // }, 1000);
                                              })
                                              .catch((err) => {
                                                setdone(false);
                                                console.log(err.response);
                                                setNotify({
                                                  isOpen: true,
                                                  message: `Sending Failed`,
                                                  type: "error",
                                                });
                                                // console.log(err.response);
                                              })
                                          : alert("no pdf available");
                                      }}
                                    />
                                  </FormGroup>
                                )}

                                {customermailc == null ||
                                customermailc == "" ? (
                                  <>
                                    <small>NO Email Found!</small>
                                    <br />{" "}
                                  </>
                                ) : efour ? (
                                  <FormGroup className="d-flex">
                                    <FormControlLabel
                                      control={<Checkbox />}
                                      disabled
                                      label={customermailc && customermailc}
                                      value={customermailc && customermailc}
                                      // onClick={Showmessage}
                                    />
                                  </FormGroup>
                                ) : (
                                  <FormGroup className="d-flex">
                                    <FormControlLabel
                                      control={<Checkbox />}
                                      label={customermailc && customermailc}
                                      value={customermailc && customermailc}
                                      onChange={(e) => {
                                        // console.log(e.target.value);
                                        pdfmail != ""
                                          ? axios
                                              .post(
                                                `/send-pdf-email`,
                                                {
                                                  email:
                                                    customermailc &&
                                                    customermailc,
                                                  pdf: pdfmail,
                                                },
                                                {
                                                  headers: {
                                                    "x-auth-header":
                                                      (token && token) ||
                                                      sessionStorage.getItem(
                                                        "tokan"
                                                      ),
                                                  },
                                                }
                                              )
                                              .then((response) => {
                                                if (response.status === 200) {
                                                  setdone(false);
                                                  console.log("success");
                                                  setNotify({
                                                    isOpen: true,
                                                    message: `Sent Successfully`,
                                                    type: "success",
                                                  });
                                                }
                                                setefour(true);
                                              })
                                              .catch((err) => {
                                                setdone(false);
                                                console.log(err.response);
                                                setNotify({
                                                  isOpen: true,
                                                  message: `Sending Failed`,
                                                  type: "error",
                                                });
                                                // console.log(err.response);
                                              })
                                          : alert("no pdf available");
                                      }}
                                    />
                                  </FormGroup>
                                )}
                              </>
                            )}
                          </small>
                        </div>
                      </div>
                    </div>
                    <div className="fw-bolder text-primary">
                      {eadmin ? (
                        <FormGroup className="d-flex">
                          <FormControlLabel
                            disabled
                            control={<Checkbox />}
                            label="Email To Admin"
                            // onChange={() => ShowOtherContact()}
                            onClick={Showmessage}
                          />
                        </FormGroup>
                      ) : (
                        <FormGroup className="d-flex">
                          <FormControlLabel
                            control={<Checkbox />}
                            label="Email To Office"
                            // onChange={() => ShowOtherContact()}
                            onChange={(e) => {
                              // console.log(e.target.value);
                              // Danny@venettini.com
                              pdfmail != ""
                                ? axios
                                    .post(
                                      `/send-pdf-email`,
                                      {
                                        email: "Danny@venettini.com",
                                        pdf: pdfmail,
                                      },
                                      {
                                        headers: {
                                          "x-auth-header":
                                            (token && token) ||
                                            sessionStorage.getItem("tokan"),
                                        },
                                      }
                                    )
                                    .then((response) => {
                                      if (response.status === 200) {
                                        setdone(false);
                                        console.log("success");
                                        setNotify({
                                          isOpen: true,
                                          message: `Sent Successfully`,
                                          type: "success",
                                        });
                                      }
                                      seteadmin(true);
                                      // setTimeout(() => {
                                      //   navigate("/AllAdmin");
                                      // }, 1000);
                                    })
                                    .catch((err) => {
                                      setdone(false);
                                      console.log(err.response);
                                      setNotify({
                                        isOpen: true,
                                        message: `Sending Failed`,
                                        type: "error",
                                      });
                                      // console.log(err.response);
                                    })
                                : alert("no pdf available");
                            }}
                          />
                        </FormGroup>
                      )}
                      <div className="mt-3">
                        {orderdetails.length == totalobj && (
                          <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="ms-2 py-2 px-2 ExcelDownlad download-table-xls-button"
                            table="table-to-xlsx"
                            filename={`${comoninfo.storeName}_#${order_number}`}
                            sheet={`Order-#${order_number}`}
                            buttonText="Export&nbsp;CSV"
                            size="small"
                          />
                        )}
                      </div>
                    </div>

                    {/* {pdf.map((pd) => (
                    <p>{pd.file}</p>
                  ))} */}
                  </div>
                  <div className="shippingTable">
                    <div className="next__content mt-5">
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              <small className=" me-5">Total&nbsp;Pairs:</small>
                            </td>
                            <td>
                              {" "}
                              <small>
                                {/* <b> ${comoninfo.grandTotal}</b> */}
                                <b> {totalpairs && totalpairs}</b>
                              </small>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <small className="">Total</small>
                            </td>
                            <td>
                              <b>
                                {" "}
                                <small className="mt-2">
                                  ${dollarUSLocale.format(comoninfo.grandTotal)}
                                </small>
                              </b>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </Container>

              {/* ////// */}
            </table>
          </div>
        )}
      </Box>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};
export default PurchaseOrder;
