import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import ResponsiveDrawer from "../Drawer/drawer";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import "./dashboard.css";
import { Col, Row } from "react-bootstrap";
import { makeStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CircularIndeterminate from "../Loader/Loader";
import Avatar from "@mui/material/Avatar";
import shoeicon from "./iconz.jpg";
import { UserContext } from "../../App";

/////////////////////================////////////////===============
/////////////////////================////////////////===============
/////////////////////================////////////////===============
const useStyles = makeStyles({
  carad: {
    color: "#4F6367",
    "&:hover": {
      backgroundColor: "#4F6367",
      color: "#fff",

      transition: "0.5s ease",
      cursor: "pointer",
    },
  },
});
/////////////////////================////////////////===============
/////////////////////================////////////////===============
/////////////////////================////////////////===============
const drawerWidth = 240;
//
export default function Dashboard(props) {
  const navigate = useNavigate();
  const [products, setproducts] = useState([]);
  const [topselling, setTopselling] = useState([]);
  const [sellingemployee, setSellingemployee] = useState([]);
  const [done, setdone] = useState(false);
  const [sold, setsold] = useState("");
  //
  const { state, dispatch } = useContext(UserContext);
  //
  const GetProducts =  () => {
    axios.post("get-token", {
      id: sessionStorage.getItem("token_id")
     }).then((response) => {

  axios.post(`/totals`, {} ,{
      
      headers: {
        
        "x-auth-header": response.data.token
      },
  }).then((response) => {
    setproducts(response.data);
    setdone(false);
    function kFormatter(num) {
      return Math.abs(num) > 999
        ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
        : Math.sign(num) * Math.abs(num);
    }
    // console.log(kFormatter(response.data.Totalsales));
    setsold(kFormatter(response.data.Totalsales));
  }).catch(err => console.log(err))
  }).catch(err => console.log(err))
    
  };
  const GetTopselling =  () => {
    axios.post("get-token", {
      id: sessionStorage.getItem("token_id")
     }).then((response) => {

  axios.post(`/top-selling-products`,{} , {
      
      headers: {
        
        "x-auth-header": response.data.token
      },
  }).then((response) => {
    setTopselling(response.data);
    // setdone(true);
  }).catch(err => console.log(err))
  }).catch(err => console.log(err))
    
  };
  const GetSellingemployee = () => {

    setdone(true);
    axios.post("get-token", {
      id: sessionStorage.getItem("token_id")
     }).then((response) => {
     axios.post(`/top-selling-employee`, {} ,{
      
      headers: {
        
        "x-auth-header": response.data.token
      },
  }).then((response) => {
    setSellingemployee(response.data);
    // console.log(response.data);
    setdone(false);
  }).catch(err => console.log(err))
  }).catch(err => console.log(err))
    
  };
  // useEffect(() => {
  //   sessionStorage.getItem("payloadout") != "true"
  //     ? navigate("/Login")
  //     : console.log("hello");
  // }, []);
  useEffect(() => {
    
      sessionStorage.getItem("payloadout") != "true"
      ? navigate("/")
      : GetProducts();
    GetTopselling();
    GetSellingemployee();
    sessionStorage.setItem("id", "1");
    
    
  }, []);
  const Data = [
    {
      text: "Pairs Sold",
      quantity: products.PairsSold == undefined ? 0 : `${products.PairsSold}`,
    },
    {
      text: "Orders",
      quantity:
        products.TotalOrders == undefined ? 0 : `${products.TotalOrders}`,
      onClick: () => {
        navigate("/OrderHistory");
      },
    },
    {
      text: "Sold",
      quantity: sold == undefined ? 0 : `$ ${sold}`,
    },
  ];
  /////////////////////================////////////////===============
  /////////////////////================////////////////===============
  /////////////////////================////////////////===============
  return (
    <div>
      <ResponsiveDrawer heading="DASHBOARD" />
      <Box
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          p: 2,
        }}
      >
        <Toolbar />
        {done ? (
          <CircularIndeterminate className="allagentsLoader" />
        ) : (
          <div className="bim">
            <div className="d-flex justify-content-between text-center m-5 px-3 ">
              {Data.map((item, index) => {
                const { onClick } = item;

                return (
                  <div key={index} className="caradx">
                    <Card className="carad" key={index}>
                      <CardContent onClick={onClick}>
                        <Typography sx={{ mb: 1.5 }} className="txt">
                          <span className="fw-bolder text-center">
                            {item.text}
                          </span>
                        </Typography>
                        <Typography
                          variant="h5"
                          component="div"
                          sx={{ fontWeight: "bold", color: "" }}
                          className="txt"
                        >
                          <span className="p-5 text-center">
                            {item.quantity}
                          </span>
                        </Typography>
                      </CardContent>
                    </Card>
                  </div>
                );
              })}
            </div>
            {/* /////////////////////================////////////////===============
            /////////////////////========TOP CARDS========////////////////==========
            /////////////////////================////////////////=============== */}
            <div className="mx-5">
              <Row>
                <Col lg={6} md={12} sm={12}>
                  <div className="dcards w-100  mt-4">
                    <Card>
                      <CardContent>
                        <Typography
                          variant="p"
                          sx={{
                            fontWeight: "bold",
                            color: "#000",
                            cursor: "pointer",
                          }}
                          component="div"
                          className="heading1 my-3"
                        >
                          <h5 className="mb-5">Top Selling Shoes</h5>
                        </Typography>
                        {topselling
                          .sort((a, b) => b.TotalOrders - a.TotalOrders)
                          .slice(0, 10)
                          .map((item) => (
                            <div className="d-flex justify-content-start">
                              <div
                                className="d-flex flex-grow-1 justify-content-between mt-2"
                                style={{ cursor: "pointer" }}
                              >
                                <div className="d-flex flex-row position-relative">
                                  <div className="mx-3">
                                    {item.image ? (
                                      <img
                                        src={item.image}
                                        alt=""
                                        className="dimg"
                                      />
                                    ) : (
                                      <img
                                        src={shoeicon}
                                        alt=""
                                        className="dimgs"
                                      />
                                    )}
                                  </div>
                                  <div>
                                    <p className={item.image ? "axzs" : "axzs"}>
                                      {item.SKU}
                                    </p>
                                  </div>
                                </div>
                                <div>
                                  <p className={item.image ? "axzs" : "axzs"}>
                                    {item.TotalOrders}
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))}

                        <Typography
                          variant="p"
                          sx={{
                            fontWeight: "bold",
                            color: "#000",
                            cursor: "pointer",
                          }}
                          component="div"
                          className="heading1 mt-2"
                          onClick={() => {
                            navigate("/TopSelling");
                          }}
                        ></Typography>
                      </CardContent>
                    </Card>
                  </div>
                </Col>
                {/* /////////////////////================////////////////===============
                /////////////////////========TOP CARDS========////////////////==========
                /////////////////////================////////////////=============== */}
                <Col lg={6} md={12} sm={12}>
                  <div className="dcards mt-4">
                    <Card>
                      <CardContent>
                        <Typography
                          variant="p"
                          sx={{
                            fontWeight: "bold",
                            color: "#000",
                            cursor: "pointer",
                          }}
                          component="div"
                          className="heading1 my-3"
                        >
                          <h5 className="mb-5">Top Selling Representatives</h5>
                        </Typography>
                        {sellingemployee
                          .sort((a, b) => b.TotalOrders - a.TotalOrders)
                          .slice(0, 10)

                          .map((item) => (
                            <div className="d-flex justify-content-start">
                              <div
                                className="d-flex flex-grow-1 justify-content-between mt-2"
                                style={{ cursor: "pointer" }}
                              >
                                <div className="d-flex flex-row position-relative">
                                  <div>
                                    {item.img ? (
                                      <img
                                        src={item.img}
                                        alt=""
                                        className="dimgx"
                                      />
                                    ) : (
                                      <div className=" dimgx">
                                        <Avatar />{" "}
                                      </div>
                                    )}
                                  </div>
                                  <div className="d-flex flex-grow-1 justify-content-end ms-4 mt-1 ">
                                    <span className="axzsz">
                                      {item.AgentFirstname}&nbsp;
                                      {item.AgentLastname}
                                    </span>
                                  </div>
                                </div>
                                <div>
                                  <p className="axzsz">{item.TotalOrders}</p>
                                </div>
                              </div>
                            </div>
                          ))}
                        <Typography
                          variant="p"
                          sx={{
                            fontWeight: "bold",
                            color: "#000",
                            cursor: "pointer",
                          }}
                          component="div"
                          className="heading1 mt-2"
                          onClick={() => {
                            navigate("/TopSelling");
                          }}
                        ></Typography>
                      </CardContent>
                    </Card>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        )}
      </Box>
    </div>
  );
}
